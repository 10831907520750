import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import { Container } from "semantic-ui-react";
import { ErrorModal } from "../../components";
import UserInformationComponent from "../../components/screenComponents/account/UserInformationComponent";
import { getLastPaymentMethod } from "../../services/SetupService";
import "../../scss/pages/account.scss";
import { ERROR_NOT_PAYMENT_METHOD } from "../../constants/Errors";
import { UPDATE_PAYMENT_METHOD, RESET_PASSWORD } from "../../navigation/Routes";

function UserInformation(props) {
    const { user, history } = props;
    const { userData } = user;

    const [paymentMethod, setPaymentMethod] = useState(null);
    const [loadingPaymentMethod, setLoadingPaymentMethod] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showAddPaymentMethod, setShowAddPaymentMethod] = useState(false);

    useEffect(() => {
        setLoadingPaymentMethod(true);
        const getLastPayment = async () => {
            try {
                const lastPaymentMethod = await getLastPaymentMethod({
                    ownerId: userData.user_id,
                });

                if (typeof lastPaymentMethod !== "string") {
                    setPaymentMethod(lastPaymentMethod);
                    setLoadingPaymentMethod(false);
                } else {
                    if (lastPaymentMethod === "INFORMACION NO ENCONTRADA") {
                        setShowAddPaymentMethod(true);
                        setLoadingPaymentMethod(false);
                    } else {
                        setLoadingPaymentMethod(false);
                        setErrorMessage(ERROR_NOT_PAYMENT_METHOD);
                    }
                }
            } catch (error) {}
        };

        if (userData && userData.user_id) {
            getLastPayment();
        }
    }, [userData]);

    const goToUpdatePaymentMethod = async () => {
        history.push(UPDATE_PAYMENT_METHOD, { userData: userData });
    };

    const handleErrorModalClose = () => {
        setErrorMessage("");
    };

    const goToResetPassword = () => {
        history.push(RESET_PASSWORD, { userData: userData });
    };

    const errorModal = errorMessage.length > 0 && (
        <ErrorModal open={errorMessage.length > 0} closeFunction={handleErrorModalClose} message={errorMessage} />
    );

    return (
        <>
            {errorModal}
            <Container>
                <UserInformationComponent
                    userData={userData}
                    paymentMethod={paymentMethod}
                    loadingPaymentMethod={loadingPaymentMethod}
                    goToUpdatePaymentMethod={goToUpdatePaymentMethod}
                    goToResetPassword={goToResetPassword}
                    showAddPaymentMethod={showAddPaymentMethod}
                />
            </Container>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

export default compose(connect(mapStateToProps, null))(withRouter(UserInformation));
