import React, { useState } from "react";
import { Header, Card, Checkbox, Icon, Image, Accordion, Select } from "semantic-ui-react";
import { ImagePreviewModal } from "../../components";
import { PLAN_TYPES } from "../../constants/Global";
import { addOnsImages } from "../../services/UpgradeBoxService";
import "../../scss/components/_cards.scss";

export function AddOnCard(props) {
    const {
        addOn,
        onChanngeAddOn,
        onChangeAutoRenewing,
        selectedAddOn,
        showPriceSection,
        subscriptionPlan,
        goToStepTwo,
    } = props;
    const { id, metadata, autoRenewing, quantity, unit_amount } = addOn;
    const { tradename, description, content, information_one, information_Two, free_shipping } = metadata;
    const [openAccordion, setOpenAccordion] = useState(false);
    const [showImagePreview, setShowImagePreview] = useState(false);
    const maxAddOnQuantity = 41;
    const addOnQuantity = [];

    const openImagePreview = () => {
        setShowImagePreview(true);
    };

    const closeImagePreview = () => {
        setShowImagePreview(false);
    };

    for (let index = 0; index < maxAddOnQuantity; index++) {
        addOnQuantity.push({
            key: index,
            text: index,
            value: `${id}&&${index}`,
        });
    }

    let cardClasses = "addOn-card";
    let inputClasses = "";
    let priceSection = null;

    if (selectedAddOn) {
        cardClasses += " selected";
    }

    if (quantity > 0) {
        inputClasses = "active";
    }

    const unitAmount = unit_amount / 100;

    if (showPriceSection) {
        const totalText = autoRenewing ? "Monthly amount:" : "Today amount:";

        priceSection = (
            <div className="price-info">
                <p>Price: {`${quantity} x $${unitAmount}`}</p>
                <p>
                    {totalText} <strong>{`$${quantity * unitAmount}`}</strong>
                </p>
            </div>
        );
    }

    let overlay = null;
    let contenntClasses = "";

    if (subscriptionPlan && subscriptionPlan.type === PLAN_TYPES.BASIC) {
        overlay = (
            <div className="card-overlay" onClick={goToStepTwo}>
                <div className="improve-indicator">
                    <span className="text-center">Needs improve your subscription to get this AddOn</span>
                </div>
            </div>
        );
        contenntClasses += "improve-opacity";
    }

    const addonImage = addOnsImages.find((image) => image.tradename === tradename).image;

    const imagePreview = showImagePreview && (
        <ImagePreviewModal open={showImagePreview} photo={addonImage} closeFunction={closeImagePreview} />
    );

    return (
        <>
            <Card className={cardClasses} fluid>
                {imagePreview}
                {overlay}
                <Card.Content className={contenntClasses}>
                    <div className="card-sell-content">
                        <div onClick={openImagePreview} className="image-section">
                            <Image src={addonImage} />
                        </div>
                        <div className="info-section">
                            <div className="header-section">
                                <Header as="h4">{tradename}</Header>
                                <p>
                                    <strong className="color-green">Price:</strong> ${unitAmount} {priceSection}
                                </p>
                            </div>
                            <Checkbox
                                id={addOn.id}
                                label="Every month"
                                onChange={onChangeAutoRenewing}
                                checked={autoRenewing}
                            />
                        </div>
                        <div className="quantity-section">
                            <Select
                                id={id}
                                className={inputClasses}
                                options={addOnQuantity}
                                onChange={onChanngeAddOn}
                                value={`${id}&&${quantity}`}
                            />
                        </div>
                    </div>
                    <Accordion>
                        <Accordion.Title
                            active={openAccordion}
                            index={0}
                            onClick={() => setOpenAccordion(!openAccordion)}
                        >
                            <Icon name="dropdown" />
                            <span className="color-cta">{openAccordion ? "Less info..." : "More info..."}</span>
                        </Accordion.Title>
                        <Accordion.Content active={openAccordion}>
                            <p>{description}</p>
                            {content && (
                                <>
                                    <ul>
                                        <p>
                                            <strong>Content:</strong>
                                        </p>

                                        {content.map((item, index) => (
                                            <li key={index}>{item}</li>
                                        ))}
                                    </ul>
                                </>
                            )}
                            <br />
                            {information_one && (
                                <>
                                    <p>
                                        <strong>Information:</strong>
                                    </p>

                                    <p>{information_one}</p>
                                    {information_Two && <p>{information_Two}</p>}
                                </>
                            )}
                            <br />
                            {unitAmount && (
                                <>
                                    <p>
                                        <strong className="color-green">Price:</strong> ${unitAmount}{" "}
                                        {Boolean(free_shipping) ? "FREE SHIPPING" : "+ SHIPPING"}
                                    </p>
                                </>
                            )}
                        </Accordion.Content>
                    </Accordion>
                </Card.Content>
            </Card>
        </>
    );
}
