import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import { Container } from "semantic-ui-react";
import { SuccessModal } from "../components";
import ValidateEmailComponent from "../components/screenComponents/ValidateEmailComponent";
import { firebaseService } from "../services/AuthService";
import { getUserStoredData } from "../store/user/actions";
import * as ERRORS from "../constants/Errors";
import * as ROUTES from "../navigation/Routes";
import "../scss/pages/get_a_box.scss";

function ValidateEmail(props) {
    const { location, getUserStoredData, history } = props;
    const { searchParams } = location.state;
    const [loadingEmailValidation, setLoadingEmailValidation] = useState(false);
    const [responseMessage, setResponseMessage] = useState("");
    const [validationSuccess, setValidationSuccess] = useState();
    const [openConfirmModal, setOpenConfirmModal] = useState(false);

    useEffect(() => {
        const doResetPasswoird = () => {
            setLoadingEmailValidation(true);
            const oobCode = searchParams.find((param) => param.includes("oobCode")).split("=")[1];

            firebaseService.auth
                .applyActionCode(oobCode)
                .then(function (resp) {
                    setLoadingEmailValidation(false);
                    setValidationSuccess(true);
                    setOpenConfirmModal(true);
                    setResponseMessage("done");
                })
                .catch(function (error) {
                    setValidationSuccess(false);
                    setLoadingEmailValidation(false);
                    switch (error.code) {
                        case "auth/invalid-action-code":
                            setResponseMessage(ERRORS.ERROR_EMAIL_VALIDATION_EXPIRED);
                            break;
                        default:
                            setResponseMessage(ERRORS.ERROR_EMAIL_VALIDATION_DEFAULT);
                            break;
                    }
                });
        };

        doResetPasswoird();
    }, [searchParams, getUserStoredData]);

    const closeEmailVerification = () => {
        setOpenConfirmModal(false);
        history.replace(ROUTES.SIGN_UP, {
            accountRoutes: "subscription",
        });
    };

    let successModal = null;

    if (openConfirmModal) {
        successModal = (
            <SuccessModal
                open={openConfirmModal}
                message="Your email has been validated successfully."
                successAction={closeEmailVerification}
                closeAction={closeEmailVerification}
            />
        );
    }

    return (
        <>
            {successModal}

            <Container>
                <ValidateEmailComponent
                    responseMessage={responseMessage}
                    loadingEmailValidation={loadingEmailValidation}
                    validationSuccess={validationSuccess}
                />
            </Container>
        </>
    );
}

const mapDispatchToProps = (dispatch) => ({
    getUserStoredData: () => dispatch(getUserStoredData()),
});

export default compose(connect(null, mapDispatchToProps))(withRouter(ValidateEmail));
