import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import { Container } from "semantic-ui-react";
import StepOneComponent from "../../components/screenComponents/subscribe/StepOneComponent";
import { saveStepOne } from "../../store/subscriptions/actions";
import { SURVIVAL_BOXES } from "../../services/SubscriptionPlanService";
import * as ROUTES from "../../navigation/Routes";
import "../../scss/pages/get_a_box.scss";

function StepOne(props) {
    const { subscriptions, history, saveStepOne } = props;
    const { subscriptionBox } = subscriptions;

    const [selectedSubscriptionBox, setSelectedSubscriptionBox] = useState("");
    const [subscriptionBoxOptions, setSubscriptionBoxOptions] = useState([]);

    useEffect(() => {
        setSubscriptionBoxOptions(SURVIVAL_BOXES);
    }, []);

    useEffect(() => {
        setSelectedSubscriptionBox(subscriptionBox ? subscriptionBox : "");
    }, [subscriptionBox]);

    const onChangeSelectedBox = (data) => {
        setSelectedSubscriptionBox(data.survivalType);
    };

    const saveStepOneAndNext = () => {
        saveStepOne({
            subscriptionBox: selectedSubscriptionBox,
        });
        history.push(ROUTES.STEP_TWO);
    };

    return (
        <>
            <Container>
                <StepOneComponent
                    selectedSubscriptionBox={selectedSubscriptionBox}
                    saveStepOneAndNext={saveStepOneAndNext}
                    onChangeSelectedBox={onChangeSelectedBox}
                    subscriptionBoxOptions={subscriptionBoxOptions}
                />
            </Container>
        </>
    );
}

const mapDispatchToProps = (dispatch) => ({
    saveStepOne: (stepOne) => dispatch(saveStepOne(stepOne)),
});

const mapStateToProps = (state) => {
    return {
        subscriptions: state.subscriptions,
        business: state.business,
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(withRouter(StepOne));
