import React from "react";
import { Image } from "semantic-ui-react";
import Loading from "../images/survivalImages/loading-bar.svg";
import "../scss/components/_loader.scss";

export function Loader(props) {
    const { message } = props;

    let loadingText = "Loading...";
    if (message) {
        loadingText = message;
    }

    return (
        <div className="loader flex-center flex-column margin-bottom margin-top">
            <br />
            <br />
            <Image src={Loading} size="small" />
            <br />
            <br />
            <p>{loadingText}.</p>
        </div>
    );
}
