import React from "react";
import { Image, Modal, Header } from "semantic-ui-react";
import { DefaultButton } from "../../components";
import SuccessImage from "../../images/survivalImages/success2.svg";
import "../../scss/components/_modal.scss";

export function SuccessModal(props) {
    const { message, open, successAction, closeAction, hideButtons, title } = props;

    let actionButtonsClasses = "action-buttons-container";

    if (hideButtons) {
        actionButtonsClasses += " hide-action-buttons";
    }

    return (
        <Modal className="confirm-modal" closeIcon open={open} size="small" onClose={closeAction}>
            <Modal.Content>
                {!title && <Image src={SuccessImage} size="small" centered />}
                <Header as="h2" textAlign="center">
                    {title ? title : "Awesome!"}
                </Header>
                <p>{message}</p>
            </Modal.Content>
            <Modal.Actions>
                <div className={actionButtonsClasses}>
                    <div className="action-buttons-content">
                        <DefaultButton title="Ok" buttonAction={successAction} />
                    </div>
                </div>
            </Modal.Actions>
        </Modal>
    );
}
