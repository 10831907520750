import { v4 as uuidv4 } from "uuid";
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from "./LocalStorageService";
import * as CONSTANTS from "../constants/Global";
import * as LOCALSTORAGE from "../constants/LocalStorage";
import EmergencyBoxImage from "../images/survivalImages/emergency-box.svg";
import OutdoorBoxImage from "../images/survivalImages/outdoor-adventure-box.svg";
import basicPlanImage from "../images/survivalImages/basic-box.svg";
import xlPlanImage from "../images/survivalImages/xl-box.svg";

export const SURVIVAL_BOXES = [
    {
        name: "Emergency Preparedness Box",
        survivalType: "emergencyPreparedness",
        image: EmergencyBoxImage,
    },
    {
        name: "Outdoor Adventures Box",
        survivalType: "outdoorAdventures",
        image: OutdoorBoxImage,
    },
];

export const sessionId = getLocalStorageItem(LOCALSTORAGE.SESSION_ID);

export const divideProductsList = (productsList) => {
    let products = null;

    if (productsList.length > 0) {
        const addOns = productsList.filter((product) => Boolean(product.metadata.addOn && product.active));
        const plans = productsList.filter((product) => Boolean(product.metadata.subscription && product.active));
        const shipping = productsList.filter((product) => Boolean(product.metadata.shipping && product.active));

        const addOnPrices = addOns.flatMap((addOn) => addOn.prices);

        products = {
            addOns: addOns,
            plans: plans,
            shipping: shipping,
            addOnPrices: addOnPrices,
        };
    } else {
        products = [];
    }

    return products;
};

export const getOrdersSummaryData = (selectedPlans) => {
    const isGift = selectedPlans.some((plan) => plan.metadata.name === "GrandBox Gift");
    let plansToPay = null;

    const subscriptionOrderSummary = selectedPlans.map((plan) => {
        let deliveries = `${plan.metadata.packageQty} monthly delivery`;
        let detail = plan.metadata.tradename;

        let name = null;
        let type = null;

        if (plan.metadata.subscription) {
            name = "Survival Box Subscription";
            type = CONSTANTS.BOX_TYPES.SUBSCRIPTION;
        } else if (plan.metadata.shipping) {
            type = "shipping";
            name = `${plan.quantity} ${plan.metadata.tradename}`;
        } else {
            if (plan.metadata.name === "Survival Box Gift") {
                type = CONSTANTS.BOX_TYPES.SUBSCRIPTION;
            } else {
                type = CONSTANTS.BOX_TYPES.ADDON;
                name = `${plan.quantity} ${plan.metadata.tradename}`;
            }
        }

        if (plan.autoRenewing) {
            deliveries = `1 monthly delivery`;
        } else {
            deliveries = `1 delivery`;
        }

        const planData = {
            priceId: plan.id,
            name: name,
            detail: detail,
            unitPrice: plan.unit_amount / 100,
            price: (plan.unit_amount * plan.quantity) / 100,
            deliveries: deliveries,
            packages: Number(plan.metadata.packageQty) || plan.quantity,
            autoRenewing: plan.autoRenewing,
            type: type,
            billingType: plan.type,
            giftData: null,
        };

        return planData;
    });

    if (isGift) {
        plansToPay = getGiftOrderSummary(subscriptionOrderSummary);
    } else {
        plansToPay = subscriptionOrderSummary;
    }

    return plansToPay;
};

const getGiftOrderSummary = (plansToPay) => {
    const price = plansToPay[0].price + plansToPay[1].price;

    const planData = {
        priceId: plansToPay[0].priceId,
        name: plansToPay[1].name,
        detail: "One Time Purchase",
        price: price,
        deliveries: plansToPay[0].deliveries,
        packages: plansToPay[0].packages,
        autoRenewing: null,
        type: plansToPay[0].type,
        parent: plansToPay[1].parent,
        priceType: plansToPay[0].priceType,
        billingType: plansToPay[1].billingType,
        upgradeType: plansToPay[1].upgradeType,
        giftData: plansToPay,
    };

    return [planData];
};

export const prepareSubmitSubscriptionData = (
    subscriptionsData,
    paymentData,
    ordersSummaryData,
    subscribeNewsLetter
) => {
    const { couponData } = subscriptionsData;
    const pricesData = ordersSummaryData[0].giftData ? ordersSummaryData[0].giftData : ordersSummaryData;
    const paymentMethod = paymentData && paymentData.id;
    const pricesIds = getPricesIds(pricesData);
    const sessionId = getLocalStorageItem(LOCALSTORAGE.SESSION_ID);
    const email = getLocalStorageItem(LOCALSTORAGE.EMAIL);
    const isOldSubscriber = getLocalStorageItem(LOCALSTORAGE.OLD_SUBSCRIBER);

    const subscriptionData = {
        input: {
            sessionId: sessionId,
            shippingAddress: subscriptionsData.shippingAddress,
            paymentInfo: {
                couponCode: couponData ? couponData.code : isOldSubscriber ? "STAYWITHUS" : null,
                paymentMethod: paymentMethod,
                prices: pricesIds.prices,
            },
            subscribeNewsLetter: subscribeNewsLetter,
            migration: email ? true : false,
        },
    };

    if (!paymentMethod) {
        delete subscriptionData.input.paymentInfo.paymentMethod;
    }

    return subscriptionData;
};

const getPricesIds = (ordersSummaryData) => {
    const prices = [];
    let recurring = null;

    ordersSummaryData.forEach((order) => {
        const priceId = order.priceId;

        recurring = order.autoRenewing ? order.autoRenewing : false;

        prices.push({
            id: priceId,
            quantity: order.packages,
            recurring: recurring,
            pending: true,
        });
    });

    const pricesIdsList = {
        prices: prices,
    };

    return pricesIdsList;
};

export const renewSessionId = () => {
    removeLocalStorageItem(LOCALSTORAGE.SESSION_ID);

    const sessionId = uuidv4();

    if (sessionId) setLocalStorageItem(LOCALSTORAGE.SESSION_ID, sessionId);

    return sessionId;
};

export const prepareSubmitAddOnsData = (addonList, subscriptionId) => {
    const sessionId = renewSessionId();

    const addOnsData = {
        input: {
            sessionId: sessionId,
            subscriptionId: subscriptionId,
            prices: addonList,
        },
    };

    return addOnsData;
};

export const getPlansOptionsWithImage = (data) => {
    const plansOptionsWithImage = data.map((plan) => {
        return {
            ...plan,
            image: plan.metadata.type === "basic" ? basicPlanImage : xlPlanImage,
        };
    });

    return plansOptionsWithImage;
};
