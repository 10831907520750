import React from "react";
import { Label, TextArea } from "semantic-ui-react";
import "../../scss/components/_input.scss";

export function CustomTextArea(props) {
    const { id, label, rows, placeholder, maxLength, onChangeAction, value, errorMessage } = props;

    let inputLabel = null;

    if (label) {
        inputLabel = <Label>{label}</Label>;
    }

    let errorInfo = null;

    const showError = errorMessage && errorMessage.length > 0;

    if (showError) {
        errorInfo = <p className="input-error">{errorMessage}</p>;
    }

    let onChange = onChangeAction;
    let textLengthComplete = value.length === maxLength;

    const setOnchange = (event) => {
        if (textLengthComplete) {
            if (event.key === "Backspace") {
                onChange = onChangeAction;
            } else {
                onChange = null;
            }
        }
    };

    const counter = <span className="counter">{`${value.length}/${maxLength}`}</span>;

    return (
        <div className="text-area-content">
            {inputLabel}
            <div className="textarea">
                <TextArea
                    id={id}
                    rows={rows}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    maxLength={maxLength}
                    onKeyDown={setOnchange}
                />
            </div>
            {errorInfo}
            {counter}
        </div>
    );
}
