import React from "react";
import { Label, Input } from "semantic-ui-react";
import "../../scss/components/_input.scss";

export function CustomInput(props) {
    const { id, label, type, placeholder, onChangeAction, value, errorMessage, disabled, noMargin } = props;

    let inputLabel = null;

    if ((value && value.length > 0 && label) || !placeholder) {
        inputLabel = (
            <Label htmlFor={id} floating>
                {label}
            </Label>
        );
    }

    let errorInfo = null;

    const showError = errorMessage && errorMessage.length > 0;

    if (showError) {
        errorInfo = <p className="input-error">{errorMessage}</p>;
    }

    let inputContainerClasess = "input-content";

    if (noMargin) {
        inputContainerClasess += " no-margin";
    }

    return (
        <div className={inputContainerClasess}>
            {inputLabel}
            <Input
                id={id}
                type={type}
                fluid
                placeholder={placeholder}
                onChange={onChangeAction}
                value={value}
                error={showError}
                disabled={disabled}
            />
            {errorInfo}
        </div>
    );
}
