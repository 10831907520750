import React, { useState } from "react";
import moment from "moment";
import { Card, Grid, Image, Header } from "semantic-ui-react";
import {
    LinkButton,
    CustomDropdown,
    MonthBoxCard,
    SettingCard,
    ConfirmationModal,
    CustomCheckbox,
    DefaultButton,
    AddOnModifyModal,
} from "../..";
import EmergencyBoxImage from "../../../images/survivalImages/emergency-box.svg";
import OutdoorBoxImage from "../../../images/survivalImages/outdoor-adventure-box.svg";
import * as CONSTANTS from "../../../constants/Global";

export default function ManageSubscriptionComponent(props) {
    const {
        goToBoxDetail,
        subscriptionOptions,
        selectedSubscription,
        handleSelectedSubscription,
        goToCancelSubscription,
        handleShowActiveSubscriptions,
        showActiveSubscriptions,
        goToSubscriptions,
        addOnPrices,
        addOnsUpdater,
        loadingGettingAddOns,
        openAddOnModifyModal,
        handleAddOnModifyModal,
        getAddOnError,
        setGetAddOnError,
        handlePauseSubscriptionModal,
        moveLevelSubscription
    } = props;
    const selectedSubscriptionId = selectedSubscription.id;
    const { memberSince, status, recurring, description, resumeAt } = selectedSubscription;
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [changeStatusQuestion, setChangeStatusQuestion] = useState("");
    const [addOnToModify, setAddOnToModify] = useState();
    // const [openModal, setOpenModal] = useState();

    let renewalDate = moment().add(1, "M").startOf("M").add(9, "days").format("MMMM/DD/YYYY");

    if (status === CONSTANTS.SUBSCRIPTION_STATUS.PAUSED && resumeAt) {
        renewalDate = moment(resumeAt * 1000)
            .utc()
            .add(1, "days")
            .format("MMMM/DD/YYYY");
    }

    let billingInfo = "";
    let renewalInfo = "";
    let nextPaymentData = "";

    const survivalBox = selectedSubscription.boxes.map((box) => {
        const boxInfo = box.prices.find((item) => Boolean(item.price.metadata.subscription));
        return {
            ...box,
            boxInfo,
        };
    });

    let labelName;

    if(selectedSubscription.description.toLowerCase().includes("premium")) {
        labelName="Downgrade"
    } else {
        labelName="Upgrade"
    }

    const addOOnsItems = selectedSubscription.prices.filter((item) => Boolean(item.price.metadata.addOn));

    const addOnsLess = addOnPrices
        .map((price) => {
            const addOnBuyed = addOOnsItems.some(
                (item) => item.price.id === price.id && item.price.metadata.survivalType === price.metadata.survivalType
            );

            if (!addOnBuyed) {
                return {
                    price: price,
                    quantity: 0,
                    recurring: false,
                    add: true,
                };
            } else {
                return null;
            }
        })
        .filter((priceItem) => {
            const subscriptionPrice = selectedSubscription.prices.find((priceItem) =>
                Boolean(priceItem.price.metadata.subscription)
            );

            if (priceItem) {
                return (
                    priceItem.price.metadata.survivalType === subscriptionPrice.price.metadata.survivalType &&
                    priceItem.price.metadata.description !== "Survival Garden"
                );
            }

            return null;
        });

    const handleCancelSubscriptionModal = (action) => {
        const cancelQuestion = (
            <>
                Are you sure you want cancel the{" "}
                <strong className="color-green">{selectedSubscription.description}</strong>
            </>
        );

        setChangeStatusQuestion(cancelQuestion);

        setOpenConfirmModal(!openConfirmModal);
    };

    const editAddOn = (addOn) => {
        let items = addOn;
        if (addOn === "add") {
            items = addOnsLess;
        }

        setAddOnToModify(items);
        handleAddOnModifyModal();
    };

    if (recurring) {
        billingInfo = `Monthly`;
        renewalInfo = renewalDate;
        nextPaymentData = `Next payment ${renewalDate}`;
    } else {
        billingInfo = "One charge";
        renewalInfo = "Without Renewal";
        nextPaymentData = "";
    }

    let subscriptionName = "";

    if (description) {
        subscriptionName = description;
    }

    const settingOne = {
        "Current plan": subscriptionName,
        Billing: billingInfo,
        Renewal_link: renewalInfo,
        "Thank you for being since": moment(memberSince).format("MMMM/DD/YYYY"),
        Status: status,
    };

    const subscriptionsListDropdown = (
        <div className="select-subscription">
            <div className="select-subscription-header">
                <p>Choose your subscription</p>
                <CustomCheckbox
                    label="Only active"
                    inverted={true}
                    onChangeAction={handleShowActiveSubscriptions}
                    value={showActiveSubscriptions}
                />
            </div>
            <CustomDropdown
                options={subscriptionOptions}
                customClass="no-margin"
                value={selectedSubscriptionId}
                onChangeAction={handleSelectedSubscription}
            />
        </div>
    );

    const nextPaymentDate = <p className="next-payment">{nextPaymentData} </p>;

    let boxImage = null;

    if (selectedSubscription.description.includes("Emergency")) {
        boxImage = EmergencyBoxImage;
    } else {
        boxImage = OutdoorBoxImage;
    }

    let boxesSection = (
        <div className="box-sections">
            {recurring ? <p className="section-title">your monthly box</p> : <p className="section-title">your box</p>}
            {
                <Card.Group textAlign="center" centered>
                    {survivalBox.length > 0 ? (
                        survivalBox.map((box, index) => {
                            return (
                                <MonthBoxCard
                                    key={index}
                                    box={box}
                                    goToBoxDetail={goToBoxDetail}
                                    type={CONSTANTS.BOX_TYPES.SUBSCRIPTION}
                                />
                            );
                        })
                    ) : (
                        <Card className="month-box-card" fluid>
                            <Card.Content>
                                <div className="month-box-card-container">
                                    <div className="image">
                                        <Image src={boxImage} size="medium" />
                                    </div>
                                    <div className="info">
                                        <Header as="h3">{selectedSubscription.description}</Header>
                                        <Card.Meta>Will be charged on February 1th 2021</Card.Meta>
                                    </div>
                                </div>
                            </Card.Content>
                        </Card>
                    )}
                </Card.Group>
            }
        </div>
    );

    let addOnSection = null;
    let addOnBuySection = null;

    if (selectedSubscription.description.includes("Premium")) {
        if (addOOnsItems.length > 0) {
            const sectionTitle = "your addons";

            addOnSection = (
                <div className="box-sections">
                    <p className="section-title">{sectionTitle}</p>
                    {
                        <Card.Group textAlign="center" centered>
                            {addOOnsItems.map((box, index) => {
                                return (
                                    <MonthBoxCard
                                        key={index}
                                        box={box}
                                        goToBoxDetail={goToBoxDetail}
                                        type={CONSTANTS.BOX_TYPES.ADDON}
                                        editAddOn={editAddOn}
                                    />
                                );
                            })}
                        </Card.Group>
                    }
                </div>
            );
        }

        if (addOnsLess && addOnsLess.length > 0) {
            addOnBuySection = (
                <div className="box-sections">
                    <p className="section-title">available addons</p>
                    {
                        <Card.Group textAlign="center" centered>
                            {addOnsLess.map((box, index) => {
                                return (
                                    <MonthBoxCard
                                        key={index}
                                        box={box}
                                        goToBoxDetail={goToBoxDetail}
                                        type={CONSTANTS.BOX_TYPES.ADDON}
                                        editAddOn={editAddOn}
                                    />
                                );
                            })}
                        </Card.Group>
                    }
                </div>
            );
        }
    }

    const subscriptionActive = selectedSubscription.status === CONSTANTS.SUBSCRIPTION_STATUS.ACTIVE;
    const subscriptionPaused = selectedSubscription.status === CONSTANTS.SUBSCRIPTION_STATUS.PAUSED;
    const showCancelButton = subscriptionActive || subscriptionPaused;

    const subscriptionSettings = (
        <>
            <div className="padding-bottom">
                <SettingCard title="Subscription settings" items={settingOne} showBenefits={subscriptionActive} />
            </div>
            <div className="padding-bottom cancel-buttons-container">
                {subscriptionActive && (
                    <>
                        <LinkButton
                            title="Pause Subscription Renewal"
                            buttonAction={handlePauseSubscriptionModal}
                            align="left"
                            textSmall={true}
                        />
                        <span className="separator">or</span>
                    </>
                )}
                {showCancelButton && (
                    <LinkButton
                        title="Cancel Subscription"
                        buttonAction={handleCancelSubscriptionModal}
                        align="left"
                        textSmall={true}
                    />
                )}
            </div>

            <div className="padding-bottom">
                <DefaultButton title="Buy another subscription" buttonAction={goToSubscriptions} inverted={true} />
            </div>
            <div className="padding-bottom">
                <DefaultButton title={labelName} buttonAction={moveLevelSubscription} inverted={true} />
            </div>
        </>
    );

    let confirmationModal = null;

    const cancelSubscriptionAction = () => goToCancelSubscription();

    if (openConfirmModal) {
        confirmationModal = (
            <ConfirmationModal
                open={openConfirmModal}
                closeAction={handleCancelSubscriptionModal}
                confirmAction={cancelSubscriptionAction}
                message={changeStatusQuestion}
            />
        );
    }

    let addOnModifyModal = null;

    if (openAddOnModifyModal) {
        addOnModifyModal = (
            <AddOnModifyModal
                open={openAddOnModifyModal}
                closeAction={handleAddOnModifyModal}
                addOnToModify={addOnToModify}
                addOnsUpdater={addOnsUpdater}
                loadingGettingAddOns={loadingGettingAddOns}
                getAddOnError={getAddOnError}
                setGetAddOnError={setGetAddOnError}
            />
        );
    }

    return (
        <>
            {confirmationModal}
            {addOnModifyModal}
            <>
                <Grid className="subscription-grid">
                    <Grid.Column width={16} only="mobile">
                        {subscriptionsListDropdown}
                        {nextPaymentDate}
                    </Grid.Column>
                    <Grid.Row columns={2} only="computer">
                        <Grid.Column>
                            {subscriptionsListDropdown}
                            {boxesSection}
                            {addOnSection}
                            {addOnBuySection}
                        </Grid.Column>
                        <Grid.Column>{subscriptionSettings}</Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1} only="mobile tablet">
                        <Grid.Column>
                            {boxesSection}
                            {addOnSection}
                            {addOnBuySection}
                            {subscriptionSettings}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </>
        </>
    );
}
