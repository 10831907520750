import React from "react";
import moment from "moment";
import { Image, Modal, Header, List } from "semantic-ui-react";
import { DefaultButton } from "..";
import ConfirmImage from "../../images/survivalImages/confirm.svg";
import "../../scss/components/_modal.scss";

export function AddOnsConfirmationModal(props) {
    const { message, open, closeAction, confirmAction } = props;
    let messageSection = null;

    const currentDeliverDay = moment().startOf("M").add(17, "d").format("MMMM, DD YYYY");
    const nextDeliverDay = moment().startOf("M").add(1, "M").add(17, "d").format("MMMM, DD YYYY");
    const nextBillingDay = moment().startOf("M").add(1, "M").format("MMMM, DD YYYY");

    if (message === "current") {
        messageSection = (
            <div className="message-section">
                <p className="message-text">
                    The changes made in the current addons will take effect in the current month:
                </p>
                <br />
                <List bulleted size="large">
                    <List.Item>The amount will be charged today.</List.Item>
                    <List.Item>
                        Selected addons will be delivered in your current deliver date ({currentDeliverDay}).
                    </List.Item>
                    <List.Item>If you decresed the addon quantity, changes will be taked the next month.</List.Item>
                    <List.Item>
                        If you uncheck the "Every month" checkbox, the addon will stop charging and dispatching the next
                        month.
                    </List.Item>
                </List>
            </div>
        );
    } else {
        messageSection = (
            <div className="message-section">
                <p className="message-text">
                    The changes made in the current addons will take effect in the next month:
                </p>
                <br />
                <List bulleted size="large">
                    <List.Item>The amount will be charged in your next billing date ({nextBillingDay}) .</List.Item>
                    <List.Item>
                        Selected addons will be delivered in your next deliver date ({nextDeliverDay}).
                    </List.Item>
                    <List.Item>Selected addons be stay in pending status until charge has been done.</List.Item>
                </List>
            </div>
        );
    }
    return (
        <Modal className="confirm-addon-modal" closeIcon open={open} size="small" onClose={closeAction}>
            <Modal.Content>
                <Image src={ConfirmImage} size="small" centered />
                <Header as="h2" textAlign="center">
                    Confirmation
                </Header>
                {messageSection}
            </Modal.Content>
            <Modal.Actions>
                <div className="action-buttons-container">
                    <div className="action-buttons-content">
                        <DefaultButton title="Cancel" buttonAction={closeAction} inverted={true} />
                    </div>
                    <div className="action-buttons-content">
                        <DefaultButton title="Accept" buttonAction={confirmAction} />
                    </div>
                </div>
            </Modal.Actions>
        </Modal>
    );
}
