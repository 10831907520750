import React, { useEffect, useState } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import Navigator from "./navigation/Navigator";
import { ErrorModal, Loader, Header, SendgridModal } from "./components";
import { getProductsList } from "./services/SetupService";
import { loadGoogleScript } from "./services/LoadScriptService";
import { getCurrentUser, firebaseSignOut } from "./services/AuthService";
import { getLocalStorageItem } from "./services/LocalStorageService";
import { getSubscriptionStoragedData } from "./store/subscriptions/actions";
import { saveProductsList } from "./store/business/actions";
import { getUserStoredData } from "./store/user/actions";
import * as ERORRS from "./constants/Errors";
import * as ROUTES from "./navigation/Routes";
import { SIGN_IN_TIME } from "./constants/LocalStorage";
import jwtDecode from "jwt-decode";

function App(props) {
    const { getSubscriptionStoragedData, getUserStoredData, saveProductsList, history } = props;
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState(ERORRS.ERROR_INITIALIZING_PAGE);
    const [closeErrorFunction, setCloseErrorFunction] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState();
    const [openStopSubscriptionModal, setOpenStopSubscriptionModal] = useState(false);

    const pathname = window.location.pathname;
    const isSubscribe = pathname.includes("subscribe");

    useEffect(() => {
        const checkLoginTime = async () => {
            const currentUser = await getCurrentUser();
            const signInTime = getLocalStorageItem(SIGN_IN_TIME);

            if (currentUser && signInTime) {
                const currentTime = Number(moment().format("x"));
                const signInTimeSixtyMinMore = Number(moment(signInTime).add(60, "minutes").format("x"));
                const isAHourAfter = moment(currentTime).isAfter(signInTimeSixtyMinMore);

                if (!showErrorModal) {
                    if (isAHourAfter) {
                        setErrorMessage(ERORRS.ERROR_SESSION_EXPIRED);
                        setShowErrorModal(true);
                        setCloseErrorFunction(true);
                    }
                }
            }
        };

        setInterval(() => {
            checkLoginTime();
        }, 5000);
    }, [showErrorModal]);

    useEffect(() => {
        const checkIsLoggedIn = async () => {
            const currentUser = await getCurrentUser();

            if (currentUser) {
                const decoded = jwtDecode(currentUser);
                getUserStoredData();
                if (decoded.firebase.sign_in_provider === "password") {
                    if (decoded.email_verified) {
                        setIsLoggedIn(currentUser);
                    } else {
                        setIsLoggedIn("notLoggedIn");
                    }
                } else {
                    setIsLoggedIn(currentUser);
                }
            } else {
                setIsLoggedIn("notLoggedIn");
            }
        };

        checkIsLoggedIn();
    }, [getUserStoredData]);

    useEffect(() => {
        const pathname = window.location.pathname;

        if (!ROUTES.ROUTES_LIST.includes(pathname)) {
            //window.location = ROUTES.SURVIVALBOXES_URLS[404];
        }
    }, []);

    useEffect(() => {
        const getInitialData = async () => {
            getSubscriptionStoragedData();
            loadGoogleScript();
        };

        const getSubscriptionProducstList = async () => {
            const productsList = await getProductsList();

            if (productsList) {
                saveProductsList(productsList);
            } else {
                setShowErrorModal(true);
            }
        };

        if (![ROUTES.RESET_PASSWORD, ROUTES.EMAIL_VALIDATION].includes(window.location.pathname)) {
            getInitialData();
            getSubscriptionProducstList();
        }
    }, [getSubscriptionStoragedData, getUserStoredData, saveProductsList, history]);

    useEffect(() => {
        if (isSubscribe) {
            setOpenStopSubscriptionModal(true);
        }
    }, [isSubscribe]);

    const closeErrorModal = () => {
        firebaseSignOut(true);
    };

    let prevPath = null;

    useEffect(() => {
        // listen and notify Segment of client-side page updates
        history.listen((location) => {
            if (location.pathname !== prevPath) {
                prevPath = location.pathname; // eslint-disable-line react-hooks/exhaustive-deps
                window.analytics.page();
            }
        });
    }, []);

    let errorModal = null;

    if (showErrorModal) {
        errorModal = (
            <ErrorModal
                message={errorMessage}
                open={showErrorModal}
                closeFunction={closeErrorFunction ? closeErrorModal : null}
            />
        );
    }

    let navigation = null;
    if (isLoggedIn) {
        navigation = (
            <>
                {pathname !== ROUTES.SUBSCRIPTION_SUMMARY && <Header history={history} />}
                <Navigator isLoggedIn={isLoggedIn} />
            </>
        );
    } else {
        navigation = <Loader />;
    }

    window.dataLayer &&
        window.dataLayer.push({
            event: "pageview",
        });

    const stopSubscriptionModal = openStopSubscriptionModal && <SendgridModal open={openStopSubscriptionModal} />;

    return (
        <>
            {stopSubscriptionModal}
            {errorModal}
            {navigation}
        </>
    );
}

const mapDispatchToProps = (dispatch) => ({
    getSubscriptionStoragedData: () => dispatch(getSubscriptionStoragedData()),
    saveProductsList: (productsList) => dispatch(saveProductsList(productsList)),
    getUserStoredData: () => dispatch(getUserStoredData()),
});

export default compose(connect(null, mapDispatchToProps))(withRouter(App));
