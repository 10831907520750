import React, { useState, useEffect } from "react";
import { Image, Modal } from "semantic-ui-react";
import LoadingBar from "../../images/survivalImages/loading-bar.svg";
import * as LOADINGS from "../../constants/Loadings";
import "../../scss/components/_modal.scss";

export function LoadingModal(props) {
    const { message, open, type } = props;
    const [textIndex, setTextIndex] = useState(0);
    const [uploadText, setUploadText] = useState("");

    useEffect(() => {
        let timer = null;
        const handleLoadingMessages = () => {
            const textStrings = [message, LOADINGS.LOADING_PHOTOS_WAIT, LOADINGS.LOADING_PHOTOS_ALMOST_READY];

            if (type === "upload") {
                setUploadText(textStrings[textIndex]);
                if (textIndex === textStrings.length) {
                    setTextIndex(0);
                } else {
                    timer = setTimeout(() => {
                        setTextIndex(textIndex + 1);
                        setUploadText(textStrings[textIndex]);
                    }, 10000);
                }
            } else {
                setUploadText(message);
            }
        };

        handleLoadingMessages();

        return () => {
            clearTimeout(timer);
        };
    }, [textIndex, message, type]);

    return (
        <Modal className="error-modal" open={open} size="small">
            <Modal.Content>
                <br />
                <br />
                <Image src={LoadingBar} size="small" centered />
                <br />
                <br />
                <p>{uploadText}</p>
            </Modal.Content>
        </Modal>
    );
}
