import React from "react";
import { Divider } from "semantic-ui-react";
import { SubHeader, DefaultButton, LinkButton, CustomInput, CustomPasswordInput, SocialButton, ErrorModal } from "..";

function LoginComponent(props) {
    const {
        onChangeWriteOptions,
        state,
        prepareSignUpEmailAndPassword,
        signUpUserControl,
        goToSignUp,
        handleResetEmailModal,
        isSubscriptionRoutes,
        goToSubscrption,
        removeErrorMessage,
    } = props;

    let errorMessage = null;

    if (state.errorMessage.length > 0) {
        errorMessage = (
            <ErrorModal
                open={state.errorMessage.length > 0}
                message={state.errorMessage}
                input_t={state.input_t}
                closeFunction={removeErrorMessage}
            />
        );
    }

    return (
        <section className="padding-page">
            <SubHeader mainTitle="Sign In" specialText="Now you will get the perfect survival box" />
            <div className="flex-center container-padding">
                <SocialButton
                    socialNetworkIcon="google"
                    socialNetwork="google"
                    title="Sign In with Google"
                    buttonAction={() => signUpUserControl("google")}
                    loading={state.loadingSignInGmail}
                    disabled={state.disableSignUp}
                />
                <SocialButton
                    socialNetworkIcon="facebook f"
                    socialNetwork="facebook"
                    title="Sign In with Facebook"
                    buttonAction={() => signUpUserControl("facebook")}
                    loading={state.loadingSignInFacebook}
                    disabled={state.disableSignUp}
                />
            </div>
            <div className="input-container container-padding">
                <Divider horizontal>Or</Divider>
                <CustomInput
                    id="email"
                    placeholder="Email"
                    label="Email"
                    onChangeAction={onChangeWriteOptions}
                    value={state.email}
                    errorMessage={state.emailError}
                />
                <CustomPasswordInput
                    id="password"
                    placeholder="Password"
                    label="Password"
                    onChangeAction={onChangeWriteOptions}
                    value={state.password}
                    errorMessage={state.passwordError}
                />
            </div>
            <div className="padding-bottom flex-center flex-column container-padding">
                <DefaultButton
                    title="Sign In"
                    buttonAction={prepareSignUpEmailAndPassword}
                    loading={state.loadingSignUp}
                    disabled={state.disableSignUp}
                />
                <br />
                <LinkButton title="Forgot your password?" buttonAction={handleResetEmailModal} />
            </div>
            {errorMessage}
            {isSubscriptionRoutes && (
                <div className="padding-bottom flex-center flex-column button-container-padding">
                    <LinkButton
                        title="Create an Account"
                        buttonAction={goToSignUp}
                        textBefore="Don't Have an account?"
                    />
                </div>
            )}
            {!isSubscriptionRoutes && (
                <div className="padding-bottom flex-center flex-column button-container-padding">
                    <LinkButton
                        title="Get a Suvivalbox!"
                        buttonAction={goToSubscrption}
                        textBefore="Don't Have a Suvivalbox subscription?"
                    />
                </div>
            )}
        </section>
    );
}

export default LoginComponent;
