import React, { useEffect, useState, useReducer } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import { Container, Grid, Accordion, Header } from "semantic-ui-react";
import { BackButton, Loader, ConfirmationModal } from "../../components";
import ShippingAddress from "../../components/screenComponents/subscribe/stepFive/ShippingAddress";
import StepFiveComponent from "../../components/screenComponents/subscribe/stepFive/StepFiveComponent";
import OrderSummaryComponent from "../../components/screenComponents/subscribe/stepFive/OrderSummaryComponent";
import { getLastPaymentMethod, getUserSubscriptions, getInvoiceHostedUrl } from "../../services/SetupService";
import { getOrdersSummaryData } from "../../services/SubscriptionPlanService";
import { getLocalStorageItem } from "../../services/LocalStorageService";
import { getCookie } from "../../services/CookieService";
import { saveStepTwo, saveDiscountCoupon, saveStepThree, saveStepSix } from "../../store/subscriptions/actions";
import * as ROUTES from "../../navigation/Routes";
import * as CONSTANTS from "../../constants/Global";
import * as LOCALSTORAGE from "../../constants/LocalStorage";
import * as CONFIRMATIONS from "../../constants/Confirmations";
import "../../scss/pages/get_a_box.scss";

function StepFive(props) {
    const { subscriptions, history, saveStepTwo, saveStepThree, saveDiscountCoupon, userData } = props;
    const { subscriptionPlan, selectedAddOns, couponData, giftType, oneTimeGift, shippingPrice } = subscriptions;
    const email = getLocalStorageItem(LOCALSTORAGE.EMAIL);

    const [paymentMethod, setPaymentMethod] = useState("");
    const [ordersSummaryData, setOrdersSummaryData] = useState([]);
    const [processPayment, setProcessPayment] = useState(null);
    const [loadingSecondaryButton, setLoadingSecondaryButton] = useState(false);
    const [lastPaymentMethod, setLastPaymentMethod] = useState(null);
    const [loadingPaymentMethod, setLoadingPaymentMethod] = useState(false);
    const [showCreditCardForm, setShowCreditCardForm] = useState(false);
    const [accordionActiveIndex, setAccordionActiveIndex] = useState(0);
    const [previousIncompleteSubscription, setPreviousIncompleteSubscription] = useState(false);
    const [loadingUserSubscriptions, setLoadingUserSubscriptions] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [activeSubscriptions, setActiveSubscriptions] = useState(null);

    const [formState, setStateForm] = useReducer((state, newState) => ({ ...state, ...newState }), {
        localFirstName: "",
        localLastName: "",
        localPostalCode: "",
        localFirstNameError: false,
        localLastNameError: false,
        localPostalCodeError: false,
        subscribeNewsLetter: true,
    });

    const [cardElementsState, setCardElementsStates] = useReducer((state, newState) => ({ ...state, ...newState }), {
        cardCvcEmpty: true,
        cardExpiryEmpty: true,
        cardNumberEmpty: true,
        cardNumberEmptyError: "",
        cardExpiryEmptyError: "",
        cardCvcEmptyError: "",
    });

    useEffect(() => {
        const isLoggedIn = getCookie(LOCALSTORAGE.USER_DATA);

        if (subscriptions.subscriptionPlan.length === 0) {
            history.replace(ROUTES.STEP_ONE);
        }

        if (!isLoggedIn) {
            history.replace(ROUTES.SIGN_UP, {
                accountRoutes: "subscription",
            });
        }
    }, [subscriptions.subscriptionPlan, history]);

    useEffect(() => {
        if (userData && !userData.email) {
            setStateForm({
                subscribeNewsLetter: false,
            });
        }
    }, [userData]);

    useEffect(() => {
        const getSubscriptionsList = async () => {
            setLoadingUserSubscriptions(true);
            const userSubscriptions = await getUserSubscriptions(userData.user_id);

            if (userSubscriptions) {
                setLoadingUserSubscriptions(false);

                if (userSubscriptions.length > 0) {
                    const activeSubscriptions = userSubscriptions.filter(
                        (subscription) =>
                            subscription.status === CONSTANTS.SUBSCRIPTION_STATUS.ACTIVE ||
                            subscription.status === CONSTANTS.SUBSCRIPTION_STATUS.UNPAID ||
                            subscription.status === CONSTANTS.SUBSCRIPTION_STATUS.PAUSED
                    );

                    if (activeSubscriptions.length > 0) {
                        setShowConfirmModal(true);
                        setActiveSubscriptions(activeSubscriptions);
                    }

                    const incomppleteSubscription = userSubscriptions.find(
                        (subscription) => subscription.status === CONSTANTS.SUBSCRIPTION_STATUS.INCOMPLETE
                    );

                    if (incomppleteSubscription) {
                        const link = await getInvoiceHostedUrl(incomppleteSubscription.sessionId);

                        if (link) {
                            const incompleteMessage = {
                                message: `url_incomplete&&${incomppleteSubscription.parents
                                    .map((parent) => `${parent.parentType.toLowerCase()} ${parent.name}`)
                                    .join(" & ")}&&${incomppleteSubscription.sessionId}`,
                                link: link,
                            };
                            setPreviousIncompleteSubscription(incompleteMessage);
                        }
                    }
                }
            }
        };

        if (userData) {
            getSubscriptionsList();
        }
    }, [userData]);

    useEffect(() => {
        if (subscriptionPlan.length > 0) {
            const plansToPay = subscriptionPlan.length === 1 && subscriptionPlan.concat(selectedAddOns);

            if (plansToPay.length > 0) {
                plansToPay.push(shippingPrice);
            }

            const ordersSummaryData = getOrdersSummaryData(plansToPay);

            setOrdersSummaryData(ordersSummaryData);
        }
    }, [subscriptionPlan, selectedAddOns, shippingPrice]);

    useEffect(() => {
        setLoadingPaymentMethod(true);
        const getLastPayment = async () => {
            try {
                const lastPaymentMethod = await getLastPaymentMethod({
                    ownerId: userData.user_id,
                });
                if (lastPaymentMethod) {
                    if (typeof lastPaymentMethod !== "string") {
                        setLoadingPaymentMethod(false);
                        setShowCreditCardForm(false);
                        setLastPaymentMethod(lastPaymentMethod);
                        setPaymentMethod("lastPayment");
                    } else {
                        setShowCreditCardForm(true);
                        setLoadingPaymentMethod(false);
                        setPaymentMethod("anotherCard");
                    }
                } else {
                    setShowCreditCardForm(true);
                    setLoadingPaymentMethod(false);
                    setPaymentMethod("anotherCard");
                }
            } catch (error) {
                setShowCreditCardForm(true);
                setLoadingPaymentMethod(false);
                setPaymentMethod("anotherCard");
            }
        };

        if (userData && userData.user_id) {
            getLastPayment();
        }
    }, [userData]);

    const onChangePaymentMethod = (event) => {
        const paymentMethod = event.target.id;
        setPaymentMethod(paymentMethod);

        if (paymentMethod === "anotherCard") {
            setShowCreditCardForm(true);
        } else {
            setShowCreditCardForm(false);
        }
    };

    const onChangeWriteOptions = (event, { value }) => {
        const inputId = event.target.id;
        setStateForm({
            [inputId]: value,
            [`${inputId}Error`]: false,
        });
    };

    const onChangeCardNumber = (event) => {
        const cardElement = event.elementType;

        if (event.complete) {
            setCardElementsStates({
                [`${cardElement}Empty`]: event.empty,
                [`${cardElement}EmptyError`]: false,
            });
        } else {
            setCardElementsStates({
                [`${cardElement}Empty`]: event.empty,
                [`${cardElement}EmptyError`]: event.error && event.error.message,
            });
        }
    };

    const removeAddOn = (selection) => {
        const addOnRemoved = selection.priceId;
        const newSelectedAddOns = selectedAddOns.filter((addOn) => addOn.id !== addOnRemoved);

        saveStepThree(
            {
                selectedAddOns: newSelectedAddOns,
            },
            true
        );
    };

    const removeSubscription = (isJoin) => {
        saveStepTwo(
            {
                subscriptionPlan: [],
            },
            true
        );

        history.replace(ROUTES.STEP_TWO);
    };

    const saveAndPay = () => {
        setProcessPayment(true);
    };

    const resetFormValidation = () => {
        setProcessPayment(null);
    };

    const handleLoadingSecondaryButton = (state) => {
        setLoadingSecondaryButton(state);
    };

    const setSubscriptionAutoRenewing = (plan) => {
        if (plan.type === CONSTANTS.BOX_TYPES.SUBSCRIPTION) {
            const newSubscriptionPlan = subscriptionPlan.map((order) => {
                if (order.id === plan.priceId) {
                    const newOrder = {
                        ...order,
                        autoRenewing: !plan.autoRenewing,
                    };

                    if (!plan.autoRenewing) {
                        setAddOnsAutoRenewing(true);
                    } else {
                        setAddOnsAutoRenewing(false);
                    }

                    return newOrder;
                } else {
                    return order;
                }
            });

            saveStepTwo(
                {
                    subscriptionPlan: newSubscriptionPlan,
                },
                true
            );
        } else {
            setAddOnsAutoRenewing(plan);
        }
    };

    const setAddOnsAutoRenewing = (plan) => {
        let newSelectedAddOns = null;
        if (plan.hasOwnProperty("priceId")) {
            newSelectedAddOns = selectedAddOns.map((addOn) => {
                if (addOn.id === plan.priceId) {
                    const newAddOn = {
                        ...addOn,
                        autoRenewing: !plan.autoRenewing,
                    };

                    return newAddOn;
                } else {
                    return addOn;
                }
            });
        } else {
            if (typeof plan === "boolean") {
                newSelectedAddOns = selectedAddOns.map((addOn) => {
                    return {
                        ...addOn,
                        autoRenewing: plan,
                    };
                });
            } else {
                newSelectedAddOns = selectedAddOns.map((addOn) => {
                    return {
                        ...addOn,
                        autoRenewing: !addOn.autoRenewing,
                    };
                });
            }
        }

        saveStepThree(
            {
                selectedAddOns: newSelectedAddOns,
            },
            true
        );
    };

    const handleTitleClick = (event, itemProps) => {
        const { index } = itemProps;
        window.scrollTo(0, 0);
        if (accordionActiveIndex === 0) {
            if (index === -1) {
                setAccordionActiveIndex(1);
            }
        } else {
            if (index === 0) {
                setAccordionActiveIndex(0);
            }
        }
    };

    const handleSubscribeNewsLetter = () => {
        setStateForm({
            subscribeNewsLetter: !formState.subscribeNewsLetter,
        });
    };

    const getAccordionPanels = () => {
        const panels = [
            {
                key: 0,
                title: {
                    content: (
                        <Header
                            size="medium"
                            textAlign="center"
                            // content={
                            //     userShippingAddress
                            //         ? "Comfirm or update your Shipping Address"
                            //         : "Receiver Shipping Address"
                            // }
                            content="Receiver Shipping Address"
                        />
                    ),
                },
                content: {
                    content: <ShippingAddress type="subscription" handleTitleClick={handleTitleClick} />,
                },
            },
            {
                key: 1,
                title: {
                    content: (
                        <Header
                            size="medium"
                            textAlign="center"
                            content={
                                lastPaymentMethod
                                    ? "Use your last payment method or add a new one"
                                    : "Add Payment Method"
                            }
                        />
                    ),
                },
                content: {
                    content: (
                        <StepFiveComponent
                            paymentMethod={paymentMethod}
                            ordersSummaryData={ordersSummaryData}
                            cardElementsState={cardElementsState}
                            formState={formState}
                            subscriptions={subscriptions}
                            saveAndPay={saveAndPay}
                            onChangeWriteOptions={onChangeWriteOptions}
                            onChangePaymentMethod={onChangePaymentMethod}
                            onChangeCardNumber={onChangeCardNumber}
                            history={history}
                            processPayment={processPayment}
                            resetFormValidation={resetFormValidation}
                            handleLoadingSecondaryButton={handleLoadingSecondaryButton}
                            loadingPaymentMethod={loadingPaymentMethod}
                            lastPaymentMethod={lastPaymentMethod}
                            showCreditCardForm={showCreditCardForm}
                            previousIncompleteSubscription={previousIncompleteSubscription}
                            userData={userData}
                        />
                    ),
                },
            },
        ];

        return panels;
    };

    const confirmModal = showConfirmModal && (
        <ConfirmationModal
            open={showConfirmModal}
            message={CONFIRMATIONS.LAST_SUBSCRIPTIONS_LIST(activeSubscriptions)}
            composedMessage={true}
            confirmAction={() => setShowConfirmModal(false)}
            closeAction={() => (window.location = ROUTES.SURVIVALBOXES_URLS.HOME)}
        />
    );

    let content = null;

    if (loadingUserSubscriptions) {
        content = <Loader />;
    } else {
        content = (
            <>
                <Grid className="payment-grid" centered reversed="computer">
                    <Grid.Row>
                        <Grid.Column computer={8} tablet={8} mobile={16}>
                            <section className="step-six-form padding-page">
                                <Accordion
                                    activeIndex={accordionActiveIndex}
                                    panels={getAccordionPanels()}
                                    onTitleClick={handleTitleClick}
                                />
                                <div className="show-computer margin-top padding-bottom  container-padding">
                                    <BackButton title="back" />
                                </div>
                            </section>
                        </Grid.Column>
                        <Grid.Column computer={8} tablet={8} mobile={16}>
                            <OrderSummaryComponent
                                ordersSummaryData={ordersSummaryData}
                                saveAndPay={saveAndPay}
                                removeAddOn={removeAddOn}
                                removeSubscription={removeSubscription}
                                history={history}
                                loadingSecondaryButton={loadingSecondaryButton}
                                saveDiscountCoupon={saveDiscountCoupon}
                                couponData={couponData}
                                giftType={giftType}
                                oneTimeGift={oneTimeGift}
                                formState={formState}
                                cardElementsState={cardElementsState}
                                showCreditCardForm={showCreditCardForm}
                                handleSubscribeNewsLetter={handleSubscribeNewsLetter}
                                setSubscriptionAutoRenewing={setSubscriptionAutoRenewing}
                                email={email}
                                userData={userData}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </>
        );
    }

    return (
        <>
            {confirmModal}
            <Container className="payment-container">{content}</Container>
        </>
    );
}

const mapDispatchToProps = (dispatch) => ({
    saveStepTwo: (stepFour, isSet) => dispatch(saveStepTwo(stepFour, isSet)),
    saveStepThree: (stepFive, isSet) => dispatch(saveStepThree(stepFive, isSet)),
    saveStepSix: (stepSix) => dispatch(saveStepSix(stepSix)),
    saveDiscountCoupon: (couponData) => dispatch(saveDiscountCoupon(couponData)),
});

const mapStateToProps = (state) => {
    return {
        subscriptions: state.subscriptions,
        plans: state.business.plans,
        userData: state.user.userData,
        business: state.business,
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(withRouter(StepFive));
