import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import { ShippingAddressForm } from "../../../ShippingAddressForm";
import { saveStepSix } from "../../../../store/subscriptions/actions";
import {
    validateInputFilled,
    phoneNumberValidator,
    getFormattedPhoneNumber,
} from "../../../../services/DataValidationService";
import { waitForGlobal } from "../../../../services/LoadScriptService";
import { returnAddressInfoList } from "../../../../services/GoogleService";
import "../../../../scss/pages/get_a_box.scss";
const inputsIds = [
    { name: "localFirstName", error: "Please enter First Name" },
    { name: "localLastName", error: "Please enter Last Name" },
    { name: "localDeliveryPlace", error: "Please enter a Street Address" },
    { name: "localCity", error: "Please enter a City" },
    { name: "localState", error: "Please enter a State" },
    { name: "localPostalCode", error: "Please enter a Postal Code" },
];

class StepSix extends Component {
    constructor(props) {
        super(props);

        const { shippingAddress } = this.props.subscriptions;

        const {
            fistName,
            firstName,
            lastName,
            city,
            state,
            address,
            apt,
            postalCode,
            phoneNumber,
            phone,
            geometry,
        } = shippingAddress;

        let locateFirstName = "";

        if (fistName) {
            locateFirstName = fistName;
        } else {
            locateFirstName = firstName;
        }

        let locatePhoneNumber = "";

        if (phone) {
            locatePhoneNumber = phone;
        } else {
            locatePhoneNumber = phoneNumber;
        }

        const locateCountry = "United States";

        const localFirstName = locateFirstName.length > 0 ? locateFirstName : "";
        const localLastName = lastName?.length > 0 ? lastName : "";
        const localPhoneNumber = locatePhoneNumber.length > 0 ? locatePhoneNumber : "";
        const localCountry = locateCountry;
        const localCity = city?.length > 0 ? city : "";
        const localState = state?.length > 0 ? state : "";
        const localDeliveryPlace = address?.length > 0 ? address : "";
        const localApt = apt?.length > 0 ? apt : "";
        const localPostalCode = postalCode?.length > 0 ? postalCode : "";

        let showAddressMap = false;
        let localGeometry = null;

        if (geometry && Object.keys(geometry).length > 0) {
            showAddressMap = true;
            localGeometry = geometry;
        }

        this.state = {
            localFirstName: localFirstName,
            localLastName: localLastName,
            localPhoneNumber: localPhoneNumber,
            localCountry: localCountry,
            localCity: localCity,
            localState: localState,
            localDeliveryPlace: localDeliveryPlace,
            localApt: localApt,
            localPostalCode: localPostalCode,
            localGeometry: localGeometry,
            localFirstNameError: false,
            localLastNameError: false,
            localDeliveryPlaceError: false,
            localPhoneNumberError: false,
            localCityError: false,
            localStateError: false,
            localPostalCodeError: false,
            showAddressMap: showAddressMap,
            writedAddress: false,
        };
    }

    componentDidUpdate(lastProps) {
        const { subscriptions } = this.props;
        const { shippingAddress } = subscriptions;

        if (lastProps.subscriptions.shippingAddress !== subscriptions.shippingAddress) {
            const {
                firstName,
                fistName,
                lastName,
                city,
                state,
                address,
                apt,
                postalCode,
                phoneNumber,
                phone,
                geometry,
            } = shippingAddress;

            let locateFirstName = "";

            if (fistName) {
                locateFirstName = fistName;
            } else {
                locateFirstName = firstName;
            }

            let locatePhoneNumber = "";

            if (phone) {
                locatePhoneNumber = phone;
            } else {
                locatePhoneNumber = phoneNumber;
            }

            const locateCountry = "United States";

            const localFirstName = locateFirstName.length > 0 ? locateFirstName : "";
            const localLastName = lastName?.length > 0 ? lastName : "";
            const localPhoneNumber = locatePhoneNumber.length > 0 ? locatePhoneNumber : "";
            const localCountry = locateCountry;
            const localCity = city?.length > 0 ? city : "";
            const localState = state?.length > 0 ? state : "";
            const localDeliveryPlace = address.length > 0 ? address : "";
            const localApt = apt?.length > 0 ? apt : "";
            const localPostalCode = postalCode?.length > 0 ? postalCode : "";

            let showAddressMap = false;
            let localGeometry = null;

            if (geometry && Object.keys(geometry).length > 0) {
                showAddressMap = true;
                localGeometry = geometry;
            }

            this.setState({
                localFirstName: localFirstName,
                localLastName: localLastName,
                localCountry: localCountry,
                localCity: localCity,
                localState: localState,
                localDeliveryPlace: localDeliveryPlace,
                localApt: localApt,
                localPostalCode: localPostalCode,
                localPhoneNumber: localPhoneNumber,
                localGeometry: localGeometry,
                showAddressMap: showAddressMap,
            });
        }
    }

    onChangeWriteOptions = (event, { value }) => {
        const inputId = event.target.id;
        const blockedInputs = ["localCity", "localPostalCode", "localState"];

        if (this.state.writedAddress) {
            this.setState({
                [inputId]: value,
                [`${inputId}Error`]: false,
            });

            if (inputId === "localDeliveryPlace") {
                this.getMissingGeometry(value);
            }
        } else {
            if (!blockedInputs.includes(inputId)) {
                this.setState({
                    [inputId]: value,
                    [`${inputId}Error`]: false,
                });
            }
        }
    };

    saveAndContinueToPay = (event) => {
        const { saveStepSix, handleTitleClick } = this.props;
        const {
            localFirstName,
            localLastName,
            localCountry,
            localCity,
            localState,
            localDeliveryPlace,
            localApt,
            localPostalCode,
            localPhoneNumber,
            localGeometry,
        } = this.state;

        const formattedPhoneNumber = getFormattedPhoneNumber(localPhoneNumber);

        const shippingAddressData = {
            firstName: localFirstName,
            lastName: localLastName,
            country: localCountry,
            city: localCity,
            state: localState,
            address: localDeliveryPlace,
            apt: localApt,
            postalCode: localPostalCode,
            phoneNumber: formattedPhoneNumber,
            geometry: localGeometry,
        };

        const inputsRequiredAreFilled = this.checkInputsRequiredAreFilled();

        if (inputsRequiredAreFilled) {
            saveStepSix(shippingAddressData);
            handleTitleClick(event, { index: -1 });
        }
    };

    checkInputsRequiredAreFilled = () => {
        const inputsFilledList = this.checkIfInputAreFilledAndCreateList();
        const quantityInputsFilled = inputsFilledList.filter((isFilled) => isFilled === true);

        if (quantityInputsFilled.length === inputsIds.length) {
            return true;
        }
        return false;
    };

    checkIfInputAreFilledAndCreateList = () => {
        const inputsFilledQuantity = [];
        inputsIds.forEach((inputId) => {
            const inputFilled = validateInputFilled(this.state[inputId.name]);
            if (inputId.name !== "localApt" && inputId.name !== "localPhoneNumber") {
                inputsFilledQuantity.push(inputFilled);
            }

            if (!this.state.writedAddress) {
                if (
                    (inputId.name === "localCity" && !inputFilled) ||
                    (inputId.name === "localPostalCode" && !inputFilled) ||
                    (inputId.name === "localState" && !inputFilled)
                ) {
                    const inputFilled = validateInputFilled(this.state["localDeliveryPlace"]);

                    if (inputFilled) {
                        this.setState({
                            localDeliveryPlaceError: "Sorry, that address is not valid",
                        });
                    }
                }
            }

            if (inputId.name === "localPhoneNumber" && inputFilled) {
                const isValidPhoneNumber = phoneNumberValidator(this.state[inputId.name]);

                if (isValidPhoneNumber) {
                    inputsFilledQuantity.push(inputFilled);
                } else {
                    this.setState({
                        localPhoneNumberError: "Invalid phone number",
                    });
                }
            }

            if (!inputFilled) {
                this.setState({
                    [`${inputId.name}Error`]: inputId.error,
                });
            }
        });

        return inputsFilledQuantity;
    };

    onSelectedPlace = (selectedPlace) => {
        const addressComponents = selectedPlace.address_components;
        const geometry = selectedPlace.geometry;

        if (geometry) {
            this.showMapInformation(geometry);
        }

        if (addressComponents) {
            this.updateFormAddressItems(addressComponents);
        } else {
            if (selectedPlace.name) {
                this.setState({
                    localDeliveryPlace: selectedPlace.name,
                    localDeliveryPlaceError: "Sorry, that address is not valid",
                });
            } else {
                const inputId = selectedPlace.target.id;
                const value = selectedPlace.target.value;

                const isStreedModified = value.length !== this.state.localDeliveryPlace.length;

                this.setState({
                    [inputId]: value,
                    showAddressMap: false,
                    localDeliveryPlaceError: "",
                });

                if (isStreedModified) {
                    this.setState({
                        localCity: "",
                        localState: "",
                        localPostalCode: "",
                    });
                }
            }
        }
    };

    updateFormAddressItems = (addressComponents) => {
        const address = returnAddressInfoList(addressComponents);
        this.setState({
            localCity: address.city,
            localState: address.state,
            localDeliveryPlace: address.address,
            localPostalCode: address.postalCode,
            localDeliveryPlaceError: false,
            localPostalCodeError: false,
        });
    };

    showMapInformation = (geometry) => {
        this.setState({
            showAddressMap: true,
            localGeometry: geometry,
        });
    };

    writedAddressManually = (event) => {
        event.preventDefault();

        this.setState((state, props) => ({
            writedAddress: !state.writedAddress,
            localCity: "",
            localState: "",
            localDeliveryPlace: "",
            localPostalCode: "",
            showAddressMap: false,
            localFirstNameError: false,
            localLastNameError: false,
            localDeliveryPlaceError: false,
            localPhoneNumberError: false,
            localCityError: false,
            localStateError: false,
            localPostalCodeError: false,
        }));
    };

    getMissingGeometry = (localDeliveryPlace) => {
        const { localCity, localState, localPostalCode } = this.state;
        let self = this;

        const googleIsLoaded = waitForGlobal("google");

        googleIsLoaded.then((isLoaded) => {
            if (isLoaded) {
                const placeToFind = `${localDeliveryPlace} ${localCity} ${localState} ${localPostalCode}`;

                const geocoder = new window.google.maps.Geocoder();
                geocoder.geocode({ address: placeToFind }, function (results, status) {
                    if (status === "OK") {
                        const geometry = results[0].geometry;
                        delete geometry["location_type"];

                        self.setState({
                            localGeometry: geometry,
                            showAddressMap: true,
                        });
                    } else {
                        console.log("Geocode was not successful for the following reason: " + status);
                    }
                });
            }
        });
    };

    render() {
        const { type, subscriberString } = this.props;

        return (
            <ShippingAddressForm
                subscriberString={subscriberString}
                state={this.state}
                onChangeWriteOptions={this.onChangeWriteOptions}
                onSelectedPlace={this.onSelectedPlace}
                submitAction={this.saveAndContinueToPay}
                submitButtonTitle="Save to continue"
                subHeaderType="subscription"
                showBackButton={true}
                writedAddressManually={this.writedAddressManually}
                type={type}
            />
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    saveStepSix: (stepSix) => dispatch(saveStepSix(stepSix)),
});

const mapStateToProps = (state) => {
    return {
        subscriptions: state.subscriptions,
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(withRouter(StepSix));
