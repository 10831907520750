let SURVIVALBOXES_MAIN_URL = process.env.REACT_APP_SURVIVALBOXES_URL;

if (!SURVIVALBOXES_MAIN_URL) {
    SURVIVALBOXES_MAIN_URL = "https://survivalbox.com/";
}

export const SURVIVALBOXES_URLS = {
    HOME: SURVIVALBOXES_MAIN_URL,
    TERMS_OF_USE: `${SURVIVALBOXES_MAIN_URL}privacy-policy`,
    PRIVACY_POLICITY: `${SURVIVALBOXES_MAIN_URL}terms-of-services`,
    404: `${SURVIVALBOXES_MAIN_URL}404`,
};

export const INITIAL = "/";

//subscription
export const SUBSCRIBE = "/subscribe";
export const STEP_ONE = `${SUBSCRIBE}/step-1`;
export const STEP_TWO = `${SUBSCRIBE}/step-2`;
export const STEP_THREE = `${SUBSCRIBE}/step-3`;
export const STEP_FOUR = `${SUBSCRIBE}/step-4`;
export const STEP_FIVE = `${SUBSCRIBE}/step-5`;
export const SUCCESSFUL = `${SUBSCRIBE}/successful`;

//manage-subscription
export const ACCOUNT = "/account";
export const SUBSCRIPTION_SUMMARY = `${ACCOUNT}/summary`;
export const BOX_DESCRIPTION = `${ACCOUNT}/box-description`;
export const SHIPPING_ADDRESS = `${ACCOUNT}/shipping-address`;
export const SIGN_UP = `${ACCOUNT}/sign-up`;
export const LOGIN = `${ACCOUNT}/login`;
export const RESET_PASSWORD = `${ACCOUNT}/reset-password`;
export const EMAIL_VALIDATION = `${ACCOUNT}/email-validation`;
export const USER_INFORMATION = `${ACCOUNT}/user-information`;
export const UPDATE_PAYMENT_METHOD = `${ACCOUNT}/update-payment-method`;

//cancel subscription
export const CANCEL_SUBSCRIPTION = "/cancel-subscription";
export const CANCEL_SURVEY = `${CANCEL_SUBSCRIPTION}/cancel-survey`;
export const CONFIRM_CANCELATION = `${CANCEL_SUBSCRIPTION}/confirm-cancelation`;

export const ROUTES_LIST = [
    INITIAL,
    SIGN_UP,
    LOGIN,
    RESET_PASSWORD,
    EMAIL_VALIDATION,
    SUCCESSFUL,
    SUBSCRIBE,
    STEP_ONE,
    STEP_TWO,
    STEP_THREE,
    STEP_FOUR,
    STEP_FIVE,
    ACCOUNT,
    SUBSCRIPTION_SUMMARY,
    BOX_DESCRIPTION,
    SHIPPING_ADDRESS,
    CANCEL_SURVEY,
    CONFIRM_CANCELATION,
    USER_INFORMATION,
];

export const HIDE_PROMO_ROUTES = [
    RESET_PASSWORD,
    EMAIL_VALIDATION,
    BOX_DESCRIPTION,
    SHIPPING_ADDRESS,
    CANCEL_SUBSCRIPTION,
    CANCEL_SURVEY,
    CONFIRM_CANCELATION,
    SUBSCRIPTION_SUMMARY,
    USER_INFORMATION,
    UPDATE_PAYMENT_METHOD,
];

export const HIDE_GIFT_TITLE_ROUTES = [
    RESET_PASSWORD,
    EMAIL_VALIDATION,
    SUBSCRIPTION_SUMMARY,
    BOX_DESCRIPTION,
    SHIPPING_ADDRESS,
    CANCEL_SUBSCRIPTION,
    CANCEL_SURVEY,
    CONFIRM_CANCELATION,
    LOGIN,
    SIGN_UP,
    STEP_FOUR,
    USER_INFORMATION,
    UPDATE_PAYMENT_METHOD,
];

export const HIDE_UPGRADE_TITLE_ROUTES = [
    RESET_PASSWORD,
    EMAIL_VALIDATION,
    SUBSCRIPTION_SUMMARY,
    BOX_DESCRIPTION,
    SHIPPING_ADDRESS,
    CANCEL_SUBSCRIPTION,
    CANCEL_SURVEY,
    CONFIRM_CANCELATION,
    LOGIN,
    SIGN_UP,
    USER_INFORMATION,
    UPDATE_PAYMENT_METHOD,
];
