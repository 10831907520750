import React, { useState, useEffect } from "react";
import { Image, Modal } from "semantic-ui-react";
import { DefaultButton, LinkButton } from "../../components";
import ConfirmImage from "../../images/survivalImages/confirm.svg";
import SadOldMan from "../../images/survivalImages/error.svg";
import * as ROUTES from "../../navigation/Routes";
import "../../scss/components/_modal.scss";

export function PaymentResponseModal(props) {
    const {
        message,
        open,
        closeFunction,
        paymentLink,
        checkLastPayment,
        loadingSubscriptions,
        retryPaymentVerification,
        history,
    } = props;
    const [validatePayment, setValidatePayment] = useState(false);
    const [messageText, setMessageText] = useState("");
    const [parents, setParents] = useState("");
    const [sessionId, setSessionId] = useState("");

    useEffect(() => {
        const messageText = message.split("&&");

        if (messageText) {
            setMessageText(messageText[0]);

            if (messageText[1]) {
                setParents(messageText[1]);
            }

            if (messageText[2]) {
                setSessionId(messageText[2]);
            }
        }
    }, [message]);

    let linkSection = null;
    let buttonSection = null;
    let messageSection = null;
    let buttonTitle = "";

    const goToLink = () => {
        window.open(paymentLink, "_blank", "fullscreen=no,height=500,resizable=yes,width=600,left=400,top=100");
        setValidatePayment(true);

        setMessageText("confirm");
    };

    const goToManageSubscription = () => {
        history.replace(ROUTES.SUBSCRIPTION_SUMMARY);
    };

    switch (messageText) {
        case "url_incomplete":
            messageSection = (
                <p>
                    We detect that you have an incomplete subscription process.
                    <br />
                    <br />
                    The subscription has as beneficiary(ies) your <span className="color-cta">{parents}</span>
                    <br />
                    <br />
                    <span>
                        Only you need press the button and <span className="color-cta">follow the steps</span>, when you
                        finish, close the window that will be opened and let us confirm your payment.
                        <br />
                    </span>
                </p>
            );
            buttonTitle = "Confirm Charge";
            break;
        case "url":
            messageSection = (
                <p>
                    All is going fine, but your bank requires that you confirm the charge.
                    <br />
                    <br />
                    <span>
                        Please press the button and <span className="color-cta">follow the steps</span>, when you
                        finish, close the window that will be opened and let us confirm your payment.
                        <br />
                    </span>
                </p>
            );
            buttonTitle = "Confirm Charge";
            break;
        case "session_exist":
            messageSection = (
                <p>
                    Something has occurred during your subscription process.
                    <br />
                    <br />
                    <span>
                        But don't worry is possible that your subscription is ready, please check in
                        <br />
                        <span className="color-cta">Manage Subscription</span> section to confirm.
                    </span>
                </p>
            );
            buttonTitle = "Go to Managee Subscription";
            buttonSection = <DefaultButton title={buttonTitle} buttonAction={goToManageSubscription} />;
            break;
        case "confirm":
            messageSection = null;
            buttonTitle = "Go to Managee Subscription";
            break;
        default:
            messageSection = <p>{messageText}</p>;
            break;
    }

    if (validatePayment) {
        if (loadingSubscriptions) {
            linkSection = <p>Verifiying your payment...</p>;
        } else {
            let confirmPaymentText = "Have you already confirmed the charge?";

            if (retryPaymentVerification) {
                confirmPaymentText = "Your charge isn't verified yet, to try again";
            }

            linkSection = (
                <>
                    <LinkButton
                        textBefore={confirmPaymentText}
                        title="Click here!"
                        buttonAction={() => checkLastPayment(sessionId)}
                    />
                    <br />
                    <LinkButton
                        textBefore="Have you closed the authorization window without completing the process?"
                        title="Click here!"
                        buttonAction={goToLink}
                    />
                </>
            );
        }
    } else {
        if (paymentLink) {
            buttonSection = (
                <>
                    {messageText === "url_incomplete" && (
                        <div className="action-buttons-content">
                            <DefaultButton inverted={true} title="Ignore" buttonAction={closeFunction} />
                        </div>
                    )}
                    <div className="action-buttons-content">
                        <DefaultButton title={buttonTitle} buttonAction={goToLink} />;
                    </div>
                </>
            );
        }
    }

    let closeAction = null;

    if (!paymentLink) {
        closeAction = closeFunction;
    }

    return (
        <Modal className="confirm-modal" open={open} size="small" closeIcon={!paymentLink} onClose={closeAction}>
            <Modal.Content>
                <Image src={paymentLink ? ConfirmImage : SadOldMan} size="small" centered />

                {messageSection}

                {linkSection}
            </Modal.Content>

            {buttonSection && (
                <Modal.Actions>
                    <div className="action-buttons-container">{buttonSection}</div>
                </Modal.Actions>
            )}
        </Modal>
    );
}
