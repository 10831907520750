import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import { Container } from "semantic-ui-react";
import SuccessfulComponent from "../../components/screenComponents/subscribe/SuccessfulComponent";
import { resetSubscriptionStore, saveStepOne } from "../../store/subscriptions/actions";
import { SURVIVAL_BOXES } from "../../services/SubscriptionPlanService";
import * as ROUTES from "../../navigation/Routes";
import "../../scss/pages/get_a_box.scss";

function Successful(props) {
    const { history, subscriptions, saveStepOne, resetSubscriptionStore } = props;

    const selectedPlan = SURVIVAL_BOXES.find((box) => box.survivalType === subscriptions.subscriptionBox);
    const complementPlan = SURVIVAL_BOXES.find((box) => box.survivalType !== subscriptions.subscriptionBox);

    const goToManageSubscriptions = () => {
        resetSubscriptionStore();
        history.replace(ROUTES.SUBSCRIPTION_SUMMARY);
    };

    const goToStepTwo = () => {
        saveStepOne({
            subscriptionBox: complementPlan.survivalType,
            subscriptionPlan: [],
            selectedAddOns: [],
            couponData: null,
            giftType: null,
            oneTimeGift: false,
        });
        history.replace(ROUTES.STEP_TWO);
    };

    return (
        <>
            <Container className="payment-container">
                <SuccessfulComponent
                    goToManageSubscriptions={goToManageSubscriptions}
                    goToStepTwo={goToStepTwo}
                    selectedPlan={selectedPlan}
                    complementPlan={complementPlan}
                />
            </Container>
        </>
    );
}

const mapDispatchToProps = (dispatch) => ({
    resetSubscriptionStore: () => dispatch(resetSubscriptionStore()),
    saveStepOne: (stepOne) => dispatch(saveStepOne(stepOne)),
});

const mapStateToProps = (state) => {
    return {
        subscriptions: state.subscriptions,
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(withRouter(Successful));
