import axios from "axios";
import { graphqlServiceSauce, graphqlServiceAuthSauce, cloudfunctionsServiceSauce } from "./HttpService";
import { divideProductsList } from "./SubscriptionPlanService";
import { ProductList } from "./graphql/ProductList";
import { UpdateBox } from "./graphql/UpdateBox";
import { GetMe } from "./graphql/GetMe";
import { GetPromotionCode } from "./graphql/GetPromotionCode";
import { GetPaymentMethod } from "./graphql/GetPaymentMethod";
import { AddSubscription } from "./graphql/AddSubscription";
import { CancelSubscription } from "./graphql/CancelSubscription";
import { GetInvoiceHostedUrl } from "./graphql/GetInvoiceHostedUrl";
import { CheckInvoice } from "./graphql/CheckInvoice";
import { GetCustomerPortal } from "./graphql/GetCustomerPortal";
import { GetMeProfile } from "./graphql/GetMeProfile";
import { AddAddOns } from "./graphql/AddAddOns";
import { ChangeSubscriptionPlan } from "./graphql/ChangeSubscriptionPlan";
import { UpdateAddOns } from "./graphql/UpdateAddOns";
import { UpdatePaymentMethod } from "./graphql/UpdatePaymentMethod";
import { ResetPassword } from "./graphql/ResetPassword";
import { renewSessionId } from "./SubscriptionPlanService";
import { getLocalStorageItem, setLocalStorageItem, removeLocalStorageItem } from "./LocalStorageService";
import { getCookie } from "./CookieService";
import * as ROUTES from "../navigation/Routes";
import * as CONSTANTS from "../constants/Global";
import * as LOCALSTORAGE from "../constants/LocalStorage";

export const getProductsList = async () => {
    try {
        const productsListResponse = await graphqlServiceSauce(ProductList);
        console.log(productsListResponse);

        if (productsListResponse.data && productsListResponse.data.data) {
            const productsList = productsListResponse.data.data.productsList;

            const dividedProductsList = await divideProductsList(productsList);

            return dividedProductsList;
        }
    } catch (error) {
        return false;
    }
};

export const getUserSubscriptions = async (userId) => {
    const ownerId = getLocalStorageItem(LOCALSTORAGE.OWNERID);
    let searchOwnerId = null;

    if (ownerId) {
        searchOwnerId = ownerId;
    } else {
        if (userId) {
            searchOwnerId = userId;
        }
    }

    try {
        const userSubscriptionResponse = await graphqlServiceAuthSauce(GetMe, { ownerId: searchOwnerId });
        console.log(userSubscriptionResponse);

        if (userSubscriptionResponse.data) {
            if (userSubscriptionResponse.data.data) {
                const subscriptionsList = userSubscriptionResponse.data.data.getMe;
                return subscriptionsList;
            }

            if (userSubscriptionResponse.data.errors) {
                const message = userSubscriptionResponse.data.errors[0].message;
                let error = null;

                switch (message) {
                    case "ACCESSO NO AUTORIZADO":
                        error =
                            "You are trying to access the subscriptions of a user that you do not have access to, please contact the support staff to resolve the issue.";
                        break;

                    default:
                        error = false;
                        break;
                }

                return error;
            }
        }
    } catch (error) {
        console.log(error);

        return false;
    }
};

export const getUserProfile = async (userId) => {
    const ownerId = getLocalStorageItem(LOCALSTORAGE.OWNERID);
    let searchOwnerId = null;

    if (ownerId) {
        searchOwnerId = ownerId;
    } else {
        if (userId) {
            searchOwnerId = userId;
        }
    }

    try {
        const userProfileResponse = await graphqlServiceAuthSauce(GetMeProfile, { ownerId: searchOwnerId });
        console.log(userProfileResponse);

        if (userProfileResponse.data) {
            if (userProfileResponse.data.data) {
                const subscriptionsList = userProfileResponse.data.data.getMeProfile;
                return subscriptionsList;
            }

            if (userProfileResponse.data.errors) {
                const message = userProfileResponse.data.errors[0].message;
                let error = null;

                switch (message) {
                    case "ACCESSO NO AUTORIZADO":
                        error =
                            "You are trying to access the subscriptions of a user that you do not have access to, please contact the support staff to resolve the issue.";
                        break;

                    default:
                        error = false;
                        break;
                }

                return error;
            }
        }
    } catch (error) {
        console.log(error);

        return false;
    }
};

export const updateBox = async (data) => {
    try {
        const updatedBoxResponse = await graphqlServiceAuthSauce(UpdateBox, data);
        console.log(updatedBoxResponse);

        if (updatedBoxResponse.data && updatedBoxResponse.data.data) {
            const updatedBox = updatedBoxResponse.data.data.updateBox;
            return updatedBox;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
};

export const postSubscription = async (submitSubscriptionData) => {
    const sessionId = submitSubscriptionData.input.sessionId;

    try {
        const subscriptionResponse = await graphqlServiceAuthSauce(AddSubscription, submitSubscriptionData);
        console.log(subscriptionResponse);

        if (subscriptionResponse.data) {
            if (subscriptionResponse.data.data.addSubscription) {
                const subscriptionData = subscriptionResponse.data.data.addSubscription;

                if (subscriptionData.hasOwnProperty("type")) {
                    const stripeUrl = {
                        message: "url",
                        link: subscriptionData.use_stripe_sdk.stripe_js,
                    };

                    return stripeUrl;
                } else {
                    return subscriptionData;
                }
            }

            if (subscriptionResponse.data.errors) {
                let error = {};
                let message = "";

                if (subscriptionResponse.data.errors[0].message.includes("idempotent")) {
                    renewSessionId();
                    message = "An incomplete payment process was detected please close this popup and try again.";
                } else {
                    message = subscriptionResponse.data.errors[0].message;
                }

                switch (message) {
                    case "Your card was declined.":
                        error.message =
                            "Your card was declined. Maybe your card was reported how stolen or lost or has some restriction, contact your bank.";
                        break;
                    case "requires_action":
                        error.message = "url";
                        error.link = await getInvoiceHostedUrl(sessionId);
                        break;
                    case "SESSION_EXIST":
                        const subscriptions = await getUserSubscriptions();
                        if (subscriptions) {
                            const subscriptionIncomplete = subscriptions.some(
                                (subscription) =>
                                    subscription.status === CONSTANTS.SUBSCRIPTION_STATUS.INCOMPLETE &&
                                    subscription.sessionId === sessionId
                            );

                            if (subscriptionIncomplete) {
                                error.message = "url";
                                error.link = await getInvoiceHostedUrl(sessionId);
                            } else {
                                error.message = "session_exist";
                            }
                        }
                        break;
                    default:
                        error.message = message;
                        break;
                }

                return error;
            }
        }
    } catch (error) {
        console.log(error);

        return false;
    }
};

export const getInvoiceHostedUrl = async (sessionId) => {
    try {
        const hostedUrlResponse = await graphqlServiceAuthSauce(GetInvoiceHostedUrl, {
            sessionId: sessionId,
        });
        console.log(hostedUrlResponse);

        if (hostedUrlResponse.data) {
            if (hostedUrlResponse.data.data) {
                const hostedUrl = hostedUrlResponse.data.data.getInvoiceHostedUrl;

                return hostedUrl;
            }

            if (hostedUrlResponse.data.errors) {
                return hostedUrlResponse.data.errors[0].message;
            }
        }
    } catch (error) {
        console.log(error);

        return false;
    }
};

export const validateDiscountCoupon = async (couponData) => {
    try {
        const getPromotionCodeResponse = await graphqlServiceAuthSauce(GetPromotionCode, couponData);
        console.log(getPromotionCodeResponse);

        if (getPromotionCodeResponse.data.data && getPromotionCodeResponse.data.data.getPromotionCode) {
            const coupon = getPromotionCodeResponse.data.data.getPromotionCode;

            return coupon;
        } else {
            if (getPromotionCodeResponse.data.errors) {
                const error = getPromotionCodeResponse.data.errors[0].message;

                if (error === "INFORMACION NO ENCONTRADA") {
                    return "not found";
                } else {
                    return "already used";
                }
            }
        }
    } catch (error) {
        return false;
    }
};

export const cancelSubscription = async (cancelData, email) => {
    try {
        const cancelSubscriptionResponse = await graphqlServiceAuthSauce(CancelSubscription, cancelData);
        console.log(cancelSubscriptionResponse);

        if (cancelSubscriptionResponse.data.data && cancelSubscriptionResponse.data.data.cancelSubscription) {
            const cancelSubscription = cancelSubscriptionResponse.data.data.cancelSubscription;

            if (email) {
                await axios.delete(
                    `https://us-central1-oneway-grandbox.cloudfunctions.net/subscribeToNewsLetter/?email=${email}&brand=Survivalbox`
                );
            }

            return cancelSubscription;
        } else {
            if (cancelSubscriptionResponse.data.errors) {
                const error = cancelSubscriptionResponse.data.errors[0].message;

                if (error === "Error: COUPON REDEEMED") {
                    return {
                        message: "This discount has been applied previously, you can't apply it again",
                    };
                } else {
                    return false;
                }
            }
        }
    } catch (error) {
        return false;
    }
};

export const getLastPaymentMethod = async (ownerId) => {
    try {
        const paymentMethodResponse = await graphqlServiceAuthSauce(GetPaymentMethod, ownerId);
        console.log(paymentMethodResponse);

        if (paymentMethodResponse.data.data && paymentMethodResponse.data.data.getPaymentMethod) {
            const paymentMethod = paymentMethodResponse.data.data.getPaymentMethod;
            return paymentMethod;
        } else {
            if (paymentMethodResponse.data.errors) {
                return paymentMethodResponse.data.errors[0].message;
            }
        }
    } catch (error) {
        return false;
    }
};

export const getCustomerPortal = async (userId) => {
    try {
        const customerPortalResponse = await graphqlServiceAuthSauce(GetCustomerPortal, userId);
        console.log(customerPortalResponse);
        if (customerPortalResponse.data.data && customerPortalResponse.data.data.getCustomerPortal) {
            const customerPortal = customerPortalResponse.data.data.getCustomerPortal;
            return customerPortal;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
};

export const checkLastInvoice = async (subscriptionId) => {
    try {
        const checkInvoiceResponse = await graphqlServiceAuthSauce(CheckInvoice, subscriptionId);
        console.log(checkInvoiceResponse);

        if (checkInvoiceResponse.data.data && checkInvoiceResponse.data.data.checkInvoice) {
            const checkInvoice = checkInvoiceResponse.data.data.checkInvoice;
            return checkInvoice;
        } else {
            if (checkInvoiceResponse.data.errors) {
                const error = checkInvoiceResponse.data.errors[0].message;

                if (error === "invoice_unpaid") {
                    return "invoice_unpaid";
                } else {
                    return false;
                }
            }
        }
    } catch (error) {
        return false;
    }
};

export const getMoreAddOns = async (addOnsData) => {
    console.log(addOnsData);
    try {
        const addAddOnsResponse = await graphqlServiceAuthSauce(AddAddOns, addOnsData);
        console.log(addAddOnsResponse);

        if (addAddOnsResponse.data.data && addAddOnsResponse.data.data.addAddOns) {
            const addAddOns = addAddOnsResponse.data.data.addAddOns;
            return addAddOns;
        } else {
            if (addAddOnsResponse.data.errors) {
                const error = addAddOnsResponse.data.errors[0].message;

                return error;
            }
        }
    } catch (error) {
        return false;
    }
};

export const updateAddOns = async (addOnsData) => {
    try {
        const updateAddOnsResponse = await graphqlServiceAuthSauce(UpdateAddOns, addOnsData);
        console.log(updateAddOnsResponse);

        if (updateAddOnsResponse.data.data && updateAddOnsResponse.data.data.updateAddOns) {
            const updateAddOns = updateAddOnsResponse.data.data.updateAddOns;
            return updateAddOns;
        } else {
            if (updateAddOnsResponse.data.errors) {
                const error = updateAddOnsResponse.data.errors[0].message;

                return error;
            }
        }
    } catch (error) {
        return false;
    }
};

export const updatePaymentMethod = async (paymentMethod) => {
    try {
        const updatePaymentMethodResponse = await graphqlServiceAuthSauce(UpdatePaymentMethod, paymentMethod);

        if (updatePaymentMethodResponse.data.data && updatePaymentMethodResponse.data.data.updatePaymentMethod) {
            const updatePaymentMethod = updatePaymentMethodResponse.data.data.updatePaymentMethod;
            return updatePaymentMethod;
        } else {
            if (updatePaymentMethodResponse.data.errors) {
                const error = updatePaymentMethodResponse.data.errors[0].message;

                return error;
            }
        }
    } catch (error) {
        return false;
    }
};

export const resetPassword = async (passwordData) => {
    try {
        const resetPasswordResponse = await graphqlServiceAuthSauce(ResetPassword, passwordData);

        if (resetPasswordResponse.data.data && resetPasswordResponse.data.data.resetPassword) {
            const resetPassword = resetPasswordResponse.data.data.resetPassword;
            return resetPassword;
        } else {
            if (resetPasswordResponse.data.errors) {
                const error = resetPasswordResponse.data.errors[0].message;

                return error;
            }
        }
    } catch (error) {
        return false;
    }
};

export const changeSubscriptionFunction = async (subscriptionDataToSend) => {
    try {
        const changeSubscriptionResponse = await graphqlServiceAuthSauce(
            ChangeSubscriptionPlan,
            subscriptionDataToSend
        );

        if (changeSubscriptionResponse.data.data && changeSubscriptionResponse.data.data.changeSubscriptionPlan) {
            const ChangeSubscriptionPlan = changeSubscriptionResponse.data.data.changeSubscriptionPlan;
            return ChangeSubscriptionPlan;
        } else {
            if (changeSubscriptionResponse.data.errors) {
                const error = changeSubscriptionResponse.data.errors[0].message;

                return error;
            }
        }
    } catch (error) {
        return false;
    }
};

export const setupAccountInitialRoute = async (
    history,
    searchParams,
    subscriptions,
    saveSurvivalType,
    resetSubscriptionStore,
    saveStepFour,
    plans,
    shipping
) => {
    const isResetPassword = searchParams.some((param) => param === CONSTANTS.INITAL_PARAMS.RESET);
    const isMigration = searchParams.some((param) => param === CONSTANTS.INITAL_PARAMS.MIGRATION);
    const isEmailValidation = searchParams.some((param) => param === CONSTANTS.INITAL_PARAMS.EMAIL_VALIDATION);
    const isSubscribe =
        searchParams.some((param) => param === CONSTANTS.INITAL_PARAMS.SUBSCRIBE) ||
        history.location.pathname === ROUTES.SUBSCRIBE;
    const isCommunity = searchParams.some((param) => param === CONSTANTS.INITAL_PARAMS.COMMUNITY);
    const survivalType = searchParams.find((param) => param.includes("survivalType"));
    const type = searchParams.find((param) => param.includes("type"));
    const ownerId = searchParams.find((param) => param.includes("ownerId"));
    const oldSubscriber = searchParams.find((param) => param.includes("oldSubscriber"));

    if (ownerId) {
        ownerIdParam(history, ownerId);
    } else if (isResetPassword) {
        resetPasswordParam(history, searchParams);
    } else if (isEmailValidation) {
        emailValidationParam(history, searchParams);
    } else if (isMigration) {
        migrationParam(history, searchParams);
    } else if (isSubscribe) {
        subscribeParam(
            history,
            resetSubscriptionStore,
            survivalType,
            saveSurvivalType,
            oldSubscriber,
            type,
            plans,
            shipping,
            saveStepFour
        );
    } else if (isCommunity) {
        manageCommunitySession(history);
    } else {
        noParamsHandler(history, subscriptions);
    }
};

const ownerIdParam = (history, ownerId) => {
    const ownerIdItem = ownerId.split("=");
    setLocalStorageItem(ownerIdItem[0], ownerIdItem[1]);
    removeLocalStorageItem(LOCALSTORAGE.STORAGED_USER_SUBSCRIPTIONS);
    removeLocalStorageItem(LOCALSTORAGE.SELECTED_SUBSCRIPTION);
    history.push(ROUTES.SUBSCRIPTION_SUMMARY);
};

const resetPasswordParam = (history, searchParams) => {
    history.push(ROUTES.RESET_PASSWORD, {
        searchParams: searchParams,
        accountRoutes: "subscription",
    });
};

const emailValidationParam = (history, searchParams) => {
    history.push(ROUTES.EMAIL_VALIDATION, {
        searchParams: searchParams,
        accountRoutes: "manage-subscription",
    });
};

const migrationParam = (history, searchParams) => {
    searchParams.forEach((item) => {
        if (!item.includes("mode")) {
            const itemSplited = item.split("=");
            setLocalStorageItem(itemSplited[0], itemSplited[1]);
        }
    });

    history.push(ROUTES.LOGIN, {
        accountRoutes: "subscription",
        showModal: true,
    });
};

const subscribeParam = (
    history,
    resetSubscriptionStore,
    survivalType,
    saveSurvivalType,
    oldSubscriber,
    type,
    plans,
    shipping,
    saveStepFour
) => {
    resetSubscriptionStore();

    if (survivalType) {
        saveSurvivalType(null, survivalType.split("=")[1]);

        if (oldSubscriber) {
            setLocalStorageItem(LOCALSTORAGE.OLD_SUBSCRIBER, Boolean(oldSubscriber.split("=")[1]));
        }

        if (type) {
            const selectedPrices = plans
                .find((plan) => plan.metadata.survivalType === survivalType.split("=")[1])
                .prices.filter((price) => price.metadata.type === type.split("=")[1]);

            const subscriptionPrice = {
                ...selectedPrices[0],
                autoRenewing: true,
            };

            if (selectedPrices.length > 0) {
                const shippingPrice = {
                    ...shipping[0].prices[0],
                    autoRenewing: true,
                    quantity: 1,
                };

                saveStepFour({
                    subscriptionPlan: [subscriptionPrice],
                    shippingPrice: shippingPrice,
                    oneTimeGift: false,
                });

                history.push(`${ROUTES.SUBSCRIBE}/step-3`);
            }
        } else {
            history.push(`${ROUTES.SUBSCRIBE}/step-2`);
        }
    } else {
        history.push(ROUTES.STEP_ONE);
    }
};

const noParamsHandler = (history, subscriptions) => {
    if (!subscriptions.step) {
        if (![ROUTES.SUCCESSFUL].includes(history.location.pathname)) {
            history.push(ROUTES.SUBSCRIPTION_SUMMARY);
        }
    } else {
        if (history.location.pathname.includes(ROUTES.SUBSCRIBE)) {
            history.push(`${ROUTES.SUBSCRIBE}/step-${subscriptions.step}`);
        } else {
            if (![ROUTES.SIGN_UP, ROUTES.LOGIN, ROUTES.SUCCESSFUL].includes(history.location.pathname)) {
                history.push(ROUTES.SUBSCRIPTION_SUMMARY);
            }
        }
    }
};

const manageCommunitySession = (history) => {
    const isLoggedIn = getCookie(LOCALSTORAGE.USER_DATA);

    if (isLoggedIn) {
        getTribeCommunityToken();
    } else {
        history.push(ROUTES.LOGIN, { accountRoutes: "community" });
    }
};

export const getTribeCommunityToken = async () => {
    try {
        const getCommunityTokenResponse = await cloudfunctionsServiceSauce("generateTribeToken");

        if (getCommunityTokenResponse.outcome) {
            window.location.href = getCommunityTokenResponse.payload.redirectUrl;

            return true;
        } else {
            return getCommunityTokenResponse.error;
        }
    } catch (error) {
        return error.message;
    }
};
