import kabar from "../images/addons/kabarEmergencyWhistle.jpg";
import sunStream from "../images/addons/sunStream.jpg";
import superMicroSharpener from "../images/addons/superMicroSharpener.jpg";
import waterSupply from "../images/addons/waterSupply.jpg";
import foodSupply from "../images/addons/foodSupply.jpg";

export const addOnsImages = [
    { tradename: "Emergency Water Supply", image: waterSupply },
    { tradename: "7 Days Emergency Food Supply", image: foodSupply },
    { tradename: "SunStream PRO COMBO PACK (Blue)", image: sunStream },
    { tradename: "Super Micro-X Sharpener - 4 Piece Combo Set", image: superMicroSharpener },
    { tradename: "KA-BAR Emergency Whistle 2 Pack", image: kabar },
];
