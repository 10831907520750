import React from 'react';
import { Label, Dropdown, Icon } from 'semantic-ui-react';
import '../../scss/components/_dropdown.scss';

export function CustomDropdown(props) {
	const { id, label, options, placeholder, onChangeAction, value, error, search, reference, customClass } = props;

	let dropdownLabel = null;

	if (value && value.length > 0 && label) {
		dropdownLabel = <Label floating>{label}</Label>;
	}

	let iconDisabled = true;

	if (value !== '') {
		iconDisabled = false;
	}

	const onChange = (event, { value }) => {
		const data = {
			id: id,
			value: value,
			text: event.nativeEvent.path[0].innerText
		};
		onChangeAction(data);
	};

	return (
		<div className={`dropdown-container ${customClass}`}>
			{dropdownLabel}
			<Dropdown
				ref={reference}
				id={id}
				search={search}
				placeholder={placeholder}
				fluid
				selection
				options={options}
				onChange={onChange}
				value={value}
				icon={<Icon disabled={iconDisabled} name="chevron down" />}
				error={error}
			/>
		</div>
	);
}
