import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import { Loader } from "../../components/Loader";
import { setupAccountInitialRoute } from "../../services/SetupService";
import { saveSurvivalType, resetSubscriptionStore, saveStepFour } from "../../store/subscriptions/actions";

function Subscribe(props) {
    const { history, subscriptions, saveSurvivalType, resetSubscriptionStore, saveStepFour, plans, shipping } = props;
    const [showLoading, setShowLoading] = useState(true);

    useEffect(() => {
        const searchParams = window.location.search.replace("?", "").split("&");

        if (plans.length > 0) {
            setShowLoading(false);
            setupAccountInitialRoute(
                history,
                searchParams,
                subscriptions,
                saveSurvivalType,
                resetSubscriptionStore,
                saveStepFour,
                plans,
                shipping
            );
        }
    }, [history, subscriptions, saveSurvivalType, resetSubscriptionStore, saveStepFour, plans, shipping]);

    return showLoading && <Loader />;
}

const mapDispatchToProps = (dispatch) => ({
    saveSurvivalType: (giftType, upgradeType) => dispatch(saveSurvivalType(giftType, upgradeType)),
    resetSubscriptionStore: () => dispatch(resetSubscriptionStore()),
    saveStepFour: (stepFour) => dispatch(saveStepFour(stepFour)),
});

const mapStateToProps = (state) => {
    return {
        subscriptions: state.subscriptions,
        plans: state.business.plans,
        shipping: state.business.shipping,
        user: state.user,
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(withRouter(Subscribe));
