import React from "react";
import { Card } from "semantic-ui-react";
import { SubHeader, DefaultButton, BoxCard } from "../..";

function StepOneComponent(props) {
    const { selectedSubscriptionBox, subscriptionBoxOptions, onChangeSelectedBox, saveStepOneAndNext } = props;

    return (
        <section className="padding-page">
            <SubHeader mainTitle="Choose between our two products!" hasDivider={true} />
            <br />
            <br />
            <br />
            <div className="cards-container">
                <Card.Group textAlign="center" centered>
                    {subscriptionBoxOptions.map((box, index) => {
                        const selectedPlan = box.survivalType === selectedSubscriptionBox;
                        return (
                            <BoxCard
                                key={index}
                                plan={box}
                                onSelection={onChangeSelectedBox}
                                selectedPlan={selectedPlan}
                            />
                        );
                    })}
                </Card.Group>
            </div>
            <br />
            <br />
            <br />
            <div className="margin-top padding-bottom flex-center flex-column button-container-padding">
                <DefaultButton
                    title="Next Step"
                    disabled={selectedSubscriptionBox === ""}
                    buttonAction={saveStepOneAndNext}
                />
            </div>
        </section>
    );
}

export default StepOneComponent;
