import React from "react";
import { Card, Image } from "semantic-ui-react";
import "../../scss/components/_cards.scss";
import { SURVIVAL_BOXES } from "../../services/SubscriptionPlanService";

export function UserSubscriptionCard(props) {
    const { subscription } = props;
    const survivalType = subscription.prices.find((price) => price.price.metadata.subscription).price.metadata
        .survivalType;
    console.log(survivalType);

    const boxImage = SURVIVAL_BOXES.find((box) => box.survivalType === survivalType).image;

    return (
        <Card>
            <Card.Content>
                <Image floated="left" size="mini" style={{ width: 80 }} src={boxImage} />
                <Card.Header> {subscription.description}</Card.Header>
                <Card.Description>
                    {`Status: ${subscription.status} | To: ${subscription.shippingAddress.firstName}
                                            ${subscription.shippingAddress.lastName}`}
                </Card.Description>
            </Card.Content>
        </Card>
    );
}
