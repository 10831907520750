import React from "react";
import { Card, Icon, List } from "semantic-ui-react";
import * as CONSTANTS from "../../constants/Global";
import "../../scss/components/_cards.scss";

export function SettingCard(props) {
    const { hasIcon, title, items, routerAction, type, showBenefits } = props;

    const itemKeys = Object.keys(items);
    const itemValues = Object.values(items);

    let chevronIcon = null;

    if (hasIcon) {
        chevronIcon = <Icon name="chevron right" size="large" />;
    }

    let overlay = null;

    switch (type) {
        case "overlay":
            overlay = <div className="card-overlay" onClick={routerAction ? () => routerAction(items) : null} />;
            break;
        default:
            break;
    }

    let benefitsCard = null;
    let cardClasses = "settings-card";

    if (showBenefits) {
        cardClasses += " cards-together";

        benefitsCard = (
            <Card className="settings-card benefits" fluid>
                <Card.Header>Information</Card.Header>
                <List bulleted>
                    {CONSTANTS.IMPORTANT_INFORMATION_TEXT.map((text, index) => {
                        switch (index) {
                            case 0:
                                return (
                                    <List.Item key={index}>
                                        <strong className="color-red">{text}</strong>
                                    </List.Item>
                                );
                            case 1:
                                return (
                                    <List.Item key={index}>
                                        <strong className="color-cta">{text}</strong>
                                    </List.Item>
                                );

                            default:
                                return <List.Item key={index}>{text}</List.Item>;
                        }
                    })}
                </List>
                {/* <Card.Header>Remember your subscription benefits</Card.Header>
                <List bulleted>
                    {CONSTANTS.BENEFITS_TEXT.map((text, index) => {
                        return <List.Item key={index}>{text}</List.Item>;
                    })}
                </List> */}
            </Card>
        );
    }

    return (
        <>
            <Card className={cardClasses} fluid>
                {overlay}
                <Card.Content className="header-container">
                    <Card.Header>{title}</Card.Header>
                    {chevronIcon}
                </Card.Content>
                <Card.Content>
                    {itemKeys.map((item, index) => {
                        const itemSplited = item.split("_");
                        const itemName = itemSplited[0];
                        const itemClass = itemSplited[1];
                        let setting = null;
                        let dataClassName = "info-data";

                        if (itemClass === "link") {
                            dataClassName += " link";
                        }

                        switch (itemName) {
                            case "anniversary":
                                break;
                            case "trackingLink":
                                break;
                            case "Track your Box":
                                if (itemValues[index] !== "0" && itemValues[3] === "SHIPPED") {
                                    setting = (
                                        <div key={index} className="info-container">
                                            <p className="info-title">{itemName}:</p>
                                            <p className={dataClassName}>
                                                <a href={itemValues[2]} target="_blank" rel="noopener noreferrer">
                                                    {itemValues[index]}
                                                </a>
                                            </p>
                                        </div>
                                    );
                                }
                                break;
                            default:
                                setting = (
                                    <div key={index} className="info-container">
                                        <p className="info-title">{itemName}:</p>
                                        <p className={dataClassName}>{itemValues[index]}</p>
                                    </div>
                                );
                                break;
                        }

                        return setting;
                    })}
                </Card.Content>
            </Card>
            {benefitsCard}
        </>
    );
}
