import React from "react";
import { Image } from "semantic-ui-react";
import { SubHeader, Messages } from "..";
import { Loader } from "../../components";
import SuccessImage from "../../images/survivalImages/success.svg";
import ErrorImage from "../../images/survivalImages/error.svg";

function ValidateEmailComponent(props) {
    const { responseMessage, loadingEmailValidation, validationSuccess } = props;

    let message = null;
    let responseComponent = null;

    if (loadingEmailValidation) {
        responseComponent = <Loader />;
    } else {
        if (responseMessage !== "") {
            if (validationSuccess) {
                responseComponent = <Image centered src={SuccessImage} size="small" />;
                message = (
                    <p className="text-center">
                        <strong>Please close this window and come back to signup page to continue.</strong>
                    </p>
                );
            } else {
                responseComponent = <Image centered src={ErrorImage} size="small" />;
                message = <Messages message={responseMessage} />;
            }
        }
    }

    return (
        <section className="padding-page">
            <div className="input-container container-padding">
                <SubHeader
                    mainTitle="Email validation"
                    specialText="Welcome to Survivalbox email validation system"
                    subtitle={validationSuccess ? "" : "Please wait while we validate your email."}
                />

                {responseComponent}
            </div>
            <div className="padding-bottom flex-center flex-column container-padding"></div>
            {message}
        </section>
    );
}

export default ValidateEmailComponent;
