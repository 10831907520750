export const SIGN_UP_ERROR = "An error has occurred during your login, please try again.";
export const POST_SUBSCRIPTION_ERROR = "An error has occurred during your subscription process, please try again.";
export const ERROR_INITIALIZING_PAGE =
    "An error has occurred initializing the page, please check your internet connection and reload the page.";
export const ERROR_UPLOADIND_PHOTOS = "An error has occurred uploading your photos, please try again.";
export const ERROR_UPLOADIND_PARENTS = "An error has occurred uploading dietary restrictions, please try again.";
export const ERROR_COUPON_NOT_FOUND =
    "The coupon entered is incorrect, does not exist or not correspond with the selected subscription.";
export const ERROR_VALIDATING_CODE = "An error has occurred validating your code, please try again.";
export const ERROR_CANCELLING_SUBSCRIPTION = "An error has occurred cancelling your subscription, please try again.";
export const ERROR_COUPON_DIFFERENT_SCOPE =
    "Your coupon is not correspond to selected Subscription, to validate your coupon please select.";
export const ERROR_COUPON_USED_PREVIOUSLY =
    "Your coupon already was used for this subscriber, please use another coupon.";
export const ERROR_COUPON_INVALID = "Your coupon is invalid, maybe was expired, please use another coupon.";
export const ERROR_SHIPPED_BOX_FEATURES = "Your box has been shipped, you can't add this feature.";
export const PAGE_NOT_FOUND =
    "Apparently this page took the day off. You are still looking for the perfect detail for your beloved grandparents.";
export const ERROR_RESET_PASSWORD_EXPIRED = "Your link has expired, please init the reset password process again.";
export const ERROR_RESET_PASSWORD_DEFAULT = "An error has occurred resetting your password, please try again.";
export const ERROR_EMAIL_VALIDATION_EXPIRED = "Your link has expired, please init the email validation process again.";
export const ERROR_EMAIL_VALIDATION_DEFAULT = "An error has occurred validating your email, please try again.";
export const ERROR_UPDATING_SHIPPING_ADDRESS =
    "An error has occurred updating your shipping address, please try again.";
export const ERROR_LOADING_USER_SUBSCRIPTIONS = "An error has occurred loading your subscriptions, please try again.";
export const ERROR_NOT_USER_SUBSCRIPTIONS = "Your account don't have any Subscription yet";
export const ERROR_NOT_PAYMENT_METHOD = "An error has occurred loading your payment method, please try again.";
export const ERROR_SESSION_EXPIRED = "Your session has expired, please sign in again.";
export const ERROR_SUBSCRIPTION_IS_NOT_PREMIUN =
    "In order to access our community, at least one of your subscriptions must be premium and be active.";
export const ERROR_PAUSING_SUBSCRIPTION = "An error has occurred pausing your subscription, please try again.";
export const ERROR_LOADING_TRIBE_COMMUNITY = "An error has occurred trying to acceding to Community, please try again.";
