import axios from "axios";
import moment from "moment";
import { create } from "apisauce";
import * as CONFIG from "./ConfigService";
import { getIdToken, getCurrentUser, firebaseSignOut } from "./AuthService";
import jwtDecode from "jwt-decode";

const API_URL = CONFIG.getApiUrl();

let apisauceInstance = null;

const customAxiosInstance = axios.create({
    baseURL: API_URL,
    headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
    },
    timeout: 60000,
    withCredentials: false,
});

const getConfig = async () => {
    const NEW_TOKEN_ID = await getIdToken();
    const STORED_TOKEN_ID = await getCurrentUser();
    let TOKEN_ID = null;

    if (NEW_TOKEN_ID) {
        TOKEN_ID = NEW_TOKEN_ID;
    } else {
        TOKEN_ID = STORED_TOKEN_ID;
        const decoded = jwtDecode(TOKEN_ID);
        const tokenExpired = moment().isAfter(moment(decoded.exp * 1000));

        if (tokenExpired) {
            firebaseSignOut(true);
        }
    }

    if (TOKEN_ID) {
        customAxiosInstance.defaults.headers.common["Authorization"] = `Bearer ${TOKEN_ID}`;
        apisauceInstance = create({ axiosInstance: customAxiosInstance });

        return true;
    }
};

export const graphqlServiceAuthSauce = async (query, variables) => {
    const instanceReady = await getConfig();

    if (instanceReady) {
        try {
            const httpResquest = await apisauceInstance.post("/graphql", {
                query: query,
                variables: variables,
            });
            return httpResquest;
        } catch (error) {
            return error;
        }
    }
};

export const graphqlServiceSauce = async (query, variables) => {
    apisauceInstance = create({ axiosInstance: customAxiosInstance });

    try {
        const httpResquest = await apisauceInstance.post("/graphql", {
            query: query,
            variables: variables,
        });
        return httpResquest;
    } catch (error) {
        return error;
    }
};

export const cloudfunctionsServiceSauce = async (url) => {
    const NEW_TOKEN_ID = await getIdToken();
    const STORED_TOKEN_ID = await getCurrentUser();
    let TOKEN_ID = null;

    if (NEW_TOKEN_ID) {
        TOKEN_ID = NEW_TOKEN_ID;
    } else {
        TOKEN_ID = STORED_TOKEN_ID;
        const decoded = jwtDecode(TOKEN_ID);
        const tokenExpired = moment().isAfter(moment(decoded.exp * 1000));

        if (tokenExpired) {
            firebaseSignOut(true);
        }
    }

    if (TOKEN_ID) {
        try {
            const headers = {
                "Content-Type": "application/json",
                Authorization: `Bearer ${TOKEN_ID}`,
            };

            const httpResquest = await axios.get(`${process.env.REACT_APP_API_URL}${url}`, { headers: headers });

            if (httpResquest.data.success) {
                return {
                    outcome: true,
                    payload: httpResquest.data.payload,
                };
            } else {
                return {
                    outcome: false,
                    error: httpResquest.error,
                };
            }
        } catch (error) {
            return {
                outcome: false,
                error: error.message,
            };
        }
    }
};
