import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import { Container } from "semantic-ui-react";
import StepFourComponent from "../../components/screenComponents/subscribe/StepFourComponent";
import { saveStepOne, saveStepThree, saveStepFour } from "../../store/subscriptions/actions";
import * as ROUTES from "../../navigation/Routes";
import "../../scss/pages/get_a_box.scss";

function StepFour(props) {
    const { subscriptions, history, business, saveStepThree, user } = props;
    const { addOnPrices } = business;
    const { subscriptionPlan, selectedAddOns } = subscriptions;
    const [showMoreInfo, setShowMoreInfo] = useState(false);

    useEffect(() => {
        if (user.userData) {
            if (user.userData.firebase.sign_in_provider === "password") {
                if (user.userData.email_verified) {
                    history.push(ROUTES.STEP_FIVE);
                } else {
                    history.push(ROUTES.SIGN_UP, {
                        accountRoutes: "subscription",
                    });
                }
            } else {
                history.push(ROUTES.STEP_FIVE);
            }
        } else {
            history.push(ROUTES.SIGN_UP, {
                accountRoutes: "subscription",
            });
        }
    }, [user.userData, history]);

    const handleMoreInfo = () => {
        setShowMoreInfo(!showMoreInfo);
    };

    const goToStepTwo = () => {
        history.replace(ROUTES.STEP_TWO, {
            selectPremium: true,
        });
    };

    const saveStepFourAndNext = () => {
        const survivalGardenTradename = "Survival Garden";
        const survivalGarden = addOnPrices.find((price) => price.metadata.tradename === survivalGardenTradename);
        const survivalGardenPrice = {
            ...survivalGarden,
            autoRenewing: true,
            quantity: 1,
        };

        const survivalGardenSelected = selectedAddOns.some(
            (price) => price.metadata.tradename === survivalGardenTradename
        );

        if (!survivalGardenSelected) {
            const newSelectedAddons = [...selectedAddOns, survivalGardenPrice];

            saveStepThree({
                selectedAddOns: newSelectedAddons,
            });
        }

        routeNextPage();
    };

    const routeNextPage = () => {
        if (user.userData) {
            if (user.userData.firebase.sign_in_provider === "password") {
                if (user.userData.email_verified) {
                    history.push(ROUTES.STEP_FIVE);
                } else {
                    history.push(ROUTES.SIGN_UP, {
                        accountRoutes: "subscription",
                    });
                }
            } else {
                history.push(ROUTES.STEP_FIVE);
            }
        } else {
            history.push(ROUTES.SIGN_UP, {
                accountRoutes: "subscription",
            });
        }
    };

    return (
        <Container>
            <StepFourComponent
                routeNextPage={routeNextPage}
                saveStepFourAndNext={saveStepFourAndNext}
                subscriptionPlan={subscriptionPlan}
                handleMoreInfo={handleMoreInfo}
                showMoreInfo={showMoreInfo}
                goToStepTwo={goToStepTwo}
            />
        </Container>
    );
}

const mapDispatchToProps = (dispatch) => ({
    saveStepOne: (stepOne) => dispatch(saveStepOne(stepOne)),
    saveStepThree: (stepThree) => dispatch(saveStepThree(stepThree)),
    saveStepFour: (stepFour) => dispatch(saveStepFour(stepFour)),
});

const mapStateToProps = (state) => {
    return {
        business: state.business,
        subscriptions: state.subscriptions,
        user: state.user,
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(withRouter(StepFour));
