import React, { useState, useReducer } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import { Container } from "semantic-ui-react";
import { ErrorModal } from "../../components";
import ConfirmCancelationComponent from "../../components/screenComponents/account/ConfirmCancelationComponent";
import { cancelSubscription } from "../../services/SetupService";
import { updateSubscriptionsStore } from "../../services/UpdateReduxStoreService";
import { saveUserSubscriptions } from "../../store/user/actions";
import * as ERRORS from "../../constants/Errors";
import * as CONSTANTS from "../../constants/Global";
import * as ROUTES from "../../navigation/Routes";
import "../../scss/pages/account.scss";

function ConfirmCancelation(props) {
    const { history, location, user, saveUserSubscriptions } = props;
    const { cancelData, subscriptions, userData } = user;
    const subscription = location.state;
    const [selectedPause, setSelectedPause] = useState("");
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [loadingState, setLoadingState] = useReducer((state, newState) => ({ ...state, ...newState }), {
        loadingPause: false,
        loadingDiscount: false,
        loadingCancelation: false,
        disableButtons: false,
    });
    const [unSubscribeNewsletter, setUnSubscribeNewsletter] = useState(false);

    const onChangePause = (event) => {
        const value = event.target.id;

        setSelectedPause(Number(value));
    };

    const processCancelation = async (cancelData) => {
        setLoadings(cancelData.input.treatment);
        setLoadingState({
            disableButtons: true,
        });

        const cancelSubscriptionResponse = await cancelSubscription(
            cancelData,
            unSubscribeNewsletter && userData.email
        );

        if (!cancelSubscriptionResponse.hasOwnProperty("message")) {
            setLoadingState({
                loadingPause: false,
                loadingDiscount: false,
                loadingCancelation: false,
                disableButtons: false,
            });

            const subscriptionUpdated = updateSubscriptionsStore(
                saveUserSubscriptions,
                subscriptions,
                cancelSubscriptionResponse
            );

            if (subscriptionUpdated) {
                history.replace(ROUTES.SUBSCRIPTION_SUMMARY);
            }
        } else {
            if (cancelSubscriptionResponse.hasOwnProperty("message")) {
                setShowErrorModal(cancelSubscriptionResponse.message);
            } else {
                setShowErrorModal(true);
            }

            setLoadingState({
                loadingPause: false,
                loadingDiscount: false,
                loadingCancelation: false,
                disableButtons: false,
            });
        }
    };

    const setLoadings = (treatment) => {
        if (treatment === CONSTANTS.CANCEL_SUBSCRIPTIONS_OPTIONS.PAUSE) {
            setLoadingState({
                loadingPause: true,
            });
        } else if (treatment === CONSTANTS.CANCEL_SUBSCRIPTIONS_OPTIONS.DISCOUNT) {
            setLoadingState({
                loadingDiscount: true,
            });
        } else {
            setLoadingState({
                loadingCancelation: true,
            });
        }
    };

    const closeErrorModal = () => {
        setShowErrorModal(false);
    };

    let errorModal = null;

    if (showErrorModal) {
        let openModal = showErrorModal;
        let message = ERRORS.ERROR_CANCELLING_SUBSCRIPTION;
        if (typeof showErrorModal === "string") {
            message = showErrorModal;
            openModal = true;
        }

        errorModal = <ErrorModal open={openModal} message={message} closeFunction={closeErrorModal} />;
    }
    return (
        <>
            {errorModal}

            <Container>
                <ConfirmCancelationComponent
                    history={history}
                    subscription={subscription}
                    onChangePause={onChangePause}
                    selectedPause={selectedPause}
                    cancelData={cancelData}
                    processCancelation={processCancelation}
                    loadingState={loadingState}
                    setUnSubscribeNewsletter={setUnSubscribeNewsletter}
                    unSubscribeNewsletter={unSubscribeNewsletter}
                />
            </Container>
        </>
    );
}

const mapDispatchToProps = (dispatch) => ({
    saveUserSubscriptions: (userSubscriptions) => dispatch(saveUserSubscriptions(userSubscriptions)),
});

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(withRouter(ConfirmCancelation));
