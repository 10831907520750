import React from "react";
import { Input, Button } from "semantic-ui-react";
import "../../scss/components/_input.scss";

export function CustomInputButtom(props) {
    const { id, placeholder, onChangeAction, value, onClickAction, loading, disabled, removeCode } = props;

    return (
        <div className="input-button-content">
            <div className="input-section">
                <Input id={id} placeholder={placeholder} onChange={onChangeAction} value={value} disabled={disabled} />
                <Button icon="refresh" onClick={onClickAction} loading={loading} disabled={disabled} />
            </div>
            {disabled && (
                <div className="text-center" style={{ padding: 5 }}>
                    <Button basic content="Remove Code" onClick={removeCode} />
                </div>
            )}
        </div>
    );
}
