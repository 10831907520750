import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import TagManager from "react-gtm-module";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import configureStore from "./store/configureStore";
import * as CONSTANTS from "./constants/Global";
import "./scss/pages/index.scss";
import "semantic-ui-css/semantic.min.css";

import { TrackJS } from "trackjs";

const initialState = window.initialReduxState;
const store = configureStore(initialState);
const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
    dataLayer: "pageview",
};

TrackJS.install({
    token: "336d9971fae34cacaf529d7ba618ce67",
    application: "survivalbox-subscription",
    // for more configuration options, see https://docs.trackjs.com
});

process.env.NODE_ENV === CONSTANTS.ENV_PRODUCTION && TagManager.initialize(tagManagerArgs);
process.env.NODE_ENV === CONSTANTS.ENV_PRODUCTION && TrackJS.track("Message");

ReactDOM.render(
    <Provider store={store}>
        <Router>
            <App />
        </Router>
    </Provider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
