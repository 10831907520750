import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FreshChat from "react-freshchat";
import { DefaultButton, ResponsiveImage } from "../components";
import SurvivalBoxesLogo from "../images/survivalbox-logo.png";
import { firebaseSignOut } from "../services/AuthService";
import { getLocalStorageItem } from "../services/LocalStorageService";
import * as ROUTES from "../navigation/Routes";
import * as LOCALSTORAGE from "../constants/LocalStorage";
import "../scss/components/_sticky_top.scss";

export function Header(props) {
    const { history, openChat } = props;
    const user = useSelector((state) => state.user.userData);
    const [showChat, setShowChat] = useState(false);
    const [chatText, setChatText] = useState(false);
    const [chatInitialized, setChatInitialized] = useState(false);
    const pathname = window.location.pathname;
    var stepNumber = Number(pathname.split("-")[1]);

    useEffect(() => {
        //const pathname = window.location.pathname;
        // const stepNumber = Number(pathname.split("-")[1]);
        // const isSubscriptionFlow = pathname.includes(ROUTES.SUBSCRIBE);
        const subscriptions = getLocalStorageItem(LOCALSTORAGE.SUBSCRIPTION_DATA);

        // const goToStartRoute = () => {
        //     const lastStepCompleted = subscriptions.step;
        //     const requiredStep = lastStepCompleted + 1;

        //     // if (isSubscriptionFlow) {
        //     //     if (subscriptions && subscriptions.step) {
        //     //         if (pathname !== ROUTES.SUCCESSFUL) {
        //     //             if (stepNumber >= requiredStep) {
        //     //                 //history.push(`${ROUTES.SUBSCRIBE}/step-${requiredStep}`);
        //     //             } else {
        //     //                 // history.push(`${ROUTES.SUBSCRIBE}/step-${stepNumber}`);
        //     //             }
        //     //         }
        //     //     } else {
        //     //         if (pathname !== ROUTES.SUCCESSFUL) {
        //     //             if (pathname !== ROUTES.STEP_ONE) {
        //     //                 history.replace(ROUTES.STEP_ONE);
        //     //             }
        //     //         }
        //     //     }
        //     // }
        // };

        if (subscriptions) {
            // goToStartRoute();
        }
    }, [history]);

    useEffect(() => {
        const openChatAction = () => {
            setShowChat(true);

            if (chatInitialized) {
                window.fcWidget.open();
            } else {
                setChatText(true);

                setTimeout(() => {
                    setChatText(false);
                }, 3000);
            }
        };

        if (openChat) {
            openChatAction();
        }
    }, [openChat, chatInitialized]);

    const openChatAction = () => {
        setShowChat(true);

        if (chatInitialized) {
            window.fcWidget.open();
        } else {
            setChatText(true);

            setTimeout(() => {
                setChatText(false);
            }, 3000);
        }
    };

    let chat = null;

    if (showChat) {
        chat = (
            <FreshChat
                token={process.env.REACT_APP_FRESHCHAT_TOKEN}
                onInit={(widget) => {
                    setChatInitialized(true);
                    widget.open();

                    if (user) {
                        const userName = user.name.split(" ");

                        const properties = {
                            email: user.email,
                            first_name: userName[0],
                            last_name: userName[1],
                        };

                        if (openChat) {
                            properties.requirement = "Cancel Cratejoy Subscription";
                            properties.subscriptionId = openChat;
                        }
                        widget.user.setProperties(properties);
                    }
                }}
            />
        );
    }

    const signOut = async () => {
        await firebaseSignOut();
    };
        
    if(window.location.pathname === "/account/sign-up")  stepNumber = 4;      
    const stepInformation = <p className="step-information">Step {stepNumber} of 5</p>;

    let promoSection = (
        <section className="bg-green-dark mini-top-banner flex-center">
            <h1 className="color-white text-center"> </h1>
        </section>
    );

    const hidePromo = ROUTES.HIDE_PROMO_ROUTES.includes(pathname);

    if (hidePromo) {
        promoSection = null;
    }

    let logoutButton = null;

    if (![ROUTES.EMAIL_VALIDATION, ROUTES.RESET_PASSWORD].includes(pathname)) {
        if (user) {
            logoutButton = <DefaultButton title="Log Out" orange={true} buttonAction={signOut} />;
        }
    }

    return (
        <header className="sticky-top">
            <section className="bg-white">
                <div className="align-center navbar">
                    <div className="center-logo padding logo">
                        <a href={ROUTES.SURVIVALBOXES_URLS.HOME}>
                            <ResponsiveImage
                                width="85px"
                                altTag="Survivalbox subscription box logo"
                                smallImg={SurvivalBoxesLogo}
                                largeImg={SurvivalBoxesLogo}
                            />
                        </a>
                    </div>
                    <div className="route-container">
                        {!isNaN(stepNumber) && stepInformation}
                        <div className="text-center">
                            <button className="help-chat support color-green hide-mobile" onClick={openChatAction}>
                                {chatText ? "Connecting..." : "Need help?"}
                            </button>
                        </div>
                    </div>
                    <div className="padding log-out">{logoutButton}</div>
                </div>
                {promoSection}
                {chat}
            </section>
        </header>
    );
}
