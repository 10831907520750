import React from "react";
import { Divider, Header, Button } from "semantic-ui-react";
import "../scss/components/_subheader.scss";

export function BoxSubHeader(props) {
    const { boxName, boxSection, singleTitle, history } = props;

    let headerH2 = null;

    if (boxName) {
        headerH2 = (
            <Header as="h2" textAlign="left" className="box-title box-title-customized">
                {`${boxName}'s ${boxSection}`}
            </Header>
        );
    }

    if (singleTitle) {
        headerH2 = (
            <Header as="h2" textAlign="left" className="box-title box-title-customized">
                {singleTitle}
            </Header>
        );
    }

    const goToBack = () => {
        history.goBack();
    };

    const backButton = <Button className="back-close" size="big" icon="close" onClick={goToBack}></Button>;

    return (
        <div className="box-sub-header-container container-padding">
            <div className="sub-header">
                {headerH2}
                {backButton}
            </div>
            <Divider />
        </div>
    );
}
