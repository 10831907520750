import Account from "../../screens/account/Account";
import ManageSubscription from "../../screens/account/ManageSubscription";
import BoxDescription from "../../screens/account/BoxDescription";
import ShipingAddress from "../../screens/account/ShippingAddress";
import UserInformation from "../../screens/account/UserInformation";
import UpdatePaymentMethod from "../../screens/account/UpdatePaymentMethod";

import * as ROUTES from "../Routes";

export const AccountStack = [
    {
        path: ROUTES.ACCOUNT,
        component: Account,
    },
    {
        path: ROUTES.SUBSCRIPTION_SUMMARY,
        component: ManageSubscription,
    },
    {
        path: ROUTES.BOX_DESCRIPTION,
        component: BoxDescription,
    },
    {
        path: ROUTES.SHIPPING_ADDRESS,
        component: ShipingAddress,
    },
    {
        path: ROUTES.USER_INFORMATION,
        component: UserInformation,
    },
    {
        path: ROUTES.UPDATE_PAYMENT_METHOD,
        component: UpdatePaymentMethod,
    },
];
