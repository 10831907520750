import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripeForm from "./StripeForm";

function StepFiveComponent(props) {
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

    return (
        <Elements
            stripe={stripePromise}
            options={{
                locale: "en",
                cssSrc: "https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap",
            }}
        >
            <StripeForm {...props} />
        </Elements>
    );
}

export default StepFiveComponent;
