import React from "react";
import { Divider, Header, Image, Button, Icon } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import * as ROUTES from "../navigation/Routes";
import "../scss/components/_subheader.scss";

export function SubHeader(props) {
    const { mainTitle, specialText, subtitle, hasDivider, image, shortPadding, showBackButton, isBoxTitle } = props;
    const user = useSelector((state) => state.user);
    const history = useHistory();
    const pathName = history.location.pathname;
    const areAccountRoutes = pathName.includes(ROUTES.ACCOUNT);
    const isSummaryRoute = pathName.includes(ROUTES.SUBSCRIPTION_SUMMARY);

    const goToUserInformation = () => {
        history.push(ROUTES.USER_INFORMATION);
    };

    let headerH1 = null;
    let headerH4 = null;
    let headerP = null;
    let divider = null;
    let imageSection = null;
    let giftNameTitle = null;

    if (mainTitle) {
        headerH1 = (
            <Header as="h1" textAlign="center" className="suscribe-title text-center">
                {mainTitle}
            </Header>
        );
    }

    if (specialText) {
        let className = "suscribe-text text-center";
        if (isBoxTitle) {
            className += " box-title";
        }

        headerP = <p className={className}>{specialText}</p>;
    }

    if (subtitle) {
        headerH4 = (
            <Header as="h4" textAlign="left" className="subtitle">
                {subtitle}
            </Header>
        );
    }

    if (hasDivider) {
        divider = <Divider />;
    }

    if (image) {
        imageSection = <Image src={image} centered size="small" />;
    }

    let className = "sub-header-container container-padding";

    if (areAccountRoutes) {
        className = "sub-header-container container-padding-small";
    }

    if (shortPadding) {
        className += " short-padding";
    }

    let backButton = null;

    if (showBackButton) {
        let historyBack = () => history.goBack();

        if (pathName.includes("box-description")) {
            historyBack = () => history.replace(ROUTES.SUBSCRIPTION_SUMMARY, false);
        }

        backButton = <Button className="back-arrow back-arrow-customized"  size="big" icon="arrow left" onClick={historyBack}></Button>;
    }

    let userInfo = null;

    if (user.userData && isSummaryRoute) {
        className = "sub-header-container container-padding-small sub-header-padding-small";

        userInfo = (
            <div className="user-info">
                <Button icon labelPosition="left" onClick={goToUserInformation}>
                    <Icon name="settings" size="large" />
                    Hello, {user && user.userData.name}
                </Button>
            </div>
        );
    }

    return (
        <div className={className}>
            <div className="sub-header">
                {userInfo}

                {giftNameTitle}
                {headerH1}
                {backButton}

                {imageSection}
                {headerP}
            </div>
            {divider}
            {headerH4}
        </div>
    );
}
