import app from "firebase/app";
import "firebase/auth";
import "firebase/storage";
import moment from "moment";
import { setCookie } from "./CookieService";
import { removeLocalStorageItem, setLocalStorageItem } from "../services/LocalStorageService";
import * as LOCALSTORAGE from "../constants/LocalStorage";
import * as CONSTANTS from "../constants/Global";

const devConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKED,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

class FirebaseService {
    constructor() {
        app.initializeApp(devConfig);
        this.auth = app.auth();
        this.storage = app.storage();
    }

    getCurrentUser = async () => {
        this.auth.onAuthStateChanged((user) => {
            if(user != null){
                user.getIdToken()
                .then((data) => {
                    process.env.NODE_ENV === CONSTANTS.ENV_DEVELOPMENT && console.log(data);
                    setCookie(LOCALSTORAGE.USER_DATA, data);
                })
                .catch((error) => {
                    console.log(error.message);
                });
            }      
        });
    };

    doSendEmailVerification = async () => {
        this.auth.onAuthStateChanged((user) => {
            if (user) {
                user.sendEmailVerification();
            }
        });
    };

    doSignOut = async () => {
        return new Promise(async (resolve, reject) => {
            this.auth
                .signOut()
                .then(function () {
                    resolve("successfull");
                    removeLocalStorageItem(LOCALSTORAGE.OWNERID);
                })
                .catch(function (error) {
                    reject(error.message);
                });
        });
    };

    doCreateUserWithEmailAndPassword = async (email, password, fullName) => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.auth.createUserWithEmailAndPassword(email, password);

                const user = this.auth.currentUser;
                await user.updateProfile({
                    displayName: fullName,
                });

                if (response) {
                    setLocalStorageItem(LOCALSTORAGE.SIGN_IN_TIME, Number(moment().format("x")));
                    this.getCurrentUser();
                }
                return resolve(response);
            } catch (error) {
                return reject(error);
            }
        });
    };

    doSignInWithEmailAndPassword = async (email, password) => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.auth.signInWithEmailAndPassword(email, password);
                if (response) {
                    setLocalStorageItem(LOCALSTORAGE.SIGN_IN_TIME, Number(moment().format("x")));
                    this.getCurrentUser();
                }
                return resolve(response);
            } catch (error) {
                return reject(error);
            }
        });
    };

    doFacebookSignInWithPopup = async () => {
        return new Promise(async (resolve, reject) => {
            const provider = new app.auth.FacebookAuthProvider();

            try {
                const response = await this.auth.signInWithPopup(provider);
                if (response) {
                    setLocalStorageItem(LOCALSTORAGE.SIGN_IN_TIME, Number(moment().format("x")));
                    this.getCurrentUser();
                }
                return resolve(response);
            } catch (error) {
                return reject(error);
            }
        });
    };

    doGoogleSignIn = async () => {
        return new Promise(async (resolve, reject) => {
            const provider = new app.auth.GoogleAuthProvider();

            try {
                const response = await this.auth.signInWithPopup(provider);
                if (response) {
                    setLocalStorageItem(LOCALSTORAGE.SIGN_IN_TIME, Number(moment().format("x")));
                    this.getCurrentUser();
                }

                return resolve(response);
            } catch (error) {
                return reject(error);
            }
        });
    };

    doSignInWithCustomToken = async (token) => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.auth.signInWithCustomToken(token);
                if (response) {
                    setLocalStorageItem(LOCALSTORAGE.SIGN_IN_TIME, Number(moment().format("x")));
                    this.getCurrentUser();
                }
                return resolve(response);
            } catch (error) {
                return reject(error);
            }
        });
    };

    getIdToken = async () => {
        const currentUser = this.auth.currentUser;
        let idToken = null;
        if (currentUser) {
            idToken = await currentUser.getIdToken(true);
            process.env.NODE_ENV === CONSTANTS.ENV_DEVELOPMENT && console.log("1", idToken);
            setCookie(LOCALSTORAGE.USER_DATA, idToken);

            return idToken;
        } else {
            return null;
        }
    };

    uploadFiles = async (data) => {
        const storageRef = app.storage().ref();
        const filesSaved = [];
        let errorUploading = null;

        const files = data.files.filter((file) => file.hasOwnProperty("base64"));

        for (const file of files) {
            try {
                const childRef = storageRef.child(`grandbox/${data.ownerId}/${data.boxId}/${file.name}`);
                const uploadTask = await childRef.putString(file.encodeString, "base64", file.metadata);

                if (uploadTask.state === "success") {
                    const url = await childRef.getDownloadURL();
                    filesSaved.push(url);
                }
            } catch (error) {
                errorUploading = error;
            }
        }

        const uploadResponse = {
            filesSaved: filesSaved,
            error: errorUploading,
        };
        return uploadResponse;
    };
}

export default FirebaseService;
