export const loadGoogleScript = () => {
    if (!window.google) {
        const url = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`;
        let script = document.createElement("script");
        script.type = "text/javascript";

        if (script.readyState) {
            script.onreadystatechange = function () {
                if (script.readyState === "loaded" || script.readyState === "complete") {
                    script.onreadystatechange = null;
                    return;
                }
            };
        } else {
            script.onload = () => null;
        }
        script.src = url;
        document.getElementsByTagName("head")[0].appendChild(script);
    }
};

export const waitForGlobal = (name, timeout = 300) => {
    return new Promise((resolve, reject) => {
        let waited = 0;

        function wait(interval) {
            setTimeout(() => {
                waited += interval;
                if (window[name] !== undefined) {
                    return resolve(true);
                }
                if (waited >= timeout * 1000) {
                    return reject({ message: "Timeout" });
                }
                wait(interval * 2);
            }, interval);
        }

        wait(30);
    });
};
