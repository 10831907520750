import React, { useState } from "react";
import { Header, Card, Image, Label } from "semantic-ui-react";
import { ErrorModal } from "../../components";
import { SURVIVAL_BOXES } from "../../services/SubscriptionPlanService";
import { PLAN_TYPES } from "../../constants/Global";
import "../../scss/components/_cards.scss";

export function PlanCard(props) {
    const { plan, selectedPlan, onSelection, selectPremium } = props;
    const { image, unit_amount, metadata } = plan;
    const { survivalType, packageQty, type } = metadata;

    const [showEmailInputModal, setShowEmailInputModal] = useState(false);

    const productName = SURVIVAL_BOXES.find((box) => box.survivalType === survivalType).name;
    const priceInfo = unit_amount / 100;
    const boxPrice = priceInfo / Number(packageQty);
    let cardClasses = "plan-card";
    let suggestLabel = null;
    let planName = "";

    if (selectPremium && metadata.type === PLAN_TYPES.PREMIUM) {
        cardClasses += " suggested-box";
        suggestLabel = (
            <Label className="suggested-label" ribbon>
                Suggested to get AddOns
            </Label>
        );
    }

    if (type === PLAN_TYPES.BASIC) {
        planName = "Basic Plan";
    } else {
        planName = "Premium Plan";
    }

    if (selectedPlan) {
        cardClasses = "plan-card selected-plan";
    }

    const closeSengridModal = () => {
        setShowEmailInputModal(!showEmailInputModal);
    };

    let emailInputModal = showEmailInputModal && (
        <ErrorModal
            open={showEmailInputModal}
            message="We are really sorry, this box has been sold out, you can choose our Premium Box and enjoy it!"
            closeFunction={closeSengridModal}
        />
    );
    return (
        <Card className={cardClasses}>
            {emailInputModal}
            <div
                className="card-overlay"
                // onClick={() => (type === "basic" ? setShowEmailInputModal(true) : onSelection(plan))}
                onClick={() => onSelection(plan)}
            >
                {suggestLabel}
            </div>
            <Card.Content>
                <div className="card-content">
                    <div className="card-image-section">{image && <Image src={image} />}</div>
                    <div className="card-data-section">
                        <Header as="h2" textAlign="center">
                            {planName}
                        </Header>
                        <div className="card-subheader" style={{ minHeight: 60, margin: "0 auto" }}>
                            <Header as="h4" textAlign="center">
                                {productName}
                            </Header>
                        </div>
                        <Card.Description>
                            <span>{`$${boxPrice.toFixed(2)}/month + shipping`}</span>
                        </Card.Description>
                    </div>
                </div>
            </Card.Content>
        </Card>
    );
}
