import React from "react";
import { List, Grid, Header, Icon, Button } from "semantic-ui-react";

export default function UserInformationComponent(props) {
    const {
        userData,
        paymentMethod,
        loadingPaymentMethod,
        goToUpdatePaymentMethod,
        goToResetPassword,
        showAddPaymentMethod,
    } = props;

    let userInformation = null;

    if (userData) {
        const { email, user_id, name, firebase } = userData;

        userInformation = (
            <Grid.Column mobile={16} computer={8} tablet={8}>
                <Header textAlign="center" as="h3" content="User Information" />
                <List celled size="large">
                    <List.Item>
                        <List.Header>Subscriber Name:</List.Header>
                        {name}
                    </List.Item>
                    <List.Item>
                        <List.Header>Email:</List.Header>
                        {email}
                    </List.Item>
                    <List.Item>
                        <List.Header>User Id:</List.Header>
                        {user_id}
                    </List.Item>
                    <List.Item>
                        <List.Header>Password</List.Header>
                        <div className="special-content">
                            {firebase.sign_in_provider === "password" || firebase.sign_in_provider === "custom" ? (
                                <>
                                    **********
                                    <Button onClick={goToResetPassword}>Reset</Button>
                                </>
                            ) : (
                                <p>
                                    If you want to change your password, it must be through your social network provider{" "}
                                    (<strong>{firebase.sign_in_provider}</strong>)
                                </p>
                            )}
                        </div>
                    </List.Item>
                </List>
            </Grid.Column>
        );
    }

    let paymentData = null;

    if (loadingPaymentMethod) {
        paymentData = (
            <Grid.Column mobile={16} computer={8} tablet={8}>
                <Header textAlign="center" as="h3" content="Payment Info" />
                <List celled size="large">
                    <List.Item>Loading...</List.Item>
                </List>
            </Grid.Column>
        );
    } else {
        if (paymentMethod) {
            let cardBrandIcon = null;

            switch (paymentMethod.brand) {
                case "visa":
                    cardBrandIcon = <Icon size="large" name="cc visa" />;
                    break;
                case "mastercard":
                    cardBrandIcon = <Icon size="large" name="cc mastercard" />;
                    break;
                case "amex":
                    cardBrandIcon = <Icon size="large" name="cc amex" />;
                    break;
                case "diners_club":
                    cardBrandIcon = <Icon size="large" name="cc diners club" />;
                    break;
                default:
                    cardBrandIcon = <Icon size="large" name="credit card" />;
                    break;
            }

            paymentData = (
                <Grid.Column mobile={16} computer={8} tablet={8}>
                    <Header textAlign="center" as="h3" content="Payment Info" />
                    <List celled size="large">
                        <List.Item>
                            <List.Header>Payment Method:</List.Header>
                            <div className="special-content">
                                {cardBrandIcon} <span>XXXX-XXXX-XXXX-{paymentMethod.last4}</span>
                                <Button onClick={goToUpdatePaymentMethod}>Change</Button>
                            </div>
                        </List.Item>
                    </List>
                </Grid.Column>
            );
        }

        if (showAddPaymentMethod) {
            paymentData = (
                <Grid.Column mobile={16} computer={8} tablet={8}>
                    <Header textAlign="center" as="h3" content="Payment Info" />
                    <List celled size="large">
                        <List.Item>
                            <List.Header>Payment Method:</List.Header>
                            <div className="special-content">
                                You do not have a defined payment method yet
                                <Button onClick={goToUpdatePaymentMethod}>Add</Button>
                            </div>
                        </List.Item>
                    </List>
                </Grid.Column>
            );
        }
    }

    return (
        <Grid className="user-information-grid">
            {userInformation} {paymentData}
        </Grid>
    );
}
