import React from "react";
import { Image } from "semantic-ui-react";
import { DefaultButton } from "../..";
import SadImage from "../../../images/survivalImages/warning.svg";

function NoSubscriptionsComponent(props) {
    const { goToSubscriptions, message, title } = props;

    return (
        <>
            <>
                <Image src={SadImage} size="small" centered />
                <p className="text-center">{message}</p>
            </>
            <div className="margin-top padding-bottom flex-center flex-column button-container-padding">
                <DefaultButton
                    title={title ? title : "Get a Survivalbox subscription"}
                    buttonAction={goToSubscriptions}
                />
            </div>
        </>
    );
}

export default NoSubscriptionsComponent;
