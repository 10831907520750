import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import { Container } from "semantic-ui-react";
import { Loader } from "../../components";
import StepThreeComponent from "../../components/screenComponents/subscribe/StepThreeComponent";
import { getLocalStorageItem, removeLocalStorageItem } from "../../services/LocalStorageService";
import { saveStepOne, saveStepThree, saveStepFour } from "../../store/subscriptions/actions";
import * as ROUTES from "../../navigation/Routes";
import * as LOCALSTORAGE from "../../constants/LocalStorage";
import "../../scss/pages/get_a_box.scss";

function StepThree(props) {
    const { subscriptions, history, business, saveStepOne, saveStepThree, saveStepFour, user } = props;
    const { addOns, shipping } = business;
    const { subscriptionPlan, subscriptionBox, selectedAddOns } = subscriptions;

    const [addOnItems, setAddOnItems] = useState([]);
    const [showCards, setShowCards] = useState(false);

    useEffect(() => {
        const survivalType = getLocalStorageItem(LOCALSTORAGE.SURVIVAL_TYPE);
        const survivalSize = getLocalStorageItem(LOCALSTORAGE.SURVIVAL_SIZE);

        if (shipping && survivalType) {
            const shippingPrice = {
                ...shipping[0].prices[0],
                autoRenewing: true,
                quantity: 1,
            };

            if (survivalType) {
                saveStepOne({
                    subscriptionBox: survivalType,
                });
            }

            const selectedPlan = business.plans.find((plan) => plan.metadata.survivalType === survivalType);

            const subscriptionBox =
                selectedPlan && selectedPlan.prices.find((price) => price.metadata.type === survivalSize);

            subscriptionBox.autoRenewing = true;

            saveStepFour({
                subscriptionPlan: [subscriptionBox],
                shippingPrice: shippingPrice,
                oneTimeGift: false,
            });
        }
    }, [business, saveStepFour, saveStepOne, shipping]);

    useEffect(() => {
        if (subscriptionBox && shipping) {
            const selectedPlanAddOns = addOns.filter((addOn) => {
                return addOn.metadata.survivalType === subscriptionBox;
            });
            const addOnsPrices = selectedPlanAddOns
                .flatMap((addOn) => addOn.prices)
                .filter((addOn) => !addOn.metadata.name.includes("Garden"));

            if (selectedAddOns.length > 0) {
                let selectedAddOnsComposed = [];
                const addOnTypes = addOnsPrices.map((addOn) => addOn.metadata.tradename);

                addOnTypes.forEach((type) => {
                    const typeExist = selectedAddOns.find((addOn) => type === addOn.metadata.tradename);

                    if (typeExist) {
                        selectedAddOnsComposed.push(typeExist);
                    } else {
                        const addOnItemLess = addOnsPrices.find((price) => price.metadata.tradename === type);

                        selectedAddOnsComposed.push({
                            ...addOnItemLess,
                            autoRenewing: true,
                            quantity: 0,
                        });
                    }
                });

                setAddOnItems(selectedAddOnsComposed);

                if (selectedAddOnsComposed.length > 0) {
                    setShowCards(true);
                }
            } else {
                if (selectedPlanAddOns) {
                    if (addOnsPrices) {
                        const addOnItems = addOnsPrices.map((price) => {
                            return {
                                ...price,
                                autoRenewing: true,
                                quantity: 0,
                            };
                        });

                        setAddOnItems(addOnItems);

                        if (addOnItems.length > 0) {
                            setShowCards(true);
                        } else {
                            history.push(ROUTES.STEP_FOUR);
                        }
                    }
                }
            }
        } else {
            history.push(ROUTES.STEP_ONE);
        }
    }, [addOns, subscriptionBox, selectedAddOns, history, user.userData, shipping]);

    const onChanngeAddOn = (event, { value }) => {
        const composedValue = value.split("&&");
        const priceId = composedValue[0];

        let price = addOnItems.find((addOn) => addOn.id === priceId);

        if (value) {
            price.quantity = Number(composedValue[1]);
        }

        const newAddOnItems = addOnItems.map((addOn) => {
            if (addOn.id === priceId) {
                return price;
            } else {
                return addOn;
            }
        });

        setAddOnItems(newAddOnItems);
    };

    const onChangeAutoRenewing = (event) => {
        const priceId = event.target.id;
        const checked = event.target.checked;
        const priceOld = addOnItems.find((addOn) => addOn.id === priceId);
        let price = null;

        const recurringPrice = addOns
            .flatMap((addOn, i) => addOn.prices)
            .find((oneTime) => oneTime.metadata.tradename === priceOld.metadata.tradename);

        price = {
            ...recurringPrice,
            quantity: priceOld.quantity,
            autoRenewing: checked,
        };

        const newAddOnItems = addOnItems.map((addOn) => {
            if (addOn.id === priceId) {
                return price;
            } else {
                return addOn;
            }
        });

        setAddOnItems(newAddOnItems);
    };

    const saveStepThreeAndNext = () => {
        removeLocalStorageItem(LOCALSTORAGE.SURVIVAL_TYPE);
        removeLocalStorageItem(LOCALSTORAGE.SURVIVAL_SIZE);
        const addOnItemsSave = addOnItems.filter((item) => item.quantity > 0);

        saveStepThree({
            selectedAddOns: addOnItemsSave,
        });

        routeNextPage();
    };

    const routeNextPage = () => {
        history.push(ROUTES.STEP_FOUR);
    };

    const goToStepTwo = () => {
        history.replace(ROUTES.STEP_TWO, {
            selectPremium: true,
        });
    };

    let addOnsSection = null;

    if (showCards) {
        addOnsSection = (
            <StepThreeComponent
                addOnItems={addOnItems}
                onChanngeAddOn={onChanngeAddOn}
                onChangeAutoRenewing={onChangeAutoRenewing}
                saveStepThreeAndNext={saveStepThreeAndNext}
                subscriptionPlan={subscriptionPlan}
                goToStepTwo={goToStepTwo}
            />
        );
    } else {
        addOnsSection = <Loader />;
    }

    return (
        <>
            <Container className="payment-container">{addOnsSection}</Container>
        </>
    );
}

const mapDispatchToProps = (dispatch) => ({
    saveStepOne: (stepOne) => dispatch(saveStepOne(stepOne)),
    saveStepThree: (stepThree) => dispatch(saveStepThree(stepThree)),
    saveStepFour: (stepFour) => dispatch(saveStepFour(stepFour)),
});

const mapStateToProps = (state) => {
    return {
        business: state.business,
        subscriptions: state.subscriptions,
        user: state.user,
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(withRouter(StepThree));
