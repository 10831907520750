import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { Image, Modal, Header } from "semantic-ui-react";
import { DefaultButton, LinkButton } from "..";
//import { firebaseService } from "../../services/AuthService";
import ConfirmImage from "../../images/survivalImages/confirm.svg";
import { getLocalStorageItem, setLocalStorageItem } from "../../services/LocalStorageService";
import { OTP_SENDED } from "../../constants/LocalStorage";
import "../../scss/components/_modal.scss";
import * as CONFIG from "../../services/ConfigService";
var API_URL = CONFIG.getApiUrl();
if (API_URL.indexOf("/graphql") > -1) API_URL = API_URL.split("/graphql").join("");

export function EmailConfirmationModal(props) {
    const otp_code0 = useRef(null);

    const { email, open, confirmAction } = props;
    const [showResendEmail, setShowResendEmail] = useState(false);
    const [loadingConfirmation, setLoadingConfirmation] = useState(false);
    const [showUnconfirmedText, setShowUnconfirmedText] = useState(false);
    const [showUnconfirmedValue, setShowUnconfirmedValue] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        const otpSended = getLocalStorageItem(OTP_SENDED);
        if (!otpSended) {
            setTimeout(() => {
                sendEmailVerification();
            }, 5000);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setShowResendEmail(true);
        if (open) {
            setOpenModal(true);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const sendEmailVerification = () => {
        axios.defaults.headers.get["Content-Type"] = "application/x-www-form-urlencoded";
        let url = API_URL + "/generateOtp?email=" + email + "&&password=''";
        console.log("URL:", url);
        axios
            .get(url)
            .then((data) => {
                console.log("Data:", data);
                setLocalStorageItem(OTP_SENDED, true);
            })
            .catch((err) => {
                console.log("Error:", err);
            });
        //firebaseService.doSendEmailVerification();
    };

    const sendDifferentEmailVerification = () => {
        open();
    };

    const resendEmailConfirmation = () => {
        sendEmailVerification();
        setShowUnconfirmedText(false);
        setShowResendEmail(false);
    };

    var validate = true;

    const emailVerifiedAction = () => {
        setShowUnconfirmedText(false);
        setShowResendEmail(false);
        setLoadingConfirmation(true);
        setShowUnconfirmedValue(false);
        if (otp_code0.current.value.length === 0) {
            otp_code0.current.style.borderColor = "red";
            validate = false;
        }
        if (validate === false) {
            setShowUnconfirmedValue(true);
            setLoadingConfirmation(false);
            return;
        }
        if (otp_code0.current.value) {
            let otp = otp_code0.current.value;
            verifiedEmail(otp);
        } else {
            setShowUnconfirmedValue(true);
            setLoadingConfirmation(false);
            return;
        }

        setTimeout(() => {
            setShowUnconfirmedText(true);
            setLoadingConfirmation(false);
            setShowResendEmail(true);
        }, 5000);
    };

    const verifiedEmail = (otp) => {
        axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";
        let url = API_URL + "/generateOtp";
        //let url  ="http://localhost:5001/oneway-survivalboxes-dev/us-central1/generateOtp";
        axios
            .post(url, {
                email: email,
                otp: otp,
            })
            .then((data) => {
                console.log("Data:", data);
                confirmAction();
            })
            .catch((err) => {
                console.log("Error:", err);
            });
    };

    const checkInputLenght = () => {
        setShowUnconfirmedValue(false);
        otp_code0.current.style.borderColor = "#b98b5566";

        if (otp_code0.current.value.length > otp_code0.current.maxLength)
            otp_code0.current.value = otp_code0.current.value.slice(0, otp_code0.current.maxLength);
    };

    return (
        <Modal className="confirm-modal" open={openModal} size="small">
            <Modal.Content>
                <Image src={ConfirmImage} size="small" centered />
                <Header as="h2" textAlign="center">
                    Email Confirmation
                </Header>
                <p className="message">
                    Please check your email ({email})
                    <br />
                    Locate the confirmation email and enter the code sent
                </p>
            </Modal.Content>
            <Modal.Actions>
                <div className="action-buttons-container">
                    <div className="action-buttons-content-otp">
                        <input
                            className="input_otp"
                            ref={otp_code0}
                            maxLength="5"
                            type="number"
                            onInput={checkInputLenght}
                        />
                    </div>
                </div>
                <div className="action-buttons-container">
                    <div className="action-buttons-content">
                        <DefaultButton
                            title="Accept"
                            buttonAction={emailVerifiedAction}
                            loading={loadingConfirmation}
                        />
                    </div>
                </div>
                <br />
                {showUnconfirmedText && (
                    <p className="color-cta">The code entered is not correct, please check and try again</p>
                )}
                {showUnconfirmedValue && (
                    <p className="color-cta">Copy and paste the code that was sended to your email.</p>
                )}
                {showResendEmail && (
                    <LinkButton
                        textBefore="You still do not receive the email?"
                        title="Send again!"
                        buttonAction={resendEmailConfirmation}
                    />
                )}
                {showResendEmail && (
                    <LinkButton
                        textBefore="This is a wrong email?"
                        title="Give us a diferent one!"
                        buttonAction={sendDifferentEmailVerification}
                    />
                )}
            </Modal.Actions>
        </Modal>
    );
}
