import * as actions from "./action-types";

export const initialState = {
    addOns: [],
    plans: [],
};

function reducer(state = initialState, action) {
    switch (action.type) {
        case actions.LOAD_PRODUCTS_LIST:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
}

export default reducer;
