import axios from "axios";
import { Image, Modal } from "semantic-ui-react";
import ErrorImage from "../../images/survivalImages/error.svg";
import "../../scss/components/_modal.scss";
import React, { useRef } from "react";
import { DefaultButton } from "../../components";
import * as CONFIG from "../../services/ConfigService";
var API_URL = CONFIG.getApiUrl();
if(API_URL.indexOf("/graphql") > -1) API_URL = API_URL.split("/graphql").join("");

export function ErrorModal(props) {    
    const { message, open, closeFunction } = props;
    var final_message = "";
    var input = false;
    var uid ="";
    const value_input = useRef(null);;
    if(message && message.indexOf("input_t") > -1){
        console.log("Si va el input");
        input = true;
        let final_message1 = message.split("input_t").join("");
        final_message = final_message1.split("&&uid=")[0];
        uid = message.split("&&uid=")[1];
        console.log("Este es el UID final:", uid);
    }else{
        input = false;
        final_message = message;
    }
    let inputSection = null;
    let buttonSection = null;
    
    const addEmailToUser = () => {
        if(value_input.current.value.indexOf("@")){
            axios.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded';        
            let url = API_URL + "/generateOtp";        
            //let url = "http://localhost:5001/oneway-survivalboxes-dev/us-central1/generateOtp"
            axios.post(url,{
                email: value_input.current.value,
                addEmail:true,
                uid:uid            
                //otp:otp
            }).then(data=>{            
                console.log("Data:", data);
                //confirmAction(); 
                closeFunction();
            }).catch(err=>{
                console.log("Error:", err);
            });
        }else{
            console.log()
        }                                                                                                                                                                                                                                                                                                                                                             
        
    }
    
    
    if(input){
        inputSection = (
            <>                    
            <div className="action-buttons-content">                    
            <input
            placeholder="Email"
            className="input_email2"
            ref={value_input}
            type="email"                                                                                    
            />
            </div>
            </>
            );
            let buttonTitle = "Save"
            buttonSection = <DefaultButton title={buttonTitle} buttonAction={addEmailToUser} />;
        }
        
        return (
            <Modal className="error-modal" closeIcon open={open} size="small" onClose={closeFunction}>
            <Modal.Content>
            <Image src={ErrorImage} size="small" centered />
            <p>{final_message}</p>
            {inputSection}
            {buttonSection}
            </Modal.Content>
            </Modal>
            );   
        }
        