import React, { useState, useEffect, useReducer } from "react";
import { Modal, Header } from "semantic-ui-react";
import "../../scss/components/_modal.scss";
import { DefaultButton, ConfirmationModal, CustomDropdown, SuccessModal, ErrorModal, } from "..";
import { renewSessionId } from '../../services/SubscriptionPlanService';
import { changeSubscriptionFunction } from '../../services/SetupService';
import {updateSubscriptionsStore} from '../../services/UpdateReduxStoreService';

export function MoveLevelModal(props) {
    const { open, closeAction, subscriptionId, selectedSubscription, plans, subscriptions, saveUserSubscriptions } = props;
    const [subscriptionSelected, setSubscriptionSelected] = useState("");
    const [labelButton, setLabelButton] = useState("");
    const [availablePlans, setAvailablePlans] = useState([])
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [loadingState, setLoadingState] = useReducer((state, newState) => ({ ...state, ...newState }), {
        loading: false,
        openErrorModal: false,
        errorContent: "",
        openSuccessModal: false,
        successContent: ""
    });
    

    let nameAction;
    const mySessionId= renewSessionId();

    useEffect(() => {
		const currentPlan = selectedSubscription.prices.find(price => Boolean(price.price.metadata.subscription)).price;

        const currentProduct = plans.find(plan => plan.id===currentPlan.product).prices
        
        const availablePrice = currentProduct.filter(price => price.id!==currentPlan.id)
        
        const availablePlan = availablePrice.map((plan, index) => {
            return {
                key: index,
                value: plan.id,
                text: plan.metadata.tradename
            }
        })
        setAvailablePlans(availablePlan);
        setSubscriptionSelected(availablePlan[0].value);
        setLabelButton(availablePrice[0].metadata.name);
	}, [selectedSubscription, plans]);

    const handleConfirmModal = () => {
        setOpenConfirmModal(!openConfirmModal);
    };

    const handleSelection = (event) => {
		setSubscriptionSelected(event.value)
        setLabelButton(event.text)
	};


    if(labelButton.toLowerCase().includes("premium")){
        nameAction="Upgrade"
    } else if(labelButton.toLowerCase().includes("basic")) {
        nameAction="Downgrade"
    } else {
        nameAction="Upgrade/Downgrade"
    }

    const processSelectedAction = async () => {        
        setLoadingState({
            loading: true
        })

       const subscriptionDataObj = {
            input: {
                sessionId: mySessionId,
                subscriptionId: subscriptionId,
                prices: [{
                    id: subscriptionSelected,
                    quantity: 1,
                    recurring: selectedSubscription.recurring,
                    pending: true
                }]
            }
        }
        const changePlanResponse = await changeSubscriptionFunction(subscriptionDataObj);
        if (changePlanResponse) {
            setLoadingState({
                loading: false,
            })
            if(typeof changePlanResponse === 'string') {
                setLoadingState({
                    openErrorModal: true,
                    errorContent: changePlanResponse,
                })
            } else {
                const subscriptionUpdated = updateSubscriptionsStore(
                    saveUserSubscriptions,
                    subscriptions,
                    changePlanResponse
                );

                if (subscriptionUpdated) {
                    setLoadingState({
                        openSuccessModal: true,
                        successContent: "Your account has been successfully changed."
                    })                   
                }
            }
        }
    }

    const closeAllModal = () => {
        setLoadingState({
            openSuccessModal:false,
            openErrorModal: false,
        })
        closeAction();
    }

    const errorModal = loadingState.openErrorModal && (
        <ErrorModal
            open={loadingState.openErrorModal}
            message={loadingState.errorContent}
            closeFunction={closeAllModal}
        />
    );

    const successModal = loadingState.openSuccessModal && (
        <SuccessModal
            open={loadingState.openSuccessModal}
            message={loadingState.successContent}
            successAction={closeAllModal}
            closeAction={closeAllModal}
            title="Success!"
        />
    );

    const confirmationModal = openConfirmModal && (
        <ConfirmationModal
            open={openConfirmModal}
            message={`Are you sure you want to ${nameAction.toLowerCase()} this subscription?`}
            closeAction={handleConfirmModal}
            confirmAction={() => {
                processSelectedAction();
                setOpenConfirmModal(false);
            }}
        />
    );

    return (
        <Modal className="pause-subscription-modal custom-padding-bottom-1m" closeIcon open={open} size="small" onClose={closeAction}>
            {confirmationModal}
            {errorModal}
            {successModal}
            <Modal.Content>
                <Header as="h3" className="custom-header-modal" textAlign="center" content={`${nameAction} your Subscription`} />
                <div className="custom-dropdown-size">
                    <CustomDropdown
                        options={availablePlans}
                        id='selectedPlan'
                        label='Available Plan'
                        onChangeAction={handleSelection}
                        value={subscriptionSelected}
                    />
                </div>
            </Modal.Content>
            <Modal.Actions>
                <div className="action-buttons-container custom-padding-bottom-1dot5em">
                    <div className="action-buttons-content">
                        <DefaultButton
                            title={nameAction}
                            buttonAction={handleConfirmModal}
                            loading={loadingState.loading}
                            disabled={ loadingState.loading || subscriptionSelected==="" }
                        />{" "}
                    </div>
                </div>
            </Modal.Actions>
            <div>
                <p className="text-center">
                    This change will take effect the next billing cycle.
                </p>
            </div>
        </Modal>
    );
}