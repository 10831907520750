import * as actions from "./action-types";
import {
    getLocalStorageItem,
    mergeDataToLocalStorage,
    removeLocalStorageItem,
    setLocalStorageItem,
} from "../../services/LocalStorageService";
import * as LOCALSTORAGE from "../../constants/LocalStorage";
import { v4 as uuidv4 } from "uuid";

export const getSubscriptionStoragedData = () => {
    return async function (dispatch) {
        const storedStepsData = getLocalStorageItem(LOCALSTORAGE.SUBSCRIPTION_DATA);

        dispatch({ type: actions.LOAD_STORAGE_STEPS, payload: storedStepsData });
    };
};

export const saveStepOne = (stepOne) => {
    const stepOneData = {
        ...stepOne,
        subscriptionPlan: [],
        upgradeBoxes: [],
        step: 1,
    };

    return async function (dispatch) {
        mergeDataToLocalStorage(LOCALSTORAGE.SUBSCRIPTION_DATA, stepOneData);

        dispatch({ type: actions.SAVE_STEP_ONE, payload: stepOneData });
    };
};

export const saveStepTwo = (stepTwo, isSet) => {
    let stepTwoData = null;

    if (isSet) {
        stepTwoData = {
            ...stepTwo,
        };
    } else {
        stepTwoData = {
            ...stepTwo,
            step: 2,
        };
    }

    return async function (dispatch) {
        mergeDataToLocalStorage(LOCALSTORAGE.SUBSCRIPTION_DATA, stepTwoData);

        dispatch({ type: actions.SAVE_STEP_TWO, payload: stepTwoData });
    };
};

export const saveStepThree = (stepThree, isSet) => {
    let stepThreeData = null;

    if (isSet) {
        stepThreeData = {
            ...stepThree,
        };
    } else {
        stepThreeData = {
            ...stepThree,
            step: 3,
        };
    }

    return async function (dispatch) {
        mergeDataToLocalStorage(LOCALSTORAGE.SUBSCRIPTION_DATA, stepThreeData);

        dispatch({ type: actions.SAVE_STEP_THREE, payload: stepThreeData });
    };
};

export const saveStepFour = (stepFour, isSet) => {
    let stepFourData = null;
    if (isSet) {
        stepFourData = {
            ...stepFour,
        };
    } else {
        stepFourData = {
            ...stepFour,
            step: 4,
        };
    }

    return async function (dispatch) {
        mergeDataToLocalStorage(LOCALSTORAGE.SUBSCRIPTION_DATA, stepFourData);

        dispatch({ type: actions.SAVE_STEP_FOUR, payload: stepFourData });
    };
};

export const saveStepFive = (stepFive, isSet) => {
    let stepFiveData = null;
    if (isSet) {
        stepFiveData = {
            ...stepFive,
        };
    } else {
        stepFiveData = {
            ...stepFive,
            step: 5,
        };
    }

    return async function (dispatch) {
        mergeDataToLocalStorage(LOCALSTORAGE.SUBSCRIPTION_DATA, stepFiveData);

        dispatch({ type: actions.SAVE_STEP_FIVE, payload: stepFiveData });
    };
};

export const saveStepSix = (stepSix) => {
    const stepSixData = {
        shippingAddress: stepSix,
        step: 6,
    };

    return async function (dispatch) {
        mergeDataToLocalStorage(LOCALSTORAGE.SUBSCRIPTION_DATA, stepSixData);
        dispatch({ type: actions.SAVE_STEP_SIX, payload: stepSixData });
    };
};

export const saveDiscountCoupon = (couponData) => {
    return async function (dispatch) {
        mergeDataToLocalStorage(LOCALSTORAGE.SUBSCRIPTION_DATA, {
            couponData: couponData,
        });

        dispatch({
            type: actions.SAVE_COUPON_DATA,
            payload: {
                couponData: couponData,
            },
        });
    };
};

export const resetSubscriptionStore = () => {
    return async function (dispatch) {
        const sessionId = uuidv4();

        removeLocalStorageItem(LOCALSTORAGE.EMAIL);
        removeLocalStorageItem(LOCALSTORAGE.OLD_SUBSCRIBER);
        removeLocalStorageItem(LOCALSTORAGE.SUBSCRIPTION_DATA);
        setLocalStorageItem(LOCALSTORAGE.SESSION_ID, sessionId);

        dispatch({ type: actions.RESET_SUBSCRIPTION_STORE });
    };
};

export const saveSurvivalType = (giftType, survivalType) => {
    return async function (dispatch) {
        if (survivalType) {
            mergeDataToLocalStorage(LOCALSTORAGE.SUBSCRIPTION_DATA, {
                subscriptionBox: survivalType,
                oneTimeGift: false,
            });
        } else {
            mergeDataToLocalStorage(LOCALSTORAGE.SUBSCRIPTION_DATA, {
                giftType: giftType,
            });
        }

        dispatch({
            type: actions.SAVE_ONE_TIME_GIFT_TYPE,
            payload: {
                giftType: giftType,
                subscriptionBox: survivalType,
            },
        });
    };
};
