import React, { useState } from "react";
import { Header } from "semantic-ui-react";
import moment from "moment";
import {
    BoxSubHeader,
    DefaultButton,
    BackButton,
    SuccessModal,
    ConfirmationModal,
    CustomCheckbox,
    CustomRadioButton,
} from "../..";
import { removeLocalStorageItem } from "../../../services/LocalStorageService";
import * as CONSTANTS from "../../../constants/Global";
import * as CONFIRMATIONS from "../../../constants/Confirmations";
import * as LOCALSTORAGE from "../../../constants/LocalStorage";

function CancelSubscriptionComponent(props) {
    const {
        history,
        cancelData,
        onChangePause,
        selectedPause,
        subscription,
        processCancelation,
        loadingState,
        setUnSubscribeNewsletter,
        unSubscribeNewsletter,
    } = props;
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [customerSelection, setCustomerSelection] = useState("");
    const [openConfirmModal, setOpenConfirmModal] = useState(false);

    const nextRenewalDate = moment(subscription.currentPeriodEnd)
        .add(selectedPause / 30, "M")
        .format("MMMM/DD/YYYY");

    const continueSubscriptionsSelection = (action) => {
        switch (action) {
            case CONSTANTS.CANCEL_SUBSCRIPTIONS_OPTIONS.PAUSE:
                if (selectedPause !== "") {
                    setOpenSuccessModal(true);
                    setCustomerSelection(action);
                    setSuccessMessage(CONFIRMATIONS.PAUSE_SUBSCRIPTION(selectedPause, nextRenewalDate));
                }

                break;
            case CONSTANTS.CANCEL_SUBSCRIPTIONS_OPTIONS.DISCOUNT:
                setOpenSuccessModal(true);
                setCustomerSelection(action);
                setSuccessMessage(CONFIRMATIONS.DISCOUNT_10_OFF);

                break;
            default:
                break;
        }
    };

    const cancelSubscriptionsSelection = () => {
        setOpenConfirmModal(true);
    };

    const closeSuccessModal = () => {
        setOpenSuccessModal(false);
    };

    const processCustomerSelection = () => {
        const cancelDataObj = {
            input: {
                subscriptionId: subscription.id,
                treatment: customerSelection === "" ? CONSTANTS.CANCEL_SUBSCRIPTIONS_OPTIONS.FINISH : customerSelection,
                pauseTimeDays: String(selectedPause),
                reason: cancelData.reason,
                coments: cancelData.otherReason,
            },
        };

        if (customerSelection === "") {
            removeLocalStorageItem(LOCALSTORAGE.SELECTED_SUBSCRIPTION);
        }

        closeSuccessModal();
        closeConfirmModal();
        processCancelation(cancelDataObj);
    };

    const closeConfirmModal = () => {
        setOpenConfirmModal(false);
    };

    let successModal = null;

    if (openSuccessModal) {
        successModal = (
            <SuccessModal
                open={openSuccessModal}
                message={successMessage}
                successAction={processCustomerSelection}
                closeAction={closeSuccessModal}
            />
        );
    }

    let confirmModal = null;

    if (openConfirmModal) {
        confirmModal = (
            <ConfirmationModal
                open={openConfirmModal}
                message={CONFIRMATIONS.CANCEL_SUBSCRIPTION}
                closeAction={closeConfirmModal}
                confirmAction={processCustomerSelection}
            />
        );
    }

    const options = (
        <div className="manage-subscription-container">
            <Header as="h3" content="We hear you" />
            <p className="detault-paragraph">
                We'd love to make Survivalbox work for you. <br />
                Would you be interested in any of the offers below?
            </p>

            <div className="recoveringCard">
                <div className="title">
                    <Header as="h3" textAlign="center" content="Keep in Survivalbox for less" />
                </div>
                <div className="text">
                    <p className="detault-paragraph text-center">
                        For your next billing, get one credit at a discounted rate of 10% off.
                    </p>
                </div>
                <div className="action">
                    <DefaultButton
                        title="Continue subscription"
                        buttonAction={() =>
                            continueSubscriptionsSelection(CONSTANTS.CANCEL_SUBSCRIPTIONS_OPTIONS.DISCOUNT)
                        }
                        loading={loadingState.loadingDiscount}
                        disabled={loadingState.disableButtons}
                    />
                </div>
            </div>

            <div className="recoveringCard">
                <div className="title">
                    <Header as="h3" textAlign="center" content="Pause your subscription" />
                </div>
                <div className="text">
                    <p className="detault-paragraph text-center">
                        Sometimes, you just need a break. Keep your benefits and pause payments for the next:
                    </p>
                    <div className="radio-actions">
                        {CONSTANTS.PAUSE_SUBSCRIPTION_TIME.map((option, index) => {
                            return (
                                <CustomRadioButton
                                    key={index}
                                    id={option.value}
                                    label={option.text}
                                    value={selectedPause}
                                    onChangeAction={onChangePause}
                                    isSmallSize={true}
                                />
                            );
                        })}
                    </div>
                </div>
                <div className="action">
                    <DefaultButton
                        title="Pause subscription"
                        buttonAction={() =>
                            continueSubscriptionsSelection(CONSTANTS.CANCEL_SUBSCRIPTIONS_OPTIONS.PAUSE)
                        }
                        loading={loadingState.loadingPause}
                        disabled={loadingState.disableButtons}
                    />
                </div>
            </div>
            <CustomCheckbox
                label="I want to unsubscribe to future newsletters"
                isBigSize={true}
                onChangeAction={() => setUnSubscribeNewsletter(!unSubscribeNewsletter)}
                value={unSubscribeNewsletter}
            />
        </div>
    );

    return (
        <section className="padding-page">
            {confirmModal}
            {successModal}
            <BoxSubHeader singleTitle="Cancel Subscription" history={history} />
            {options}
            <div className="margin-top padding-bottom flex-center flex-column button-container-padding">
                <DefaultButton
                    title="Finish cancelling"
                    buttonAction={cancelSubscriptionsSelection}
                    inverted={true}
                    loading={loadingState.loadingCancelation}
                    disabled={loadingState.disableButtons}
                />
                <BackButton title="back" />
            </div>
        </section>
    );
}

export default CancelSubscriptionComponent;
