import React, { useEffect, useReducer, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import { Container } from "semantic-ui-react";
import { EmailReset, EmailConfirmationModal } from "../components";
import LoginComponent from "../components/screenComponents/LoginComponent";
import { validateInputFilled, validateInputEmail } from "../services/DataValidationService";
import { signInEmailAndPassWord, signInWithFacebook, signInWithGoogle, firebaseService } from "../services/AuthService";
import { getTribeCommunityToken } from "../services/SetupService";
import { setCookie } from "../services/CookieService";
import { getLocalStorageItem } from "../services/LocalStorageService";
import { getUserStoredData } from "../store/user/actions";
import * as ROUTES from "../navigation/Routes";
import * as LOCALSTORAGE from "../constants/LocalStorage";
import "../scss/pages/get_a_box.scss";
import axios from "axios";
import * as CONFIG from "../services/ConfigService";
import { ERROR_SUBSCRIPTION_IS_NOT_PREMIUN } from "../constants/Errors";
var API_URL = CONFIG.getApiUrl();
if (API_URL.indexOf("/graphql") > -1) API_URL = API_URL.split("/graphql").join("");

const inputsIds = [
    { name: "email", error: "Email is required" },
    { name: "password", error: "Password is required" },
];

function Login(props) {
    const { history, getUserStoredData, location, user, subscriptions } = props;
    const { giftType } = subscriptions;
    const { userData } = user;
    const isSubscriptionRoutes = location.state?.accountRoutes === "subscription";
    const isCommunityRoute = location.state?.accountRoutes === "community";
    const showModal = location.state?.showModal;

    const [formState, setStateForm] = useReducer((state, newState) => ({ ...state, ...newState }), {
        email: "",
        password: "",
        emailError: false,
        passwordError: false,
        errorMessage: "",
        inputError: false,
        loadingSignUp: false,
        loadingSignInFacebook: false,
        loadingSignInGmail: false,
        disableSignUp: false,
    });
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [openResetEmailModal, setOpenResetEmailModal] = useState(false);

    useEffect(() => {
        const afterLoginRoute = () => {
            if (giftType) {
                history.replace(ROUTES.STEP_THREE);
            } else {
                if (isSubscriptionRoutes) {
                    history.replace(ROUTES.STEP_THREE);
                } else {
                    history.replace(ROUTES.SUBSCRIPTION_SUMMARY);
                }
            }
        };

        if (showModal) {
            setOpenResetEmailModal(true);
        }

        if (userData) {
            if (userData.firebase.sign_in_provider === "password" || userData.firebase.sign_in_provider === "custom") {
                if (userData.email_verified) {
                    afterLoginRoute();
                } else {
                    setStateForm({
                        openConfirmModal: true,
                        email: userData.email,
                    });
                }
            }
        }
    }, [showModal, history, userData, giftType, isSubscriptionRoutes]);

    const onChangeWriteOptions = (event, { value }) => {
        const inputId = event.target.id;

        setStateForm({
            [inputId]: value,
            [`${inputId}Error`]: false,
            errorMessage: "",
        });
    };

    const prepareSignUpEmailAndPassword = () => {
        const { inputError } = formState;

        const inputsRequiredAreFilled = checkInputsRequiredAreFilled();

        if (inputsRequiredAreFilled && !inputError) {
            signIpUserControl("email");
        }
    };

    const signIpUserControl = (type, userData) => {
        setStateForm({
            disableSignUp: true,
            errorMessage: "",
        });

        switch (type) {
            case "email":
                withEmailAndPasswordAction();
                break;
            case "facebook":
                withFacebookAction();
                break;
            case "google":
                withGoogleAction();
                break;
            default:
                break;
        }
    };

    const withEmailAndPasswordAction = async () => {
        setStateForm({
            loadingSignUp: true,
            disableSignUp: true,
        });

        const userData = {
            email: formState.email,
            password: formState.password,
        };

        try {
            const userSignUp = await signInEmailAndPassWord(userData, getUserStoredData);

            if (userSignUp) {
                setStateForm({
                    loadingSignUp: false,
                    disableSignUp: false,
                });

                if (userSignUp.data) {
                    if (userSignUp.data.user.emailVerified) {
                        afterLoginRoute();
                    } else {
                        setOpenConfirmModal(true);
                    }
                } else {
                    handleErrorMessage(userSignUp.message);
                }
            }
        } catch (error) {
            console.log(error);
            handleErrorMessage(error.message);
        }
    };

    const getEmail = async (uid) => {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.post["Content-Type"] = "application/json";
            let url = API_URL + "/generateOtp";
            //let url = "http://localhost:5001/oneway-survivalboxes-dev/us-central1/generateOtp"
            axios
                .post(url, {
                    getEmail: true,
                    uid: uid,
                })
                .then((data) => {
                    if (data.data["message"]["email"] == null || !data.data["message"]["email"]) {
                        return reject(null);
                    } else {
                        console.log("Antes del retorno:", data.data["message"]["email"]);
                        return resolve(data.data["message"]["email"]);
                    }
                })
                .catch((err) => {
                    console.log("Error:", err);
                });
        });
    };

    const withFacebookAction = async () => {
        setStateForm({
            loadingSignInFacebook: true,
            disableSignUp: true,
        });

        try {
            const userSignUp = await signInWithFacebook(getUserStoredData);
            if (userSignUp) {
                if (userSignUp["data"]["user"]["email"] == null) {
                    getEmail(userSignUp["data"]["user"]["uid"])
                        .then((data) => {
                            if (data != null) {
                                afterLoginRoute();
                            } else {
                                let message =
                                    "input_t We have detected that your user has been created through a social network, using the phone number, please enter a valid email, to continue with the process &&uid=" +
                                    userSignUp["data"]["user"]["uid"];
                                handleErrorMessage(message);
                                return;
                            }
                        })
                        .catch((err) => {
                            afterLoginRoute();
                        });
                    setStateForm({
                        loadingSignInFacebook: false,
                        disableSignUp: false,
                    });
                }
                setStateForm({
                    loadingSignInFacebook: false,
                    disableSignUp: false,
                });
                if (userSignUp.data) {
                    afterLoginRoute();
                } else {
                    handleErrorMessage(userSignUp.message);
                }
            }
        } catch (error) {
            setStateForm({
                loadingSignInFacebook: false,
                disableSignUp: false,
            });
            handleErrorMessage(error.message);
        }
    };

    const withGoogleAction = async () => {
        setStateForm({
            loadingSignInGmail: true,
            disableSignUp: true,
        });

        try {
            const userSignUp = await signInWithGoogle(getUserStoredData);

            setStateForm({
                loadingSignInGmail: false,
                disableSignUp: false,
            });

            if (userSignUp.data) {
                afterLoginRoute();
            } else {
                handleErrorMessage(userSignUp.message);
            }
        } catch (error) {
            setStateForm({
                loadingSignInGmail: false,
                disableSignUp: false,
            });

            handleErrorMessage(error.message);
        }
    };

    const checkInputsRequiredAreFilled = () => {
        const inputsFilledList = checkIfInputAreFilledAndCreateList();
        const quantityInputsFilled = inputsFilledList.filter((isFilled) => isFilled === true);

        if (quantityInputsFilled.length === inputsIds.length) {
            return true;
        }
        return false;
    };

    const checkIfInputAreFilledAndCreateList = () => {
        const inputsFilledQuantity = [];

        inputsIds.forEach((inputId) => {
            const inputFilled = validateInputFilled(formState[inputId.name]);

            if (inputId.name === "email" && inputFilled) {
                const inputEmail = validateInputEmail(formState[inputId.name]);
                inputsFilledQuantity.push(inputEmail);
                emailErrorController(inputEmail, inputId);
            } else {
                inputsFilledQuantity.push(inputFilled);
            }

            if (!inputFilled) {
                setStateForm({
                    [`${inputId.name}Error`]: inputId.error,
                });
            }
        });

        return inputsFilledQuantity;
    };

    const emailErrorController = (inputEmail, inputId) => {
        if (!inputEmail) {
            setStateForm({
                [`${inputId.name}Error`]: "Please enter a valid email",
                inputError: true,
            });
        } else {
            setStateForm({
                [`${inputId.name}Error`]: false,
                inputError: false,
            });
        }
    };

    const goToSignUp = () => {
        history.replace(ROUTES.SIGN_UP, {
            accountRoutes: "subscription",
        });
    };

    const goToSubscrption = () => {
        history.replace(ROUTES.STEP_ONE);
    };

    const handleResetEmailModal = () => {
        setOpenResetEmailModal(!openResetEmailModal);
    };

    const afterLoginRoute = async () => {
        if (isSubscriptionRoutes || giftType) {
            const email = getLocalStorageItem(LOCALSTORAGE.EMAIL);
            if (email) {
                history.replace(ROUTES.STEP_THREE);
            } else {
                history.replace(ROUTES.STEP_FIVE);
            }
        } else {
            if (isCommunityRoute) {
                setStateForm({
                    loadingSignUp: true,
                    disableSignUp: true,
                });

                const tribeCommmunity = await getTribeCommunityToken();

                if (tribeCommmunity) {
                    setStateForm({
                        errorMessage: ERROR_SUBSCRIPTION_IS_NOT_PREMIUN,
                        loadingSignUp: false,
                        disableSignUp: false,
                    });
                }
            } else {
                history.replace(ROUTES.SUBSCRIPTION_SUMMARY);
            }
        }
    };

    const handleErrorMessage = (errorMessage) => {
        setStateForm({
            errorMessage: errorMessage,
        });
    };

    const closeConfirmModal = () => {
        setOpenConfirmModal(false);
    };

    const confirmVerification = async () => {
        const idToken = await firebaseService.getIdToken();

        if (idToken) {
            setCookie(LOCALSTORAGE.USER_DATA, idToken);
            getUserStoredData();
        }
    };

    const removeErrorMessage = () => {
        if (formState.errorMessage === ERROR_SUBSCRIPTION_IS_NOT_PREMIUN) {
            history.replace(ROUTES.SUBSCRIPTION_SUMMARY);
        } else {
            setStateForm({
                errorMessage: "",
            });
        }
    };

    let confirmationModal = null;

    if (openConfirmModal) {
        confirmationModal = (
            <EmailConfirmationModal
                open={openConfirmModal}
                email={formState.email}
                closeAction={closeConfirmModal}
                confirmAction={confirmVerification}
            />
        );
    }

    let resetEmailModal = null;

    if (openResetEmailModal) {
        resetEmailModal = <EmailReset open={openResetEmailModal} closeFunction={handleResetEmailModal} />;
    }

    return (
        <>
            {resetEmailModal}
            {confirmationModal}

            <Container>
                <LoginComponent
                    state={formState}
                    onChangeWriteOptions={onChangeWriteOptions}
                    prepareSignUpEmailAndPassword={prepareSignUpEmailAndPassword}
                    signUpUserControl={signIpUserControl}
                    goToSignUp={goToSignUp}
                    handleResetEmailModal={handleResetEmailModal}
                    isSubscriptionRoutes={isSubscriptionRoutes}
                    goToSubscrption={goToSubscrption}
                    removeErrorMessage={removeErrorMessage}
                />
            </Container>
        </>
    );
}

const mapDispatchToProps = (dispatch) => ({
    getUserStoredData: () => dispatch(getUserStoredData()),
});

const mapStateToProps = (state) => {
    return {
        subscriptions: state.subscriptions,
        user: state.user,
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(withRouter(Login));
