import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import { Container } from "semantic-ui-react";
import moment from "moment";
import BoxDescriptionComponent from "../../components/screenComponents/account/BoxDescriptionComponent";
import "../../scss/pages/account.scss";

function BoxDescription(props) {
    const { history, location, user } = props;
    const { subscriptions } = user;
    const [boxData, setBoxData] = useState({});

    useEffect(() => {
        const configureCurrentBox = () => {
            const referenceData = location.search.replace("?", "");
            const boxDataIds = referenceData.split("&&");
            const seachSubscriptionId = boxDataIds[0];
            const searchBoxId = boxDataIds[1];
            const subscription = subscriptions.find((subscription) => subscription.id === seachSubscriptionId);
            const { shippingAddress, ownerId, id } = subscription;
            const currentRawBox = subscription.boxes.find((box) => box.id === searchBoxId);

            const addOOnsItems = currentRawBox.prices.filter((item) => Boolean(item.price.metadata.addOn));
            const subscriptionInfo = currentRawBox.prices.find((item) => Boolean(item.price.metadata.subscription));

            const settingOne = {
                "Shipping Id": currentRawBox.id,
                "Add Ons": addOOnsItems.map((item) => item.price.metadata.tradename).join(" | "),
                "Deliver Date": moment(currentRawBox.deliverData).add(1, "days").format("MMM DD YYYY"),
                "Track your Box_link": currentRawBox.trackingId,
                trackingLink: currentRawBox.tracking.trackingLink,
                Status: currentRawBox.status,
            };

            if (addOOnsItems.length === 0) {
                delete settingOne["Add Ons"];
            }

            const currentBox = {
                ...currentRawBox,
                name: subscriptionInfo.price.metadata.name,
                shippingSubscriptionAddress: shippingAddress,
                boxSettings: settingOne,
                ownerId: ownerId,
                subscriptionId: id,
                addOOnsItems: addOOnsItems,
            };

            setBoxData(currentBox);
        };

        if (subscriptions && subscriptions.length > 0) {
            configureCurrentBox();
        }
    }, [subscriptions, location]);

    let boxDescription = null;

    if (boxData.hasOwnProperty("id")) {
        boxDescription = <BoxDescriptionComponent history={history} boxData={boxData} />;
    }

    return (
        <>
            <Container>{boxDescription}</Container>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

export default compose(connect(mapStateToProps, null))(withRouter(BoxDescription));
