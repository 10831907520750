export const SUBSCRIPTION_DATA = "subscriptionData";
export const USER_DATA = "userData";
export const SUBSCRIPTION_INFO = "subscriptionInfo";
export const STORAGED_USER_SUBSCRIPTIONS = "storagedUserSubscriptions";
export const SELECTED_SUBSCRIPTION = "selectedSubscription";
export const SESSION_ID = "sessionId";
export const OTP_VALIDATOR = "otpValidation";
export const LAST_ROUTE = "lastRoute";
export const SURVIVAL_TYPE = "survivalType";
export const SURVIVAL_SIZE = "survivalSize";
export const OWNERID = "ownerId";
export const EMAIL = "email";
export const OLD_SUBSCRIBER = "oldSubscriber";
export const SIGN_IN_TIME = "signInTime";
export const OTP_SENDED = "otpSended";
