import React from "react";
import { Grid, Image, Table } from "semantic-ui-react";
import { SubHeader, SettingCard } from "../..";
import EmergencyBoxImage from "../../../images/survivalImages/emergency-box.svg";
import OutdoorBoxImage from "../../../images/survivalImages/outdoor-adventure-box.svg";
import * as ROUTES from "../../../navigation/Routes";
import * as CONSTANTS from "../../../constants/Global";
import { addOnsImages } from "../../../services/UpgradeBoxService";

function BoxDescriptionComponent(props) {
    const { history, boxData } = props;
    const { name, boxName, shippingAddress, shippingSubscriptionAddress, boxSettings, boxType, status, addOOnsItems } =
        boxData;

    console.log(addOOnsItems);

    let boxImage = null;

    if (name.includes("Emergency")) {
        boxImage = EmergencyBoxImage;
    } else {
        boxImage = OutdoorBoxImage;
    }

    const isSubscription = boxType === CONSTANTS.BOX_TYPES.SUBSCRIPTION;

    delete boxSettings["Thank you for being since"];

    let address = shippingSubscriptionAddress;
    if (shippingAddress && Object.keys(shippingAddress).length > 0) {
        address = shippingAddress;
    }

    const goToShippingAddress = () => {
        if (status === CONSTANTS.BOX_STATUS.UNSHIPPED) {
            history.push(ROUTES.SHIPPING_ADDRESS, {
                address: address,
                ...boxData,
            });
        }
    };

    const boxMonthImage = (
        <div className="box-image-section">
            <Image centered src={boxImage} />
        </div>
    );

    const shippingAddressData = {
        Recipient: `${address.firstName} ${address.lastName}`,
        Address: `${address.address}, ${address.city}, ${address.state}  `,
    };

    const settingsInfo = (
        <div className="padding-bottom padding-bottom-customized">
            <SettingCard
                title="Shipping"
                hasIcon={true}
                items={shippingAddressData}
                routerAction={goToShippingAddress}
                type="overlay"
            />
            <SettingCard title="Box settings" items={boxSettings} />
        </div>
    );

    let subHeaderMainTitle = "";
    let specialText = "";

    if (isSubscription) {
        if (boxName.includes("Gift")) {
            subHeaderMainTitle = "Manage One Time Gift";
        } else {
            subHeaderMainTitle = "Manage Subscription";
            specialText = name;
        }
    } else {
        subHeaderMainTitle = "Manage Subscription";
        specialText = name;
    }

    const addOnsSection = addOOnsItems.length > 0 && (
        <Table className="add-ons-table" basic>
            <Table.Header className="add-ons-header">
                <Table.Row>
                    <Table.HeaderCell colSpan="2" textAlign="center">
                        AddOn
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">Quantity</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">Amount</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">Charge</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">Status</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {addOOnsItems.map((item, index) => {
                    const addonImage = addOnsImages.find(
                        (image) => image.tradename === item.price.metadata.tradename
                    ).image;
                    return (
                        <Table.Row key={index}>
                            <Table.Cell textAlign="center">
                                <Image src={addonImage} size="tiny" />
                            </Table.Cell>
                            <Table.Cell textAlign="center">{item.price.metadata.tradename}</Table.Cell>
                            <Table.Cell textAlign="center"> {item.quantity}</Table.Cell>
                            <Table.Cell textAlign="center">
                                ${item.quantity * (item.price.unit_amount / 100)}
                            </Table.Cell>
                            <Table.Cell textAlign="center">{item.recurring ? "Monthly" : "One Time"}</Table.Cell>
                            <Table.Cell textAlign="center">
                                {item.tracking ? (
                                    <a href={item.tracking.trackingLink} target="_blank" rel="noreferrer">
                                        TRACKING
                                    </a>
                                ) : (
                                    "UNSHIPPED"
                                )}
                            </Table.Cell>
                        </Table.Row>
                    );
                })}
            </Table.Body>
        </Table>
    );

    return (
        <section className="padding-page">
            <SubHeader
                mainTitle={subHeaderMainTitle}
                specialText={specialText}
                isBoxTitle={true}
                showBackButton={true}
            />
            <div className="manage-subscription-container">
                <Grid className="subscription-grid">
                    <Grid.Row columns={2} only="large screen">
                        <Grid.Column>
                            {settingsInfo}
                            {addOnsSection}
                        </Grid.Column>
                        <Grid.Column>{boxMonthImage}</Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1} only="mobile tablet">
                        <Grid.Column>
                            <>
                                {boxMonthImage}
                                {settingsInfo}
                                {addOnsSection}
                            </>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        </section>
    );
}

export default BoxDescriptionComponent;
