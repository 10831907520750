import React, { useReducer } from "react";
import { Modal, Header } from "semantic-ui-react";
import axios from "axios";
import { CustomInput, DefaultButton, SuccessModal } from "../../components";
import { validateInputEmail } from "../../services/DataValidationService";

import "../../scss/components/_modal.scss";

export function SendgridModal(props) {
    const { open } = props;

    const [emailForm, setEmailForm] = useReducer((state, newState) => ({ ...state, ...newState }), {
        subscriberEmail: "",
        success: false,
        loading: false,
        errorMessage: false,
    });
    const postSengridEmail = async () => {
        const emailValid = validateInputEmail(emailForm.subscriberEmail);

        if (emailValid) {
            setEmailForm({
                loading: true,
                success: false,
            });
            try {
                const sengridEmailResponse = await axios.get(
                    `https://us-central1-oneway-grandbox.cloudfunctions.net/subscribeToNewsLetter/?email=${emailForm.subscriberEmail}&brand=Survivalbox`
                );
                if (sengridEmailResponse) {
                    setEmailForm({
                        loading: false,
                        subscriberEmail: "",
                        success: sengridEmailResponse.data.success,
                    });
                }
            } catch (error) {
                setEmailForm({
                    loading: false,
                    error: error.message,
                    subscriberEmail: "",
                });
            }
        } else {
            setEmailForm({
                errorMessage: "Please put a valid email.",
            });
        }
    };

    const successModal = emailForm.success && (
        <SuccessModal
            open={emailForm.success}
            message="Your email was added to our list, coming soon we are notified you!"
            successAction={() => {
                setEmailForm({ success: false });
                window.location.href = process.env.REACT_APP_SURVIVALBOXES_URL;
            }}
        />
    );

    return (
        <Modal className="sendgrid-modal" open={open} size="small">
            {successModal}
            <Header as="h2" textAlign="center">
                Currently out of stock!
            </Header>
            <Modal.Content>
                <p style={{ fontSize: 20, textAlign: "justify" }}>
                    Register your email address below to receive an email as soon as this become available again.
                </p>
                <br />
                <br />
                <CustomInput
                    label="Email address"
                    onChangeAction={(event, { value }) => setEmailForm({ subscriberEmail: value, errorMessage: false })}
                    value={emailForm.subscriberEmail}
                    noMargin={true}
                    errorMessage={emailForm.errorMessage}
                />
                <br />
                <br />
                <p style={{ fontSize: 20, textAlign: "justify" }}>
                    !We will ready to give much more adventure in this new year!{" "}
                </p>
            </Modal.Content>
            <Modal.Actions>
                <div className="action-buttons-container">
                    <div className="action-buttons-content">
                        <DefaultButton
                            title="Send"
                            buttonAction={postSengridEmail}
                            loading={emailForm.loading}
                            disabled={emailForm.loading}
                        />
                    </div>
                </div>
            </Modal.Actions>
        </Modal>
    );
}
