import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button } from "semantic-ui-react";
import "../../scss/components/_button.scss";
import {
    STEP_FIVE,
    STEP_THREE,
    STEP_TWO,
    STEP_ONE,
    USER_INFORMATION,
    SUBSCRIPTION_SUMMARY,
} from "../../navigation/Routes";

export function BackButton(props) {
    const { disabled, tabIndex } = props;
    const history = useHistory();
    const location = useLocation();
    const subscriptions = useSelector((state) => state.subscriptions);

    const goBack = () => {
        if (location.pathname === STEP_FIVE) {
            if (subscriptions.subscriptionBox === "outdoorAdventures") {
                history.push(STEP_TWO);
            } else {
                history.push(STEP_THREE);
            }
        } else if (location.pathname === USER_INFORMATION) {
            history.goBack();
        } else if (location.pathname === STEP_THREE) {
            history.push(STEP_TWO);
        } else if (location.pathname === STEP_TWO) {
            history.push(STEP_ONE);
        } else if (location.pathname === "/account/sign-up") {
            history.push(STEP_THREE);
        } else {
            if (typeof tabIndex === "number") {
                history.push(SUBSCRIPTION_SUMMARY, { tabIndex: tabIndex });
            } else {
                history.goBack();
            }
        }
    };

    return (
        <Button className="back-button" fluid onClick={goBack} disabled={disabled}>
            Back
        </Button>
    );
}
