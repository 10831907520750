import React, { useState } from "react";
import { Image, Card, Header } from "semantic-ui-react";
import { OutlineButton } from "../../components";
import moment from "moment";
import * as CONSTANTS from "../../constants/Global";
import { ImagePreviewModal } from "../../components";
import EmergencyBoxImage from "../../images/survivalImages/emergency-box.svg";
import OutdoorBoxImage from "../../images/survivalImages/outdoor-adventure-box.svg";
import { addOnsImages } from "../../services/UpgradeBoxService";
import AddOnImage from "../../images/survivalImages/add-on.svg";
import "../../scss/components/_cards.scss";

export function MonthBoxCard(props) {
    const { box, goToBoxDetail, type, editAddOn } = props;
    const { status, add } = box;
    const [showImagePreview, setShowImagePreview] = useState(false);

    let imageClasses = "image";
    let contentClasses = "month-box-card-container";
    let statusInfo = null;
    let editButton = null;
    let boxImage = null;
    let extraContent = null;

    let cardInfo = {
        name: "",
        deliverDate: "",
        quantity: "",
    };

    const openImagePreview = () => {
        setShowImagePreview(true);
    };

    const closeImagePreview = () => {
        setShowImagePreview(false);
    };

    if (type === CONSTANTS.BOX_TYPES.SUBSCRIPTION) {
        cardInfo.name = box.boxInfo.price.metadata.tradename;
        cardInfo.deliverDate = `Delivers in ${moment(box.deliverDate).utc().format("MMM DD YYYY")}`;

        if (cardInfo.name.includes("Emergency")) {
            boxImage = EmergencyBoxImage;
        } else {
            boxImage = OutdoorBoxImage;
        }
        if (status === CONSTANTS.BOX_STATUS.UNSHIPPED) {
            statusInfo = <Card.Description style={{ color: "#ad7733" }}>Waiting Shipping</Card.Description>;
        } else {
            if (status === CONSTANTS.BOX_STATUS.CANCELLED) {
                statusInfo = <Card.Description style={{ color: "blue" }}>Box Cancelled</Card.Description>;
            }
        }
    } else {
        const tradename = box.price.metadata.tradename;
        boxImage = AddOnImage;
        if (tradename !== "Survival Garden") {
            boxImage = addOnsImages.find((image) => box && image.tradename === box.price.metadata.tradename).image;
        }

        cardInfo.name = box.price.metadata.tradename;
        cardInfo.deliverDate = `Quantity: ${box.quantity}`;
        contentClasses += " padding-0";
        if (add) {
            statusInfo = null;
            editButton = (
                <div>
                    <OutlineButton title="Get" buttonAction={() => editAddOn("add")} />
                </div>
            );
        } else {
            editButton = (
                <div>
                    <OutlineButton title="Edit" buttonAction={() => editAddOn(box)} />
                </div>
            );
        }

        extraContent = (
            <Card.Content extra>
                <Card.Description>
                    <strong>Status: </strong>
                    {box.pending ? "Pending" : "Paid"}
                </Card.Description>
                <Card.Description>
                    <strong>Deliver and Charge: </strong>
                    {box.recurring ? "Every month" : "One time"}
                </Card.Description>
            </Card.Content>
        );
    }

    const imagePreview = showImagePreview && (
        <ImagePreviewModal open={showImagePreview} photo={boxImage} closeFunction={closeImagePreview} />
    );

    return (
        <Card className="month-box-card" fluid>
            {imagePreview}
            {type === CONSTANTS.BOX_TYPES.SUBSCRIPTION && (
                <div className="card-overlay" onClick={() => goToBoxDetail(box)} />
            )}
            <Card.Content>
                <div className={contentClasses}>
                    <div
                        className={imageClasses}
                        style={{ cursor: type === CONSTANTS.BOX_TYPES.SUBSCRIPTION ? "" : "pointer" }}
                        onClick={type === CONSTANTS.BOX_TYPES.SUBSCRIPTION ? null : openImagePreview}
                    >
                        <Image src={boxImage} size="medium" />
                    </div>
                    <div className="info">
                        <Header as="h3">{cardInfo.name}</Header>
                        <Card.Meta>{cardInfo.deliverDate}</Card.Meta>
                        {statusInfo}
                    </div>
                    {editButton}
                </div>
            </Card.Content>
            {!box.add && extraContent}
        </Card>
    );
}
