import React from "react";
import { Card } from "semantic-ui-react";
import { UserSubscriptionCard } from "../components";

export const DONATION_CONFIRMATION =
    "We'll really sorry your loss, but you can still give a little love to another people. You would like to donate your remaining boxes?";
export const DISCOUNT_10_OFF =
    "We'll really appreciate your confidence, in your next billing you will receive a 10% off to continue enjoy your Survivalbox.";
export const SKIP_NOTE = "Are you sure that do you want to skip your note this month?";
export const SKIP_PHOTOS = "Are you sure that do you want to skip your photos this month?";
export const NO_UPLOAD_MORE_PHOTOS = "Are you sure that you don't want to upload more photos this month?";
export const CANCEL_SUBSCRIPTION =
    "Are you sure that you want to cancel your subscription (This action don't have undo)?";
export const RECIEVE_MARKETING_EMAIL = "I wish to receive updated news and information about Survival Box.";
export const UPLOD_PHOTOS_CONFIRMATION = (
    <>
        Are you sure that you want upload photos? <br />
        <strong>Check if your images are correct this action not has undo.</strong>
    </>
);
export const PAUSE_SUBSCRIPTION = (pauseTime, nextRenewalDate) =>
    `Are you sure that you want to pause Subscription Renewal for ${pauseTime} days (your next renewal date will be ${nextRenewalDate})?`;
export const LAST_SUBSCRIPTIONS_LIST = (activeSubscriptions) => {
    const subscriptionsList = (
        <div style={{ marginRight: "10%", marginLeft: "10%" }}>
            <p className="text-left">Currently you have this active subscriptions:</p>
            <Card.Group itemsPerRow={2}>
                {activeSubscriptions &&
                    activeSubscriptions
                        .sort((a, b) => {
                            if (a.description > b.description) {
                                return 1;
                            } else {
                                return -1;
                            }
                        })
                        .map((subscription, index) => {
                            return <UserSubscriptionCard key={index} subscription={subscription} />;
                        })}
            </Card.Group>

            <p className="text-left margin-top">
                You are sure you want to purchase another subscription and continue with this process?
            </p>
        </div>
    );

    return subscriptionsList;
};
