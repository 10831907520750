import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import { Container } from "semantic-ui-react";
import CancelSurveyComponent from "../../components/screenComponents/account/CancelSurveyComponent";
import { cancelSubscription } from "../../store/user/actions";
import * as ROUTES from "../../navigation/Routes";
import * as CONSTANTS from "../../constants/Global";
import "../../scss/pages/account.scss";

function CancelSurvey(props) {
    const { history, location, user, cancelSubscription } = props;
    const { cancelData } = user;
    const subscription = location.state;
    const [cancelReason, setCancelReason] = useState("");
    const [showTextArea, setShowTextArea] = useState(false);
    const [userCancelReason, setUserCancelReason] = useState("");

    useEffect(() => {
        setCancelReason(cancelData.reason);
        setUserCancelReason(cancelData.otherReason);
    }, [cancelData]);

    const onChangeCancelReason = (event) => {
        const value = event.target.id;

        setCancelReason(value);

        if (value === CONSTANTS.CANCEL_SUBSCRIPTIONS_REASONS[5].value) {
            setShowTextArea(true);
        } else {
            setShowTextArea(false);
            setUserCancelReason("");
        }
    };

    const onChangeWriteOption = (event, { value }) => {
        setUserCancelReason(value);
    };

    const goToConfirmCancelation = () => {
        Object.assign(cancelData, {
            reason: cancelReason,
            otherReason: userCancelReason,
        });

        cancelSubscription(cancelData);

        history.push(ROUTES.CONFIRM_CANCELATION, subscription);
    };

    return (
        <Container>
            <CancelSurveyComponent
                history={history}
                subscription={subscription.subscription}
                onChangeCancelReason={onChangeCancelReason}
                cancelReason={cancelReason}
                showTextArea={showTextArea}
                userCancelReason={userCancelReason}
                onChangeWriteOption={onChangeWriteOption}
                goToConfirmCancelation={goToConfirmCancelation}
            />
        </Container>
    );
}

const mapDispatchToProps = (dispatch) => ({
    cancelSubscription: (cancelData) => dispatch(cancelSubscription(cancelData)),
});

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(withRouter(CancelSurvey));
