import React, { useEffect, useReducer } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import { Container } from "semantic-ui-react";
import { EmailConfirmationModal } from "../components";
import SignUpComponent from "../components/screenComponents/SignUpComponent";
import { validateInputFilled, validateInputEmail } from "../services/DataValidationService";
import { signUpEmailAndPassWord, signInWithFacebook, signInWithGoogle } from "../services/AuthService";
import { firebaseService } from "../services/AuthService";
import { removeLocalStorageItem } from "../services/LocalStorageService";
import { setCookie } from "../services/CookieService";
import { getUserStoredData } from "../store/user/actions";
import * as ROUTES from "../navigation/Routes";
import * as LOCALSTORAGE from "../constants/LocalStorage";

import "../scss/pages/get_a_box.scss";
const inputsIds = [
    { name: "fullName", error: "Full Name is requered" },
    { name: "email", error: "Email is required" },
    { name: "password", error: "Password is required" },
    { name: "confirmPassword", error: "Password confirmation required" },
];

function SignUp(props) {
    const { history, getUserStoredData, location, user, subscriptions } = props;
    const { giftType } = subscriptions;
    const { userData } = user;
    const isSubscriptionRoutes = location.state?.accountRoutes === "subscription";

    const [formState, setStateForm] = useReducer((state, newState) => ({ ...state, ...newState }), {
        fullName: "",
        email: "",
        password: "",
        confirmPassword: "",
        fullNameError: false,
        emailError: false,
        passwordError: false,
        confirmPasswordError: false,
        errorMessage: false,
        inputError: false,
        loadingSignUp: false,
        loadingSignInFacebook: false,
        loadingSignInGmail: false,
        disableSignUp: false,
        openConfirmModal: false,
    });

    useEffect(() => {
        if (userData) {
            if (userData.firebase.sign_in_provider === "password" || userData.firebase.sign_in_provider === "custom") {
                if (userData.email_verified) {
                    if (isSubscriptionRoutes) {
                        history.replace(ROUTES.STEP_FIVE);
                    } else {
                        history.replace(ROUTES.SUBSCRIPTION_SUMMARY);
                    }
                } else {
                    setStateForm({
                        openConfirmModal: true,
                        email: userData.email,
                    });
                }
            }
        }
    }, [history, userData, isSubscriptionRoutes]);

    const onChangeWriteOptions = (event, { value }) => {
        const inputId = event.target.id;

        if (inputId === "password") {
            checkPasswordLength(inputId, value);
        } else if (inputId === "confirmPassword") {
            checkSamePassword(value);
        } else {
            setStateForm({
                [inputId]: value,
                [`${inputId}Error`]: false,
                inputError: false,
            });
        }
    };

    const checkPasswordLength = (inputId, value) => {
        setStateForm({
            [inputId]: value,
        });
        if (value.length < 6) {
            setStateForm({
                passwordError: "Your password must be at least 6 characters",
                inputError: true,
            });
        } else {
            setStateForm({
                passwordError: false,
            });
        }
    };

    const checkSamePassword = (value) => {
        setStateForm({
            confirmPassword: value,
        });

        if (formState.password !== value) {
            setStateForm({
                passwordError: "Those passwords didn't match",
                confirmPasswordError: "Those passwords didn't match",
                inputError: true,
            });
        } else {
            setStateForm({
                passwordError: false,
                confirmPasswordError: false,
                inputError: false,
            });
        }
    };

    const prepareSignUpEmailAndPassword = () => {
        const inputsRequiredAreFilled = checkInputsRequiredAreFilled();

        if (inputsRequiredAreFilled && !formState.inputError) {
            signUpUserControl("email");
        }
    };

    const signUpUserControl = (type, userData) => {
        setStateForm({
            disableSignUp: true,
            errorMessage: "",
        });

        switch (type) {
            case "email":
                withEmailAndPasswordAction(userData);
                break;
            case "facebook":
                withFacebookAction();
                break;
            case "google":
                withGoogleAction();
                break;
            default:
                break;
        }
    };

    const actionOpenModal = () => {
        formState.openConfirmModal? setStateForm({ openConfirmModal: false }):setStateForm({ openConfirmModal: true });
        removeLocalStorageItem(LOCALSTORAGE.OTP_SENDED);
    }

    const withEmailAndPasswordAction = async () => {
        setStateForm({
            loadingSignUp: true,
            disableSignUp: true,
        });

        const userData = {
            email: formState.email,
            password: formState.password,
            fullName: formState.fullName,
        };

        try {
            const userSignUp = await signUpEmailAndPassWord(userData, getUserStoredData);

            if (userSignUp) {
                setStateForm({
                    loadingSignUp: false,
                    disableSignUp: false,
                });

                if (userSignUp.data) {
                    if (userSignUp.data.user.emailVerified) {
                        afterLoginRoute();
                    } else {
                        actionOpenModal();
                    }
                } else {
                    handleErrorMessage(userSignUp.message);
                }
            }
        } catch (error) {
            setStateForm({
                loadingSignUp: false,
                disableSignUp: false,
            });
            console.log(error);
            handleErrorMessage(error.message);
        }
    };

    const withFacebookAction = async () => {
        setStateForm({
            loadingSignInFacebook: true,
            disableSignUp: true,
        });

        try {
            const userSignUp = await signInWithFacebook(getUserStoredData);
            console.log("UserSignUpFacebook:" , userSignUp);
            if (userSignUp) {
                setStateForm({
                    loadingSignInFacebook: false,
                    disableSignUp: false,
                });

                if (userSignUp.data) {
                    afterLoginRoute();
                } else {
                    handleErrorMessage(userSignUp.message);
                }
            }
        } catch (error) {
            setStateForm({
                loadingSignInFacebook: false,
                disableSignUp: false,
            });
            handleErrorMessage(error.message);
        }
    };

    const withGoogleAction = async () => {
        setStateForm({
            loadingSignInGmail: true,
            disableSignUp: true,
        });

        try {
            const userSignUp = await signInWithGoogle(getUserStoredData);

            if (userSignUp) {
                setStateForm({
                    loadingSignInGmail: false,
                    disableSignUp: false,
                });

                if (userSignUp.data) {
                    afterLoginRoute();
                } else {
                    handleErrorMessage(userSignUp.message);
                }
            }
        } catch (error) {
            setStateForm({
                loadingSignInGmail: false,
                disableSignUp: false,
            });
            handleErrorMessage(error.message);
        }
    };

    const checkInputsRequiredAreFilled = () => {
        const inputsFilledList = checkIfInputAreFilledAndCreateList();
        const quantityInputsFilled = inputsFilledList.filter((isFilled) => isFilled === true);

        if (quantityInputsFilled.length === inputsIds.length) {
            return true;
        }
        return false;
    };

    const checkIfInputAreFilledAndCreateList = () => {
        const inputsFilledQuantity = [];

        inputsIds.forEach((inputId) => {
            const inputFilled = validateInputFilled(formState[inputId.name]);

            if (inputId.name === "email" && inputFilled) {
                const inputEmail = validateInputEmail(formState[inputId.name]);
                inputsFilledQuantity.push(inputEmail);
                emailErrorController(inputEmail, inputId);
            } else {
                inputsFilledQuantity.push(inputFilled);
            }

            if (!inputFilled) {
                setStateForm({
                    [`${inputId.name}Error`]: inputId.error,
                    inputError: true,
                });
            } else {
                if (inputId.name === "confirmPassword") {
                    checkSamePassword(formState.confirmPassword);
                }
            }
        });

        return inputsFilledQuantity;
    };

    const emailErrorController = (inputEmail, inputId) => {
        if (!inputEmail) {
            setStateForm({
                [`${inputId.name}Error`]: "Please enter a valid email",
                inputError: true,
            });
        } else {
            setStateForm({
                [`${inputId.name}Error`]: false,
                inputError: false,
            });
        }
    };

    const confirmVerification = async () => {
        const idToken = await firebaseService.getIdToken();

        if (idToken) {
            setCookie(LOCALSTORAGE.USER_DATA, idToken);
            removeLocalStorageItem(LOCALSTORAGE.OTP_SENDED);
            getUserStoredData();
        }
    };

    const goToLogin = () => {
        history.replace(ROUTES.LOGIN, {
            accountRoutes: "subscription",
        });
    };

    const afterLoginRoute = () => {
        if (isSubscriptionRoutes || giftType) {
            history.replace(ROUTES.STEP_FIVE);
        } else {
            history.replace(ROUTES.SUBSCRIPTION_SUMMARY);
        }
    };

    const handleErrorMessage = (errorMessage) => {
        setStateForm({
            errorMessage: errorMessage,
        });
    };

    const removeErrorMessage = () => {
        setStateForm({
            errorMessage: "",
        });
    };

    let confirmationModal = null;

    if (formState.openConfirmModal) {
        confirmationModal = (
            <EmailConfirmationModal
                open={actionOpenModal}
                email={formState.email}
                confirmAction={confirmVerification}
            />
        );
    }

    return (
        <>
            {confirmationModal}

            <Container>
                <SignUpComponent
                    state={formState}
                    onChangeWriteOptions={onChangeWriteOptions}
                    prepareSignUpEmailAndPassword={prepareSignUpEmailAndPassword}
                    signUpUserControl={signUpUserControl}
                    goToLogin={goToLogin}
                    removeErrorMessage={removeErrorMessage}
                />
            </Container>
        </>
    );
}

const mapDispatchToProps = (dispatch) => ({
    getUserStoredData: () => dispatch(getUserStoredData()),
});

const mapStateToProps = (state) => {
    return {
        subscriptions: state.subscriptions,
        user: state.user,
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(withRouter(SignUp));
