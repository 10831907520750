import React from "react";
import { Header, Card, Image } from "semantic-ui-react";
import "../../scss/components/_cards.scss";

export function BoxCard(props) {
    const { plan, selectedPlan, onSelection } = props;
    const { image, name } = plan;

    let cardClasses = "plan-card";

    if (selectedPlan) {
        cardClasses = "plan-card selected-plan";
    }

    return (
        <Card className={cardClasses}>
            <div className="card-overlay" onClick={() => onSelection(plan)} />
            <Card.Content>
                <div className="card-content">
                    <div className="card-image-section">
                        <Image src={image} size="huge" />
                    </div>
                    <br />
                    <div className="card-data-section">
                        <Header as="h4">{name}</Header>
                    </div>
                </div>
            </Card.Content>
        </Card>
    );
}
