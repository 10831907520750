import React from "react";
import { Card } from "semantic-ui-react";
import { SubHeader, DefaultButton, BackButton } from "../..";
import { PlanCard } from "../..";
import { SURVIVAL_BOXES } from "../../../services/SubscriptionPlanService";

function StepTwoComponent(props) {
    const {
        subscription,
        saveStepFourAndNext,
        onSelectPlan,
        subscriptionPlansOptions,
        subscriptions,
        selectPremium,
    } = props;

    let nextStepDisdabled = true;
    if (subscription.length > 0) {
        nextStepDisdabled = false;
    }

    const productName = SURVIVAL_BOXES.find((box) => box.survivalType === subscriptions.subscriptionBox).name.replace(
        "Box",
        ""
    );

    return (
        <section className="padding-page">
            <SubHeader mainTitle={`Select gear plans ${productName}`} hasDivider={true} />
            <br />
            <br />
            <br />
            <div className="cards-container">
                <Card.Group textAlign="center" centered>
                    {subscriptionPlansOptions.map((plan, index) => {
                        const selectedPlan = subscription[0] && subscription[0].id === plan.id;
                        return (
                            <PlanCard
                                key={index}
                                plan={plan}
                                onSelection={onSelectPlan}
                                selectedPlan={selectedPlan}
                                selectPremium={selectPremium}
                            />
                        );
                    })}
                </Card.Group>
            </div>
            <br />
            <br />
            <br />
            <div className="margin-top padding-bottom flex-center flex-column button-container-padding">
                <DefaultButton title="Next Step" disabled={nextStepDisdabled} buttonAction={saveStepFourAndNext} />
                <br />
                <BackButton title="back" />
            </div>
        </section>
    );
}

export default StepTwoComponent;
