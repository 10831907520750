import React from "react";
import { Image, Modal, Header } from "semantic-ui-react";
import { DefaultButton } from "../../components";
import ConfirmImage from "../../images/survivalImages/confirm.svg";
import "../../scss/components/_modal.scss";

export function ConfirmationModal(props) {
    const { message, open, closeAction, confirmAction, composedMessage } = props;

    return (
        <Modal className="confirm-modal" closeIcon open={open} size="small" onClose={closeAction}>
            <Modal.Content>
                <Image src={ConfirmImage} size="small" centered />
                <Header as="h2" textAlign="center">
                    Confirmation
                </Header>
                {composedMessage ? message : <p>{message}</p>}
            </Modal.Content>
            <Modal.Actions>
                <div className="action-buttons-container">
                    <div className="action-buttons-content">
                        <DefaultButton title="Cancel" buttonAction={closeAction} inverted={true} />
                    </div>
                    <div className="action-buttons-content">
                        <DefaultButton title="Accept" buttonAction={confirmAction} />
                    </div>
                </div>
                <br />
            </Modal.Actions>
        </Modal>
    );
}
