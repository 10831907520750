export const DISCOUNT_AMOUNT = 10;
export const ENV_DEVELOPMENT = "development";
export const ENV_PRODUCTION = "production";
export const IMPORTANT_INFORMATION_TEXT = [
    "Your billing days are each 1st of month (If you buy a Survivalbox close to end of month, maybe you will receive two boxes same month).",
    "The following boxes will be shipped 18th each month.",
];
export const BENEFITS_TEXT = [
    "You will get a box of GrandBox monthly with 43% discount + FREE shipping ($30/month by box) with your 12 month subscription. ",
    "Every month a different theme for your GrandBox.",
    "Personalize your box adding photos and a note.",
];
export const MONTHS = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];
export const SUBSCRIPTION_STATUS = {
    ACTIVE: "ACTIVE",
    CANCELLED: "CANCELLED",
    INCOMPLETE: "INCOMPLETE",
    PAUSED: "PAUSED",
    UNPAID: "UNPAID",
};
export const PLAN_TYPES = {
    BASIC: "basic",
    PREMIUM: "premium",
};
export const BOX_TYPES = {
    ADDON: "addOn",
    SUBSCRIPTION: "subscription",
    GIFT: "gift",
};
export const BOX_STATUS = {
    SHIPPED: "SHIPPED",
    UNSHIPPED: "UNSHIPPED",
    CANCELLED: "CANCELLED",
    DONATED: "DONATED",
};
export const CANCEL_TYPES = {
    DONATE: "donate",
    CANCEL: "cancel",
};
export const CANCEL_SUBSCRIPTIONS_REASONS = [
    {
        text: "It's to expensive",
        value: "EXPENSIVE",
    },
    {
        text: "I'm using another service",
        value: "OTHER_SERVICE",
    },
    {
        text: "I am having technical problems",
        value: "TECHNICAL_PROBLEMS",
    },
    {
        text: "I had a bad service",
        value: "BAD_SERVICE",
    },
    {
        text: "It was not the quality we expected",
        value: "LACK_QUALITY",
    },
    {
        text: "Other reason",
        value: "OTHER",
    },
];
export const PAUSE_SUBSCRIPTION_TIME = [
    {
        text: "30 days",
        value: 30,
    },
    {
        text: "60 days",
        value: 60,
    },
    {
        text: "90 days",
        value: 90,
    },
];
export const CANCEL_SUBSCRIPTIONS_OPTIONS = {
    DISCOUNT: "DISCOUNT",
    REFOUND: "REFUND",
    DONATE: "DONATE",
    FINISH: "FINISH",
    PAUSE: "PAUSE",
};
export const SUBSCRIPTIONS_PLANS = {
    TWELVE_MONTH: "12 Month Prepay",
    SIX_MONTH: "6 Month Prepay",
    THREE_MONTH: "3 Month Prepay",
    MONTH_TO_MONTH: "1 Month to Month",
};
export const INITAL_PARAMS = {
    RESET: "mode=resetPassword",
    SUBSCRIBE: "mode=subscribe",
    MIGRATION: "mode=migration",
    EMAIL_VALIDATION: "mode=verifyEmail",
    COMMUNITY: "mode=community",
};
export const INVOICE_STATUS = {
    PAID: "paid",
    UNPAID: "invoice_unpaid",
};
export const SURVIVAL_GARDEN_FAQS = [
    {
        quesiton: "quesitonOne",
        answer: "answerOne",
    },
    {
        quesiton: "quesitonTwo",
        answer: "answerTwo",
    },
    {
        quesiton: "quesitonThree",
        answer: "answerThree",
    },
    {
        quesiton: "quesitonFour",
        answer: "answerFour",
    },
    {
        quesiton: "quesitonFive",
        answer: "answerFive",
    },
];
