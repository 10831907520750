import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import { Container } from "semantic-ui-react";
import { Loader } from "../../components";
import StepTwoComponent from "../../components/screenComponents/subscribe/StepTwoComponent";
import { getPlansOptionsWithImage } from "../../services/SubscriptionPlanService";
import { saveStepFour } from "../../store/subscriptions/actions";
import * as ROUTES from "../../navigation/Routes";
import "../../scss/pages/get_a_box.scss";

function StepTwo(props) {
    const { history, location, subscriptions, plans, saveStepFour, shipping } = props;
    const selectPremium = location.state && location.state.selectPremium;

    const [subscription, setSubscription] = useState([]);
    const [subscriptionPlansOptions, setSubscriptionPlansOptions] = useState([]);
    const [showPlans, setShowPlans] = useState(false);

    const recreateOptions = useCallback((data) => {
        const plansOptionsWithImage = getPlansOptionsWithImage(data);

        if (plansOptionsWithImage.length > 0) {
            setSubscriptionPlansOptions(plansOptionsWithImage);
            setShowPlans(true);
        }
    }, []);

    useEffect(() => {
        if (subscriptions.subscriptionBox) {
            const selectedPrices = plans.find((plan) => plan.metadata.survivalType === subscriptions.subscriptionBox);
            if (selectedPrices) {
                recreateOptions(selectedPrices.prices);
                setSubscription(subscriptions.subscriptionPlan);
            }
        } else {
            history.replace(ROUTES.STEP_ONE);
        }
    }, [history, plans, subscriptions.subscriptionBox, recreateOptions, subscriptions.subscriptionPlan]);

    const onSelectPlan = (selection) => {
        if (subscription.length > 0) {
            subscription.pop();
        }

        setSubscription((old) => [
            ...old,
            {
                ...selection,
                autoRenewing: true,
            },
        ]);
    };

    const saveStepFourAndNext = () => {
        const shippingPrice = {
            ...shipping[0].prices[0],
            autoRenewing: true,
            quantity: 1,
        };

        if (subscription !== "") {
            saveStepFour({
                subscriptionPlan: subscription,
                shippingPrice: shippingPrice,
                oneTimeGift: false,
            });

            history.push(ROUTES.STEP_THREE);
        }
    };

    let stepTwoComponent = null;

    if (showPlans) {
        stepTwoComponent = (
            <StepTwoComponent
                subscriptionPlansOptions={subscriptionPlansOptions}
                subscription={subscription}
                history={history}
                subscriptions={subscriptions}
                saveStepFourAndNext={saveStepFourAndNext}
                onSelectPlan={onSelectPlan}
                selectPremium={selectPremium}
            />
        );
    } else {
        stepTwoComponent = <Loader />;
    }

    return (
        <>
            <Container>{stepTwoComponent}</Container>
        </>
    );
}

const mapDispatchToProps = (dispatch) => ({
    saveStepFour: (stepFour) => dispatch(saveStepFour(stepFour)),
});

const mapStateToProps = (state) => {
    return {
        subscriptions: state.subscriptions,
        plans: state.business.plans,
        shipping: state.business.shipping,
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(withRouter(StepTwo));
