import React from "react";
import { Button, Card, Header, Icon, Divider } from "semantic-ui-react";
import { CustomCheckbox } from "../../components";
import { BOX_TYPES } from "../../constants/Global";
import "../../scss/components/_cards.scss";

export function OrderCard(props) {
    const { plan, removeAddOn, removeSubscription, setAutoRenewing } = props;
    const { name, price, unitPrice, detail, deliveries, autoRenewing, packages, availableDate, status, type } = plan;

    let priceFixed = `$${unitPrice.toFixed(2)}`;

    let priceString = null;
    let removeAction = removeAddOn;

    if (type === BOX_TYPES.SUBSCRIPTION) {
        removeAction = removeSubscription;
        const monthlyPrice = unitPrice / packages;
        priceString = `$${monthlyPrice.toFixed(2)} / month`;
    } else {
        priceFixed = `$${price.toFixed(2)}`;
    }

    if (autoRenewing) {
        priceString = `$${unitPrice.toFixed(2)} x ${packages} / month`;
    } else {
        priceString = `$${unitPrice.toFixed(2)} x ${packages} / one time`;
    }

    if (status === "future") {
        priceString = `${priceFixed} (${availableDate})`;
    }

    const hasAutoRenewing = typeof autoRenewing === "boolean";

    return (
        <Card className="order-card" fluid>
            <Card.Content>
                <div className="order-card-header">
                    {hasAutoRenewing && (
                        <Button circular icon={<Icon name="close" size="large" />} onClick={() => removeAction(plan)} />
                    )}
                    <Header as="h3">{name}</Header>
                    <span>{priceFixed}</span>
                </div>
                <Card.Description>{detail}</Card.Description>
                <Card.Description>{priceString}</Card.Description>
                <Card.Meta>{deliveries}</Card.Meta>
                {hasAutoRenewing && (
                    <CustomCheckbox
                        label="Auto-Renewing"
                        value={autoRenewing}
                        onChangeAction={() => setAutoRenewing(plan)}
                    />
                )}
            </Card.Content>
            <Divider />
        </Card>
    );
}
