import React, { useEffect, useState } from "react";
import { Card, Modal, Header } from "semantic-ui-react";
import moment from "moment";
import { DefaultButton, AddOnCard, ErrorModal, AddOnsConfirmationModal } from "../../components";
import "../../scss/components/_modal.scss";

export function AddOnModifyModal(props) {
    const {
        open,
        closeAction,
        addOnToModify,
        addOnsUpdater,
        loadingGettingAddOns,
        setGetAddOnError,
        getAddOnError,
    } = props;
    const [addOnItems, setAddOnItems] = useState([]);
    const [updateType, setUpdateType] = useState("");
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

    let headerText = "";
    let buttonActionTitle = "";

    useEffect(() => {
        let addOnInfo = [];
        if (addOnToModify.hasOwnProperty("price")) {
            addOnInfo = [
                {
                    ...addOnToModify.price,
                    quantity: addOnToModify.quantity,
                    autoRenewing: addOnToModify.autoRenewing ? addOnToModify.autoRenewing : true,
                },
            ];
            setUpdateType("update");
        } else {
            addOnInfo = addOnToModify.map((addOn, index) => {
                return {
                    ...addOn.price,
                    quantity: addOn.quantity,
                    autoRenewing: addOn.autoRenewing ? addOn.autoRenewing : true,
                };
            });
            setUpdateType("get");
        }

        setAddOnItems(addOnInfo);
    }, [addOnToModify]);

    const manageAddOnQuantity = (event, { value }) => {
        const composedValue = value.split("&&");
        const priceId = composedValue[0];

        let price = addOnItems.find((addOn) => addOn.id === priceId);

        if (value) {
            price.quantity = Number(composedValue[1]);
        }

        const newAddOnItems = addOnItems.map((addOn) => {
            if (addOn.id === priceId) {
                return price;
            } else {
                return addOn;
            }
        });

        setAddOnItems(newAddOnItems);
    };

    const manageAddOnAutoRenewing = (event, { value }) => {
        const priceId = event.target.id;
        const checked = event.target.checked;
        let price = addOnItems.find((addOn) => addOn.id === priceId);

        if (typeof checked === "boolean") {
            price.autoRenewing = checked;
        }

        const newAddOnItems = addOnItems.map((addOn) => {
            if (addOn.id === priceId) {
                return price;
            } else {
                return addOn;
            }
        });

        setAddOnItems(newAddOnItems);
    };

    const handleConfirmationModal = () => {
        setOpenConfirmationModal(!openConfirmationModal);
    };

    const handleAddOnsPost = () => {
        const addOnsToSend = addOnItems
            .map((item) => {
                return {
                    id: item.id,
                    quantity: item.quantity,
                    recurring: item.autoRenewing,
                    pending: true,
                };
            })
            .filter((item) => item.quantity > 0);

        addOnsUpdater(updateType, addOnsToSend);
        handleConfirmationModal();
    };

    if (addOnToModify.hasOwnProperty("price")) {
        headerText = "Modify AddOn";
        buttonActionTitle = "Modify";
    } else {
        headerText = "Get AddOns";
        buttonActionTitle = "Get";
    }

    let addOnCardSection = null;

    if (addOnItems.length > 0)
        addOnCardSection = addOnItems.map((addOn, index) => {
            return (
                <AddOnCard
                    key={index}
                    onChanngeAddOn={manageAddOnQuantity}
                    onChangeAutoRenewing={manageAddOnAutoRenewing}
                    addOn={addOn}
                    showPriceSection={true}
                />
            );
        });

    let errorModal = null;

    if (getAddOnError.length > 0) {
        errorModal = (
            <ErrorModal open={getAddOnError.length > 0} message={getAddOnError} closeFunction={setGetAddOnError("")} />
        );
    }

    let confirmationModal = null;

    if (openConfirmationModal) {
        const currentDay = Number(moment().format("DD"));

        let message = "";

        if (currentDay < 11) {
            message = "current";
        } else {
            message = "next";
        }

        confirmationModal = (
            <AddOnsConfirmationModal
                open={openConfirmationModal}
                message={message}
                confirmAction={handleAddOnsPost}
                closeAction={handleConfirmationModal}
            />
        );
    }

    const enableButton = addOnItems.some((item) => item.quantity > 0);
    const sumValuesBy = "unit_amount";
    const addOnsSum = addOnItems.reduce((a, b) => a + ((b[sumValuesBy] / 100) * b.quantity || 0), 0);

    return (
        <Modal
            className="edit-addon-modal"
            closeIcon
            open={open}
            size="small"
            onClose={!loadingGettingAddOns ? closeAction : null}
        >
            {errorModal}
            {confirmationModal}
            <Header as="h2" textAlign="center">
                {`${headerText} | Total: $${addOnsSum}`}
            </Header>
            <Modal.Content scrolling>
                <Card.Group>{addOnCardSection}</Card.Group>
            </Modal.Content>
            <Modal.Actions>
                <div className="action-buttons-container">
                    <div className="action-buttons-content">
                        <DefaultButton
                            title="Cancel"
                            inverted={true}
                            buttonAction={closeAction}
                            disabled={loadingGettingAddOns}
                        />
                    </div>
                    <div className="action-buttons-content">
                        <DefaultButton
                            title={buttonActionTitle}
                            buttonAction={handleConfirmationModal}
                            loading={loadingGettingAddOns}
                            disabled={!enableButton}
                        />
                    </div>
                </div>
            </Modal.Actions>
        </Modal>
    );
}
