import React from "react";
import { BoxSubHeader, DefaultButton, CustomInput, SearchLocationInput, BackButton, Map } from "../components";

export function ShippingAddressForm(props) {
    const {
        subscriberString,
        state,
        onChangeWriteOptions,
        onSelectedPlace,
        showBackButton,
        submitAction,
        submitButtonTitle,
        subHeaderType,
        boxName,
        boxSection,
        history,
        writedAddressManually,
        type,
    } = props;

    let backButton = null;

    if (showBackButton) {
        backButton = <BackButton title="back" />;
    }

    let addressInMap = null;

    if (state.showAddressMap) {
        addressInMap = <Map geometry={state.localGeometry} />;
    }

    let subHeader = null;

    switch (subHeaderType) {
        case "box":
            subHeader = <BoxSubHeader boxName={boxName} boxSection={boxSection} history={history} />;
            break;
        default:
            break;
    }

    let personalizedLabel = "";
    if (subscriberString) {
        personalizedLabel = `${subscriberString}'s`;
    }

    const sectionClasses = type === "subscription" ? "" : "padding-page";
    const containerClasses = type === "subscription" ? "input-container" : "input-container container-padding";

    return (
        <section className={sectionClasses}>
            {subHeader}
            <div className={containerClasses}>
                <CustomInput
                    id="localFirstName"
                    placeholder={`${personalizedLabel} First Name`}
                    onChangeAction={onChangeWriteOptions}
                    value={state.localFirstName}
                    errorMessage={state.localFirstNameError}
                    type="text"
                    label={`${personalizedLabel} First Name`}
                />
                <CustomInput
                    id="localLastName"
                    placeholder={`${personalizedLabel} Last Name`}
                    onChangeAction={onChangeWriteOptions}
                    value={state.localLastName}
                    errorMessage={state.localLastNameError}
                    type="text"
                    label={`${personalizedLabel} Last Name`}
                />
                <CustomInput
                    id="localPhoneNumber"
                    placeholder={`${personalizedLabel} Phone Number (Optional)`}
                    onChangeAction={onChangeWriteOptions}
                    value={state.localPhoneNumber}
                    errorMessage={state.localPhoneNumberError}
                    type="text"
                    label={`${personalizedLabel} Phone Number`}
                    isPhoneNumber={true}
                />
                <p className="color-cta" style={{ margin: "0 0 15px 5px" }}>
                    {state.writedAddress
                        ? " Write your address manually, please fill all no optional fields."
                        : " Write your address and select a place of the list, try to start with your Postal Code"}

                    <br />
                    <a
                        href="# "
                        tabIndex="0"
                        className={`hand-address-link ${state.writedAddress ? "color-black" : ""}`}
                        onClick={writedAddressManually}
                    >
                        {state.writedAddress ? "Autocomplete address?" : "Your address is not in the list?"}
                    </a>
                </p>
                {state.writedAddress ? (
                    <CustomInput
                        id="localDeliveryPlace"
                        placeholder="White your address"
                        onChangeAction={onChangeWriteOptions}
                        value={state.localDeliveryPlace}
                        errorMessage={state.localDeliveryPlaceError}
                        type="text"
                        label={`${personalizedLabel} Street Address`}
                    />
                ) : (
                    <SearchLocationInput
                        id="localDeliveryPlace"
                        placeholder="Delivery Place"
                        onSelectedPlace={onSelectedPlace}
                        value={state.localDeliveryPlace}
                        errorMessage={state.localDeliveryPlaceError}
                        type="text"
                        label={`${personalizedLabel} Delivery Place`}
                    />
                )}

                <div className="multi-input-line">
                    <div className="input-shorter">
                        <CustomInput
                            id="localCity"
                            placeholder="City"
                            onChangeAction={onChangeWriteOptions}
                            value={state.localCity}
                            errorMessage={state.writedAddress ? state.localCityError : null}
                            type="text"
                            label="City"
                        />
                    </div>
                    <div className="input-longer">
                        <CustomInput
                            id="localState"
                            search={true}
                            onChangeAction={onChangeWriteOptions}
                            errorMessage={state.writedAddress ? state.localStateError : null}
                            placeholder="Select State"
                            value={state.localState}
                            label="State"
                        />
                    </div>
                </div>
                <div className="multi-input-line">
                    <div className="input-half">
                        <CustomInput
                            id="localApt"
                            placeholder="Apt (optional)"
                            onChangeAction={onChangeWriteOptions}
                            value={state.localApt}
                            type="text"
                            label="Apt"
                        />
                    </div>
                    <div className="input-half">
                        <CustomInput
                            id="localPostalCode"
                            placeholder="Postal Code"
                            onChangeAction={onChangeWriteOptions}
                            value={state.localPostalCode}
                            errorMessage={state.writedAddress ? state.localPostalCodeError : null}
                            type="text"
                            label="Postal Code"
                        />
                    </div>
                </div>
                <CustomInput
                    id="localCountry"
                    onChangeAction={null}
                    placeholder="Shipping Country"
                    label="Country"
                    type="text"
                    value={state.localCountry}
                />
                {addressInMap}
            </div>

            <div className="margin-top padding-bottom flex-center flex-column button-container-padding">
                <DefaultButton title={submitButtonTitle} loading={state.loadingUpdate} buttonAction={submitAction} />
                {type !== "subscription" && (
                    <>
                        <br />
                        {backButton}
                    </>
                )}
            </div>
        </section>
    );
}
