import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import { Container, Tab } from "semantic-ui-react";
import moment from "moment";
import { Header, Loader, ErrorModal, PauseSubscriptionModal, MoveLevelModal } from "../../components";
import ManageSubscriptionComponent from "../../components/screenComponents/account/ManageSubscriptionComponent";
import NoSubscriptionsComponent from "../../components/screenComponents/account/NoSubscriptionsComponent";
import UserInformation from "../../screens/account/UserInformation";
import {
    getUserSubscriptions,
    getMoreAddOns,
    updateAddOns,
    getUserProfile,
    getTribeCommunityToken,
} from "../../services/SetupService";
import { setLocalStorageItem } from "../../services/LocalStorageService";
import { prepareSubmitAddOnsData } from "../../services/SubscriptionPlanService";
import { updateSubscriptionsStore } from "../../services/UpdateReduxStoreService";
import { saveUserSubscriptions, saveFirebaseData } from "../../store/user/actions";
import { resetSubscriptionStore } from "../../store/subscriptions/actions";
import * as ROUTES from "../../navigation/Routes";
import * as LOCALSTORAGE from "../../constants/LocalStorage";
import * as LOADINGS from "../../constants/Loadings";
import * as ERRORS from "../../constants/Errors";
import * as CONSTANTS from "../../constants/Global";
import "../../scss/pages/account.scss";

function ManageSubscription(props) {
    const {
        history,
        user,
        saveUserSubscriptions,
        resetSubscriptionStore,
        addOnPrices,
        saveFirebaseData,
        location,
        plans,
    } = props;
    const { subscriptions, userData, userFirebaseData } = user;
    const outsideTabIndex = location.state?.tabIndex;

    const [loadingSubscriptions, setLoadingSubscription] = useState(false);
    const [subscriptionOptions, setSubscriptionOptions] = useState(null);
    const [selectedSubscription, setSelectedSubscription] = useState(null);
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [showActiveSubscriptions, setShowActiveSubscriptions] = useState(true);
    const [showChat, setShowChat] = useState(null);
    const [loadingGettingAddOns, setLoadingGettingAddOns] = useState(false);
    const [openAddOnModifyModal, setOpenAddOnModifyModal] = useState(false);
    const [getAddOnError, setGetAddOnError] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [tabIndex, setTabIndex] = useState(1);
    const [usingTabs, setUsingTabs] = useState(null);
    const [openPauseSubscriptionModal, setOpenPauseSubscriptionModal] = useState(false);
    const [openMoveLevelModal, setOpenPauseMoveLevelModal] = useState(false);
    //  const [tribeCommunityResponse, setTribeCommunityResponse] = useState(null);

    useEffect(() => {
        if (typeof outsideTabIndex === "number") {
            if (outsideTabIndex !== tabIndex) {
                if (!usingTabs) {
                    setTabIndex(outsideTabIndex);
                    setUsingTabs(false);
                }
            }
        }
    }, [outsideTabIndex, tabIndex, usingTabs]);

    useEffect(() => {
        const getUserProfileFirebase = async () => {
            const firebaseProfile = await getUserProfile(userData.user_id);

            console.log(firebaseProfile);

            if (typeof firebaseProfile === "object") {
                saveFirebaseData(firebaseProfile);
            }
        };

        if (!userFirebaseData && userData && userData.user_id) getUserProfileFirebase();
    }, [userFirebaseData, userData, saveFirebaseData]);

    useEffect(() => {
        const getSubscriptionsList = async () => {
            setLoadingSubscription(true);
            const userSubscriptions = await getUserSubscriptions(userData.user_id);

            if (userSubscriptions) {
                if (typeof userSubscriptions === "string") {
                    setOpenErrorModal(true);
                    setLoadingSubscription(false);
                    setErrorMessage(userSubscriptions);
                } else {
                    setLoadingSubscription(false);
                    if (userSubscriptions.length > 0) {
                        saveUserSubscriptions(userSubscriptions);
                    } else {
                        saveUserSubscriptions([]);
                    }
                }
            } else {
                setOpenErrorModal(true);
                setErrorMessage(ERRORS.ERROR_LOADING_USER_SUBSCRIPTIONS);
                setLoadingSubscription(false);
            }
        };

        if (userData && userData.user_id) {
            getSubscriptionsList();
        }
    }, [saveUserSubscriptions, userData]);

    useEffect(() => {
        const getSubscriptionsOptions = () => {
            if (subscriptions && subscriptions.length > 0) {
                const subscriptionOptions = subscriptions
                    .map((subscription, index) => {
                        const removingString = subscription.description.substring(
                            subscription.description.indexOf("Box")
                        );
                        const reciever = `${subscription.shippingAddress.firstName} ${subscription.shippingAddress.lastName}`;
                        const description = subscription.description.replace(removingString, "");

                        return {
                            key: index,
                            value: subscription.id,
                            text: `${description} | ${reciever}`,
                            status: subscription.status,
                        };
                    })
                    .filter((subscription) => {
                        if (showActiveSubscriptions) {
                            return [
                                CONSTANTS.SUBSCRIPTION_STATUS.ACTIVE,
                                CONSTANTS.SUBSCRIPTION_STATUS.PAUSED,
                            ].includes(subscription.status);
                        } else {
                            return subscription;
                        }
                    });

                setSubscriptionOptions(subscriptionOptions);
            }
        };

        getSubscriptionsOptions();
    }, [subscriptions, showActiveSubscriptions]);

    useEffect(() => {
        if (subscriptionOptions && subscriptionOptions.length > 0) {
            const getCurrentSubscription = () => {
                const lastSubscription = Math.max.apply(
                    Math,
                    subscriptions.map((subscription) => {
                        const createdAt = Number(moment(subscription.createdAt).format("x"));
                        return createdAt;
                    })
                );

                const newSelectedSubscription = subscriptions.find(
                    (subscription) => Number(moment(subscription.createdAt).format("x")) === lastSubscription
                );

                if (newSelectedSubscription) {
                    setLocalStorageItem(LOCALSTORAGE.SELECTED_SUBSCRIPTION, newSelectedSubscription);
                    setSelectedSubscription(newSelectedSubscription);
                }
            };

            if (subscriptionOptions.length > 0) {
                getCurrentSubscription();
            }
        }
    }, [subscriptionOptions, subscriptions]);

    const handleShowActiveSubscriptions = () => {
        setShowActiveSubscriptions(!showActiveSubscriptions);

        const subscriptionOptions = subscriptions
            .map((subscription, index) => {
                const removingString = subscription.description.substring(subscription.description.indexOf("Box"));
                const reciever = `${subscription.shippingAddress.firstName} ${subscription.shippingAddress.lastName}`;
                const description = subscription.description.replace(removingString, "");

                return {
                    key: index,
                    value: subscription.id,
                    text: `${description} | ${reciever}`,
                    status: subscription.status,
                };
            })
            .filter((subscription) => {
                if (showActiveSubscriptions) {
                    return subscription;
                } else {
                    if (subscriptions.length === 1) {
                        return subscription;
                    } else {
                        return [CONSTANTS.SUBSCRIPTION_STATUS.ACTIVE, CONSTANTS.SUBSCRIPTION_STATUS.PAUSED].includes(
                            subscription.status
                        );
                    }
                }
            });

        setSubscriptionOptions(subscriptionOptions);
    };

    const goToBoxDetail = (box) => {
        history.push({
            pathname: ROUTES.BOX_DESCRIPTION,
            search: `${box.subscriptionId}&&${box.id}`,
        });
    };

    const handleSelectedSubscription = (data) => {
        const selectedSubscription = subscriptions.find((subscription) => subscription.id === data.value);
        setSelectedSubscription(selectedSubscription);
        setLocalStorageItem(LOCALSTORAGE.SELECTED_SUBSCRIPTION, selectedSubscription);
    };

    const goToCancelSubscription = () => {
        history.push(ROUTES.CANCEL_SURVEY, selectedSubscription);
    };

    const goToSubscriptions = () => {
        resetSubscriptionStore();
        history.replace(ROUTES.STEP_ONE);
    };

    const closeErrorModal = () => {
        setOpenErrorModal(false);
    };

    const showChatToCancelSubs = () => {
        setShowChat(selectedSubscription.id);
    };

    const addOnsUpdater = async (type, addOnsList) => {
        setLoadingGettingAddOns(true);
        const subscriptionId = selectedSubscription.id;
        if (addOnsList.length > 0) {
            const addOnsData = prepareSubmitAddOnsData(addOnsList, subscriptionId);
            let addOnApiResponse = null;

            if (type === "update") {
                addOnApiResponse = await updateAddOns(addOnsData);
            } else {
                addOnApiResponse = await getMoreAddOns(addOnsData);
            }

            if (addOnApiResponse) {
                setLoadingGettingAddOns(false);

                if (typeof addOnApiResponse === "string") {
                    setGetAddOnError(addOnApiResponse);
                } else {
                    const subscriptionUpdated = updateSubscriptionsStore(
                        saveUserSubscriptions,
                        subscriptions,
                        addOnApiResponse
                    );

                    if (subscriptionUpdated) {
                        handleAddOnModifyModal();
                    }
                }
            }
        }
    };

    const handleMoveLevelSubscription = () => {
        setOpenPauseMoveLevelModal(!openMoveLevelModal);
    };

    const moveLevelSubscription = openMoveLevelModal && (
        <MoveLevelModal
            open={openMoveLevelModal}
            closeAction={handleMoveLevelSubscription}
            selectedSubscription={selectedSubscription}
            subscriptionId={selectedSubscription.id}
            plans={plans}
            saveUserSubscriptions={saveUserSubscriptions}
            subscriptions={subscriptions}
        />
    );

    const handleAddOnModifyModal = () => {
        setOpenAddOnModifyModal(!openAddOnModifyModal);
    };

    const handleTabChange = (e, { activeIndex }) => {
        setUsingTabs(true);
        setTabIndex(activeIndex);
    };

    const handlePauseSubscriptionModal = () => {
        setOpenPauseSubscriptionModal(!openPauseSubscriptionModal);
    };

    // const handleTribeCommunity = async () => {
    //     const tribeCommunityResponse = await getTribeCommunityToken();

    //     setTribeCommunityResponse(tribeCommunityResponse);
    // };

    let loader = null;
    let manageSubscription = null;

    const pauseSubscriptionModal = openPauseSubscriptionModal && (
        <PauseSubscriptionModal
            open={openPauseSubscriptionModal}
            closeAction={handlePauseSubscriptionModal}
            subscriptionId={selectedSubscription.id}
            subscriptions={subscriptions}
            saveUserSubscriptions={saveUserSubscriptions}
        />
    );

    if (loadingSubscriptions) {
        loader = <Loader message={LOADINGS.LOADING_SUBSCRIPTIONS} />;
    } else {
        if (selectedSubscription && subscriptionOptions.length > 0) {
            manageSubscription = (
                <ManageSubscriptionComponent
                    goToBoxDetail={goToBoxDetail}
                    selectedSubscription={selectedSubscription}
                    subscriptionOptions={subscriptionOptions}
                    handleSelectedSubscription={handleSelectedSubscription}
                    goToCancelSubscription={goToCancelSubscription}
                    handleShowActiveSubscriptions={handleShowActiveSubscriptions}
                    showActiveSubscriptions={showActiveSubscriptions}
                    showChatToCancelSubs={showChatToCancelSubs}
                    goToSubscriptions={goToSubscriptions}
                    addOnPrices={addOnPrices}
                    addOnsUpdater={addOnsUpdater}
                    moveLevelSubscription={handleMoveLevelSubscription}
                    loadingGettingAddOns={loadingGettingAddOns}
                    openAddOnModifyModal={openAddOnModifyModal}
                    handleAddOnModifyModal={handleAddOnModifyModal}
                    getAddOnError={getAddOnError}
                    setGetAddOnError={setGetAddOnError}
                    userFirebaseData={userFirebaseData}
                    getTribeCommunityToken={getTribeCommunityToken}
                    handlePauseSubscriptionModal={handlePauseSubscriptionModal}
                />
            );
        } else {
            manageSubscription = (
                <NoSubscriptionsComponent
                    goToSubscriptions={goToSubscriptions}
                    message={ERRORS.ERROR_NOT_USER_SUBSCRIPTIONS}
                />
            );
        }
    }

    let erroModal = null;

    if (openErrorModal) {
        erroModal = <ErrorModal open={openErrorModal} closeFunction={closeErrorModal} message={errorMessage} />;
    }

    const panes = [
        {
            menuItem: "MANAGE MY ACCOUNT",
            render: () => (
                <Tab.Pane>
                    <section className="padding-page">
                        <div className="manage-subscription-container">
                            <UserInformation />
                        </div>
                    </section>
                </Tab.Pane>
            ),
        },
        {
            menuItem: "MANAGE SUBSCRIPTION",
            render: () => (
                <Tab.Pane>
                    <section className="padding-page">
                        <div className="manage-subscription-container">{manageSubscription} </div>
                    </section>
                </Tab.Pane>
            ),
        },
        // {
        //     menuItem: "COMMUNITY",
        //     render: () => {
        //         if (userFirebaseData?.tribeCommunity) {
        //             handleTribeCommunity();

        //             if (tribeCommunityResponse) {
        //                 const hasError = typeof tribeCommunityResponse === "string";

        //                 return (
        //                     <Tab.Pane>
        //                         {hasError ? (
        //                             <NoSubscriptionsComponent
        //                                 goToSubscriptions={() => handleTribeCommunity()}
        //                                 message={ERRORS.ERROR_LOADING_TRIBE_COMMUNITY}
        //                                 title="Try again"
        //                             />
        //                         ) : (
        //                             <Loader />
        //                         )}
        //                     </Tab.Pane>
        //                 );
        //             } else {
        //                 <Tab.Pane>
        //                     <Loader />
        //                 </Tab.Pane>;
        //             }
        //         } else {
        //             return (
        //                 <Tab.Pane>
        //                     <NoSubscriptionsComponent
        //                         goToSubscriptions={goToSubscriptions}
        //                         message={ERRORS.ERROR_SUBSCRIPTION_IS_NOT_PREMIUN}
        //                     />
        //                 </Tab.Pane>
        //             );
        //         }
        //     },
        // },
    ];

    return (
        <>
            {erroModal}
            {pauseSubscriptionModal}
            {moveLevelSubscription}
            <Header history={history} hidePromo={true} openChat={showChat} />
            <Container>
                {loader}
                {!loadingSubscriptions && (
                    <div className="margin-top">
                        <Tab activeIndex={tabIndex} onTabChange={handleTabChange} panes={panes} />
                    </div>
                )}
            </Container>
        </>
    );
}

const mapDispatchToProps = (dispatch) => ({
    saveUserSubscriptions: (userSubscriptions) => dispatch(saveUserSubscriptions(userSubscriptions)),
    resetSubscriptionStore: () => dispatch(resetSubscriptionStore()),
    saveFirebaseData: (userFirebaseData) => dispatch(saveFirebaseData(userFirebaseData)),
});

const mapStateToProps = (state) => {
    return {
        user: state.user,
        addOnPrices: state.business.addOnPrices,
        plans: state.business.plans,
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(withRouter(ManageSubscription));
