import React from "react";
import { Header } from "semantic-ui-react";
import { SubHeader, DefaultButton, OutlineButton } from "../..";
import SubscriptionSuccessful from "../../..//images/survivalImages/success.svg";

function SuccessfulComponent(props) {
    const { goToManageSubscriptions, goToStepTwo, selectedPlan, complementPlan } = props;

    return (
        <section className="padding-page">
            <SubHeader mainTitle="Congratulations" image={SubscriptionSuccessful} />
            <div className="input-container container-padding">
                <Header as="h3" textAlign="center" content="Your Survivalbox Subscription is ready!" />
                <br />
                <p className="successful-note">
                    Remember that you can complement your
                    <br />
                    {selectedPlan && <strong className="color-green">{selectedPlan.name}</strong>}
                    <br />
                    with our
                    <br />
                    {complementPlan && <strong className="color-green">{complementPlan.name}</strong>}
                </p>
            </div>
            <div className="margin-top padding-bottom flex-center flex-column button-container-padding">
                {complementPlan && (
                    <DefaultButton title={`Buy Your ${complementPlan.name} Now!`} buttonAction={() => goToStepTwo()} />
                )}
                <OutlineButton title="Skip, Manage my Subscription" buttonAction={() => goToManageSubscriptions()} />
            </div>
        </section>
    );
}

export default SuccessfulComponent;
