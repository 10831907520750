import React, { useState } from "react";
import { Grid, Header, Accordion, Image } from "semantic-ui-react";
import { SubHeader, DefaultButton, BackButton } from "../..";
import * as CONSTANTS from "../../../constants/Global";

function StepFourComponent(props) {
    const { saveStepFourAndNext, subscriptionPlan, routeNextPage, goToStepTwo, handleMoreInfo, showMoreInfo } = props;
    const isBasicPlan = subscriptionPlan[0].metadata.type === CONSTANTS.PLAN_TYPES.BASIC;
    const [activeIndex, setActiveIndex] = useState(0);

    return (
        <section className="padding-page">
            <SubHeader
                mainTitle="Survival Garden"
                specialText={isBasicPlan ? "(Only for Premium Subscription)" : ""}
                hasDivider={true}
            />

            <Grid>
                <Grid.Row>
                    <Grid.Column computer={6} tablet={6} mobile={16}>
                        <div className="column-info-container">
                            <h3>DESCRIPTION</h3>
                            <ul>
                                <li>Lorem ipsum 1 orem ipsum 1 orem ipsum 1</li>
                                <li>orem ipsum 1 orem ipsum 1 orem ipsum 1</li>
                                <li>orem ipsum 1 orem ipsum 1 orem ipsum 1</li>
                                <li>orem ipsum 1 orem ipsum 1 orem ipsum 1</li>
                                <li>orem ipsum 1 orem ipsum 1 orem ipsum 1</li>
                                <li>orem ipsum 1 orem ipsum 1 orem ipsum 1</li>
                            </ul>
                            <br />
                            <DefaultButton
                                title={showMoreInfo ? "Less information" : "More information"}
                                buttonAction={handleMoreInfo}
                                inverted={true}
                            />
                        </div>
                    </Grid.Column>
                    <Grid.Column computer={10} tablet={10} mobile={16}>
                        <div className="survival-garden-background">
                            <Image
                                src="https://i1.wp.com/thepreppingguide.com/wp-content/uploads/2019/06/How-to-Start-a-Survival-Garden.jpg?zoom=2&resize=620%2C400&ssl=1"
                                fluid
                            />
                        </div>
                    </Grid.Column>
                </Grid.Row>
                {showMoreInfo && (
                    <Grid.Row>
                        <Grid.Column width={16} textAlign="center">
                            <Header as="h2" textAlign="center">
                                Big title how it works
                            </Header>
                            <br />
                            <p>Description</p>
                            <br /> <br />
                        </Grid.Column>
                        <Grid.Column computer={8} tablet={8} mobile={16}>
                            <Accordion className="" fluid styled>
                                {CONSTANTS.SURVIVAL_GARDEN_FAQS.map((question, index) => {
                                    return (
                                        <div key={index}>
                                            <Accordion.Title
                                                active={activeIndex === index}
                                                index={1}
                                                onClick={() => setActiveIndex(index)}
                                            >
                                                {question.quesiton}
                                            </Accordion.Title>
                                            <Accordion.Content active={activeIndex === index}>
                                                <p>{question.answer}</p>
                                            </Accordion.Content>
                                        </div>
                                    );
                                })}
                            </Accordion>
                        </Grid.Column>
                        <Grid.Column width={16}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column width={4}>
                                        <div className="feature-container">FEATURE</div>
                                    </Grid.Column>
                                    <Grid.Column width={4}>
                                        <div className="feature-container">FEATURE</div>
                                    </Grid.Column>
                                    <Grid.Column width={4}>
                                        <div className="feature-container">FEATURE</div>
                                    </Grid.Column>
                                    <Grid.Column width={4}>
                                        <div className="feature-container">FEATURE</div>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Grid.Column>
                    </Grid.Row>
                )}
            </Grid>
            <div className="margin-top padding-bottom flex-center flex-column button-container-padding">
                {isBasicPlan ? (
                    <DefaultButton title="Select Premium Plan" buttonAction={goToStepTwo} />
                ) : (
                    <DefaultButton title="Get Survival Garden" buttonAction={saveStepFourAndNext} />
                )}
                <br />
                <DefaultButton title="No, Thank You." dark={true} buttonAction={routeNextPage} />
                <br />
                <BackButton title="back" />
            </div>
        </section>
    );
}

export default StepFourComponent;
