import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import { Loader } from "../components/Loader";
import { setupAccountInitialRoute } from "../services/SetupService";
import { saveSurvivalType, resetSubscriptionStore, saveStepFour } from "../store/subscriptions/actions";
import { getUserStoredData } from "../store/user/actions";
import { signInWithCustomToken } from "../services/AuthService";
import { INITAL_PARAMS } from "../constants/Global";

function Initial(props) {
    const {
        history,
        subscriptions,
        saveSurvivalType,
        resetSubscriptionStore,
        saveStepFour,
        plans,
        shipping,
        getUserStoredData,
    } = props;
    const [showLoading, setShowLoading] = useState(true);

    useEffect(() => {
        const searchParams = window.location.search.replace("?", "").split("&");
        const token = searchParams.find((param) => param.includes("token"));

        const doBackgroundSignIn = async () => {
            await signInWithCustomToken(token.split("=")[1], getUserStoredData);
        };

        if (token) doBackgroundSignIn();
    }, [getUserStoredData]);

    useEffect(() => {
        const searchParams = window.location.search.replace("?", "").split("&");
        const isCommunity = searchParams.some((param) => param === INITAL_PARAMS.COMMUNITY);

        if (plans.length > 0) {
            !isCommunity && setShowLoading(false);
            setupAccountInitialRoute(
                history,
                searchParams,
                subscriptions,
                saveSurvivalType,
                resetSubscriptionStore,
                saveStepFour,
                plans,
                shipping
            );
        }
    }, [history, subscriptions, saveSurvivalType, resetSubscriptionStore, saveStepFour, plans, shipping]);

    return showLoading && <Loader />;
}

const mapDispatchToProps = (dispatch) => ({
    saveSurvivalType: (giftType, upgradeType) => dispatch(saveSurvivalType(giftType, upgradeType)),
    resetSubscriptionStore: () => dispatch(resetSubscriptionStore()),
    saveStepFour: (stepFour) => dispatch(saveStepFour(stepFour)),
    getUserStoredData: () => dispatch(getUserStoredData()),
});

const mapStateToProps = (state) => {
    return {
        subscriptions: state.subscriptions,
        plans: state.business.plans,
        shipping: state.business.shipping,
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(withRouter(Initial));
