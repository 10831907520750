import React, { useState } from "react";
import { Image, Divider, Card } from "semantic-ui-react";
import { SubHeader, DefaultButton, BackButton, CustomInputButtom, ErrorModal, CustomCheckbox } from "../../..";
import { OrderCard } from "../../..";
import SecureCheckout from "../../../../images/secure-cheeckout.svg";
import SecureSsl from "../../../../images/secure-ssl.svg";
import { validateDiscountCoupon } from "../../../../services/SetupService";
import { getLocalStorageItem } from "../../../../services/LocalStorageService";
import * as ERRORS from "../../../../constants/Errors";
import * as CONFIRMATIONS from "../../../../constants/Confirmations";
import * as CONSTANTS from "../../../../constants/Global";
import * as LOCALSTORAGE from "../../../../constants/LocalStorage";

function OrderSummary(props) {
    const {
        saveAndPay,
        ordersSummaryData,
        removeAddOn,
        removeSubscription,
        loadingSecondaryButton,
        saveDiscountCoupon,
        couponData,
        giftType,
        oneTimeGift,
        formState,
        cardElementsState,
        showCreditCardForm,
        handleSubscribeNewsLetter,
        setSubscriptionAutoRenewing,
        userData,
    } = props;
    const [couponName, setCouponName] = useState(couponData ? couponData.code : "");
    const [loadingCoupon, setLoadingCoupon] = useState(false);
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [couponError, setCouponError] = useState(false);
    const subscriptionData = ordersSummaryData.find((order) => order.type === CONSTANTS.BOX_TYPES.SUBSCRIPTION);
    const shippingAmount = ordersSummaryData.find((order) => order.type === "shipping");
    const isOldSubscriber = getLocalStorageItem(LOCALSTORAGE.OLD_SUBSCRIBER);

    const onChangeCoupon = (event, { value }) => {
        setCouponName(value);
    };

    const checkDiscountCoupon = async () => {        
        if (couponName !== "") {
            setLoadingCoupon(true);
            const coupon = {
                code: couponName,
            };

            const couponValidation = await validateDiscountCoupon(coupon);            
            console.log("CouponValidation:" , couponValidation);
            if (couponValidation) {
                setLoadingCoupon(false);                
                if (couponValidation === "not found") {
                    setOpenErrorModal(true);
                    setCouponError(ERRORS.ERROR_COUPON_NOT_FOUND);
                } else if (couponValidation === "already used") {
                    setOpenErrorModal(true);
                    setCouponError(ERRORS.ERROR_COUPON_USED_PREVIOUSLY);
                } else {                    
                    if (couponValidation.active) {
                        saveDiscountCoupon(couponValidation);
                    } else {
                        setOpenErrorModal(true);
                        setCouponError(ERRORS.ERROR_COUPON_INVALID);
                    }
                }
            } else {
                setLoadingCoupon(false);
                setOpenErrorModal(true);
                setCouponError(ERRORS.ERROR_VALIDATING_CODE);
            }
        }
    };

    const removeCode = () => {
        saveDiscountCoupon(null);
        setCouponName("");
    };

    const closeErrorModal = () => {
        setCouponName("");
        setOpenErrorModal(false);
    };

    const sumValuesBy = "price";
    const addOnsSum = ordersSummaryData
        .filter((order) => order.type !== CONSTANTS.BOX_TYPES.SUBSCRIPTION)
        .reduce((a, b) => a + (b[sumValuesBy] || 0), 0);

    let subscriptionPrice = subscriptionData;

    if (subscriptionData) {
        subscriptionPrice = subscriptionData.unitPrice;
    }

    let discountedTotalValue = addOnsSum + subscriptionPrice;
    if (couponData) {
        if (couponData.coupon) {
            if (couponData.coupon.percent_off) {
                discountedTotalValue =
                    addOnsSum + subscriptionPrice - subscriptionPrice * (couponData.coupon.percent_off / 100);
            } else if (couponData.coupon.amount_off) {
                discountedTotalValue = addOnsSum + (subscriptionPrice - couponData.coupon.amount_off / 100);
            } else {
                discountedTotalValue = addOnsSum;
            }
        }
    }

    if (isOldSubscriber) {
        discountedTotalValue = addOnsSum + subscriptionPrice - subscriptionPrice * (10 / 100);
    }

    let errorModal = null;

    if (openErrorModal) {
        errorModal = <ErrorModal open={openErrorModal} message={couponError} closeFunction={closeErrorModal} />;
    }

    const couponDisabled = couponData && couponData.code !== "";

    let joinSubscriptionSection = null;
    let promoCodeSection = (
        <>
            <Divider />
            <div className="summary-item">
                <p>Promo Code</p>
                <span>{couponData && couponData.code}</span>
            </div>
            <CustomInputButtom
                placeholder="Promo Code"
                onChangeAction={onChangeCoupon}
                value={couponData ? couponData.code : couponName}
                onClickAction={checkDiscountCoupon}
                loading={loadingCoupon}
                disabled={couponDisabled}
                removeCode={removeCode}
            />
        </>
    );

    if (giftType && oneTimeGift) {
        joinSubscriptionSection = (
            <>
                <div>
                    <h3 className="text-center">Save 30% on your first order when you add a Subscription!</h3>
                    <DefaultButton title="Join Now" inverted={true} buttonAction={() => removeSubscription(true)} />
                </div>
                <Divider />
            </>
        );

        promoCodeSection = null;
    }

    const cardDataError = [
        cardElementsState.cardCvcEmptyError,
        cardElementsState.cardExpiryEmptyError,
        cardElementsState.cardNumberEmptyError,
    ].some((item) => item);

    const cardDataInputsEmpty = [
        cardElementsState.cardCvcEmpty,
        cardElementsState.cardExpiryEmpty,
        cardElementsState.cardNumberEmpty,
    ].some((item) => item);

    const userDataInputs = [formState.localFirstName, formState.localLastName, formState.localPostalCode].some(
        (item) => !item
    );

    const disabledPaymenButton = (cardDataError || cardDataInputsEmpty || userDataInputs) && showCreditCardForm;

    const filteredOrdersSummaryData = ordersSummaryData.filter((order) => order.type !== "shipping");

    return (
        <>
            {errorModal}
            <section className="order-summary-container">
                <SubHeader mainTitle="Order Summary" shortPadding={true} />
                <Card.Group textAlign="center" centered>
                    {filteredOrdersSummaryData.map((plan, index) => {
                        return (
                            <OrderCard
                                key={index}
                                plan={plan}
                                removeAddOn={removeAddOn}
                                removeSubscription={removeSubscription}
                                setAutoRenewing={setSubscriptionAutoRenewing}
                            />
                        );
                    })}
                </Card.Group>
                {joinSubscriptionSection}
                <div className="summary-item">
                    <span>SHIPPING</span>
                    <span className="bold">{`$${shippingAmount ? shippingAmount.unitPrice.toFixed(2) : 0}`}</span>
                </div>
                {isOldSubscriber && (
                    <div className="summary-item">
                        <p>Special percent off (First box)</p>
                        <span className="bold">10%</span>
                    </div>
                )}
                {promoCodeSection}
                {userData && userData.email && (
                    <div className="summary-item">
                        <CustomCheckbox
                            id="subscribeNewsLetter"
                            isBigSize={true}
                            label={CONFIRMATIONS.RECIEVE_MARKETING_EMAIL}
                            value={formState.subscribeNewsLetter}
                            onChangeAction={handleSubscribeNewsLetter}
                        />
                    </div>
                )}
                <Divider />
                <div className="summary-item">
                    <p className="bold">Total</p>
                    <span className="bold total">{`$${discountedTotalValue.toFixed(2)} USD`}</span>
                </div>
                <div className="show-mobile margin-top padding-bottom container-padding">
                    <DefaultButton
                        title="Order The Box"
                        loading={loadingSecondaryButton}
                        buttonAction={saveAndPay}
                        disabled={disabledPaymenButton}
                    />
                    <br />
                    <BackButton title="back" />
                </div>
            </section>
            <section className="icons-section">
                <Image src={SecureCheckout} size="small" />
                <Image src={SecureSsl} size="small" />
            </section>
        </>
    );
}

export default OrderSummary;
