import React from "react";
import { Grid, Card, Header, Table } from "semantic-ui-react";
import { AddOnCard, SubHeader, DefaultButton, BackButton } from "../..";

function StepThreeComponent(props) {
    const {
        addOnItems,
        onChanngeAddOn,
        onChangeAutoRenewing,
        subscriptionPlan,
        saveStepThreeAndNext,
        goToStepTwo,
    } = props;
    const ifAddOns = addOnItems.some((item) => item.quantity > 0);
    const planPrice = subscriptionPlan[0].unit_amount;

    const addOnItemsTotal = addOnItems
        .map((item) => item.unit_amount * item.quantity)
        .reduce((total, num) => total + num);

    const addOnItemsMonthlyTotal = addOnItems
        .map((item) => item.autoRenewing && item.unit_amount * item.quantity)
        .reduce((total, num) => total + num);

    const monthlyTotal = addOnItemsMonthlyTotal + planPrice;
    const todayTotal = addOnItemsTotal + planPrice;

    const amountCards = (
        <Table unstackable size="large" basic="very" style={{ marginTop: "5.3em" }}>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell width={10}>{`${subscriptionPlan[0].metadata.name}:`}</Table.HeaderCell>
                    <Table.HeaderCell width={4}></Table.HeaderCell>
                    <Table.HeaderCell width={2}>{`$${planPrice / 100}`}</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {ifAddOns &&
                    addOnItems.map((item, index) => {
                        const price = ((item.unit_amount * item.quantity) / 100).toFixed(2);

                        if (item.quantity > 0) {
                            return (
                                <Table.Row key={index} verticalAlign="middle">
                                    <Table.Cell textAlign="left">
                                        <br />
                                        {item.metadata.tradename}
                                        <br />
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">
                                        <br />
                                        {`$${item.unit_amount / 100} x ${item.quantity} item(s)`}
                                        <br />
                                    </Table.Cell>

                                    <Table.Cell textAlign="right">
                                        <br />
                                        {`$${price}`}
                                        <br />
                                    </Table.Cell>
                                </Table.Row>
                            );
                        } else {
                            return null;
                        }
                    })}
            </Table.Body>
            <Table.Footer>
                <Table.Row>
                    <Table.HeaderCell>
                        <Header as="h3" content="Monthly total" />
                    </Table.HeaderCell>
                    <Table.HeaderCell />

                    <Table.HeaderCell textAlign="right">
                        <strong>{`$${(monthlyTotal / 100).toFixed(2)}`}</strong>
                    </Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                    <Table.HeaderCell>
                        <Header as="h2" content="Today total" />
                    </Table.HeaderCell>
                    <Table.HeaderCell />

                    <Table.HeaderCell textAlign="right">
                        <strong>{`$${(todayTotal / 100).toFixed(2)}`}</strong>
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Footer>
        </Table>
    );

    let actionButton = null;

    if (ifAddOns) {
        actionButton = <DefaultButton title="Next Step" buttonAction={saveStepThreeAndNext} />;
    } else {
        actionButton = <DefaultButton title="Next" dark={true} buttonAction={saveStepThreeAndNext} />;
    }

    return (
        <Grid className="payment-grid" centered reversed="mobile">
            <Grid.Row>
                <Grid.Column computer={8} tablet={8} mobile={16}>
                    <section className="step-three-content padding-page">
                        <SubHeader mainTitle="Add On Market" specialText="Premium option add on market" />
                        <Card.Group>
                            {addOnItems.map((addOn, index) => {
                                const selectedAddOn = addOn.quantity > 0;
                                return (
                                    <AddOnCard
                                        key={index}
                                        onChanngeAddOn={onChanngeAddOn}
                                        onChangeAutoRenewing={onChangeAutoRenewing}
                                        selectedAddOn={selectedAddOn}
                                        addOn={addOn}
                                        subscriptionPlan={subscriptionPlan[0].metadata}
                                        goToStepTwo={goToStepTwo}
                                    />
                                );
                            })}
                        </Card.Group>
                    </section>
                </Grid.Column>
                <Grid.Column computer={8} tablet={8} mobile={16}>
                    <section className="order-summary-container order-summary-container-customized addons">
                        <SubHeader mainTitle="Summary" />
                        {amountCards}
                    </section>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={16}>
                    <div className="margin-top padding-bottom flex-center flex-column button-container-padding">
                        {actionButton}
                        <br />
                        <BackButton title="back" />
                    </div>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}

export default StepThreeComponent;
